import { FaWindowClose } from 'react-icons/fa';
import styles from "./ExerciseDetails.module.css";
import { getExerciseThumbnail } from '../../../utils/getExerciseThumbnail';
import { useContext, useState } from 'react';
import { BasicSessionInfoContextType, basicSessionInfoContext } from '../../../utils/BasicSessionContext';
import { CategoryTreeContextType, categoryTreeContext } from '../../../utils/CategoryTreeContext';
import { Exercise, ObjectType } from '../../../utils/exerciseService';
import DOMPurify from 'dompurify';
import Button from '../Button/Button';
import { createNewAssignment, initAssign } from '../../../utils/AssignmentService/AssignmentService';
import { getSessionInfo } from '../../../utils/SessionService/SessionService';
import { SessionInfoNode } from '../../../utils/SessionService/SessionInfoNode';
import { NewLinkNode } from '../../../utils/AssignmentService/NewLinkNode';
import Modal from '../Modal/Modal';
import AssignmentPrompt from '../Prompts/AssignmentPrompt';
import AssignmentLinkAndRedirectPrompt from '../Prompts/AssignmentLinkAndRedirectPrompt';
import { getPreviewUrl } from '../../../utils/getPreviewUrl';
import { getCartoonistUrl } from '../../../utils/getCartoonistUrl';
import React from "react";
import ButtonNew from "../Button/ButtonNew";

interface Props {
    closeWindow: () => void;
    exercise: Exercise;
}

export const ExerciseDetails = ({exercise, closeWindow}: Props) => {
    /* Get values from CMS */

    const { cmsLangId } = useContext(basicSessionInfoContext) as BasicSessionInfoContextType;
    const { components } = useContext(categoryTreeContext) as CategoryTreeContextType;

    const activityDetailNode = components?.children.find(node => node.name === "ActivityDetail");

    const solveActivityLabelNode = activityDetailNode?.children.find(node => node.name === "SolveActivity-label");
    const solveActivityLabel = solveActivityLabelNode?.children.find(node => node.name.includes(cmsLangId))?.description;


    const assignActivityLabelNode = activityDetailNode?.children.find(node => node.name === "AssignActivity-label");
        const assignActivityLabel = assignActivityLabelNode?.children.find(node => node.name.includes(cmsLangId))?.description;

        /* ------------------------- */

        /* ----------------- Logic for assigning exercise --------------------- */

        const [newAssignmentLink, setNewAssignmentLink] = useState<NewLinkNode | null>(null);
        const [userSession, setUserSession] = useState<SessionInfoNode | null>(null);

        const [showAssignPrompt, setShowAssignPrompt] = useState(false);
        const [showLinkAndRedirectPrompt, setShowLinkAndRedirectPrompt] = useState(false);


        const createAssignment = async (exercise: any) => {
            //setSelectedExercise(exercise);
            const response = await initAssign();
            updateSessionInfo();
            setNewAssignmentLink(response.data);
            setShowAssignPrompt(true);
        }

        const assignExercise = async (newAssignment: any) => {
            const response = await createNewAssignment(newAssignment);
            setShowAssignPrompt(false);
            setShowLinkAndRedirectPrompt(true);
        }

        const abortAssign = () => {
            //setSelectedExercise(null);
            setNewAssignmentLink(null);
            setShowAssignPrompt(false);
            setShowLinkAndRedirectPrompt(false);
        }

        const updateSessionInfo = async () => {
            const response = await getSessionInfo();
            setUserSession(response.data);
        }
        /* ------------------------------------------------------------------------------------------- */

        const exerciseHref = exercise.objectType === ObjectType.crz_exercise_samfunn ? getPreviewUrl(exercise) : getCartoonistUrl(exercise);



    return (
            <>
                <div className={styles['information-window']} >
                    <div style={{ float: 'right' }}>
                        <button aria-label={"Lukk oppgaven " /* TODO rmg translate*/ + exercise.name} onClick={closeWindow} style={{ fontSize: "1.25rem" }} ><FaWindowClose /></button>
                    </div>
                    <div className={styles['information-window__content']} >
                        <div className={styles['image-wrapper']}>
                            <img src={getExerciseThumbnail(exercise)} alt="" /> {/* Hva forventer brukeren som trykker på dette bildet? */}
                        </div>
                        <div className={styles['information-window__name-and-description']}>
                            <h2 aria-live='polite' className={styles['information-window__name']}>{exercise.name}</h2>
                            <div className={styles['information-window__desc']}>
                                {(exercise && exercise.description) && exercise.description.split("\n").join(";;;").split("<br>").join(";;;").split("<br/>").join(";;;").split("<br />").join(";;;").split(";;;").map((paragraph, index) => { // Authors love to use <br>. Turn them into pharagraphs.
                                    return (
                                        paragraph.length > 0 && <p key={index} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(paragraph, { ADD_ATTR: ['target'] }) }} ></p>
                                    );
                                })
                                }
                            </div>
                            <div style={{ display: "flex", flexWrap: "wrap", gap: "24px", justifyContent: "flex-end", }}>
                                <a href={exerciseHref} target="_top">
                                    <ButtonNew>{solveActivityLabel}</ButtonNew>
                                </a>
                                <ButtonNew onClick={() => createAssignment(exercise)}>{assignActivityLabel}</ButtonNew>
                            </div>
                        </div>
                    </div>
                </div>
                {/*-------- Assigning exercise ----------- */}
                {showAssignPrompt &&
                    <Modal show={showAssignPrompt} backdropClicked={abortAssign}>
                        <AssignmentPrompt
                            exercise={exercise}
                            session={userSession}
                            newLinkNode={newAssignmentLink}
                            confirmClicked={assignExercise}
                            cancelClicked={abortAssign} />
                    </Modal>
                }
                {showLinkAndRedirectPrompt &&
                    <Modal show={showLinkAndRedirectPrompt} backdropClicked={abortAssign}>
                        <AssignmentLinkAndRedirectPrompt
                            exercise={exercise}
                            session={userSession}
                            newLinkNode={newAssignmentLink}
                            cancelClicked={abortAssign} />
                    </Modal>
                }
                {/*---------------------------------------------- */}
            </>
        )
}