export const topicIconBaseURL = "https://alphabet-planet-ui.s3.eu-west-1.amazonaws.com/topic-tree/images/"
export enum TopicIcon {
    Bruk_fantasien = "bruk_fantasien.svg",
    Rettskriving = "rettskriving.svg",
    Grammatikk = "grammatikk.svg",
    Tegnsetting = "tegnsetting.svg",
    Tall_og_telling = "tall_og_telling.svg",
    Lek_med_ord = "lek_med_ord.svg",
    Les_og_laer = "les_og_laer.svg",
    Skriverammer = "skriverammer.svg",
    Reflekter_og_forklar = "reflekter_og_forklar.svg",
    Alfa = "alfa.svg",
    Bravo = "bravo.svg",
    Charlie = "charlie.svg",
    Delta = "delta.svg",
    Bok1 = "bok1.svg",
    Bok2 = "bok2.svg",
    Bok3 = "bok3.svg",
    Bok4 = "bok4.svg",
    Aktiviteter = "aktiviteter.svg",
    Stage_round = "stage_round.svg",
    Rim_og_rytme = "rim_og_rytme.svg",
    Sosial_kompetanse = "sosial_kompetanse.svg",
    Fortellinger = "fortellinger.svg",
    Begreper = "begreper.svg",
    Print = "print.svg"
}