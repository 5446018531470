import React, { useRef, useState } from 'react';

import styles from './Checkbox.module.css';


interface CheckboxProps {
    checked?: boolean;
    label: string;
    handleChange: Function;
    value: string;
}

export const Checkbox = ({ checked = false, label, value, handleChange }: CheckboxProps) => {


    /* const [isChecked, setIsChecked] = useState(checked); */

    const refCheckbox = useRef<null | HTMLInputElement>(null);
    //refCheckbox.current!.checked = checked;

    function handleClick() {
        refCheckbox.current!.checked = !refCheckbox.current!.checked;
        //setIsChecked(!isChecked);
        handleChange(refCheckbox.current);
    }

    function handleKeyDown(event : React.KeyboardEvent) {
        if (event.key === "Enter") {
          handleClick();
        }
      }

    return (
        <div className={styles.input} onClick={handleClick} onKeyDown={handleKeyDown} tabIndex={0} aria-checked={checked}>
            <div className={checked ? styles['checkbox--checked'] : styles['checkbox--unchecked']} ></div>
            <input style={{display: "none"}} ref={refCheckbox} onChange={e => handleChange(e.target)} type="radio" name={label} value={value} checked={checked} />
            <label htmlFor={label}>{label}</label>
        </div>
    );
};
