import { sanitize } from 'dompurify';
import React, { useContext, useState } from 'react';
import { FaLightbulb, FaRegLightbulb, FaWindowClose } from 'react-icons/fa';
import {Link, Outlet, useParams} from 'react-router-dom';
import { CategoryTreeNode } from '../../utils/CategoryTreeNode';
import { parseToURL } from '../../utils/parseToURL';
import Modal from '../UIComponents/Modal/Modal';

import styles from './TeacherFrontPage.module.css';
import { BasicSessionInfoContextType, basicSessionInfoContext } from '../../utils/BasicSessionContext';
import { CategoryTreeContextType, categoryTreeContext } from '../../utils/CategoryTreeContext';

interface TeacherFrontPageProps {
    teacherFrontPageNode: CategoryTreeNode;
    teacherNavMenuNode: CategoryTreeNode;
    pagesNode: CategoryTreeNode;
}

export const TeacherFrontPage = ({ teacherFrontPageNode, teacherNavMenuNode, pagesNode }: TeacherFrontPageProps) => {
    const { cmsLangId, basicSessionInfo } = useContext(basicSessionInfoContext) as BasicSessionInfoContextType;
    const languageId = basicSessionInfo?.languageId;
    const { globalTranslations } = useContext(categoryTreeContext) as CategoryTreeContextType;

    const closeWindowLabelNode = globalTranslations?.children.find(node => node.name === "CloseWindow-translation");
    console.assert(closeWindowLabelNode, "Can't find CloseWindow-translation as child of " + globalTranslations?.name);
    const closeWindowLabel = closeWindowLabelNode?.children.find(node => node.name.includes(cmsLangId))?.description;
    console.assert(closeWindowLabel, "Can't find node width language id " + cmsLangId + "as child of " + closeWindowLabelNode?.name);

    const pageTitle = teacherFrontPageNode.children.find(n => n.name === "Title")?.children.find(n => n.name.startsWith(cmsLangId))?.description;

    const [showToTeacherModal, setShowToTeacherModal] = useState(false);

    const guidanceLabel = teacherFrontPageNode.children.find(n => n.name === "GuidanceLabel")?.children.find(n => n.name.includes(cmsLangId))?.description;
    console.assert(guidanceLabel, "Missing the Node 'GuidanceLabel' or it's language-child's value.");

    // Add rel and target attributes
    let guidanceHtmlText = teacherFrontPageNode.children.find(n => n.name === "GuidanceHtml")?.children.find(n => n.name.includes(cmsLangId))?.description;
    const attribute = "href=";
    if (guidanceHtmlText && guidanceHtmlText.includes("<a") && guidanceHtmlText.includes(attribute)) {
        guidanceHtmlText = guidanceHtmlText.replaceAll(attribute, 'rel="noopener noreferrer" target="_blank" ' + attribute);
    }

    const guidanceDangerousHtml = guidanceHtmlText &&
        <div className={styles['information-to-teacher']} >
            <div style={{ width: "100%", textAlign: 'end' }}>
                <button onClick={() => setShowToTeacherModal(false)} style={{ fontSize: "1.25rem" }}>
                    <FaWindowClose />
                </button>
            </div>
            <div dangerouslySetInnerHTML={{ __html: sanitize(guidanceHtmlText, { ADD_ATTR: ['target'] }) }}></div>
            <div style={{ width: "100%", textAlign: 'end', marginBlockStart: "1.25rem", }}>
                <button
                    onClick={() => setShowToTeacherModal(false)}
                    style={{ textDecoration: "underline" }}
                >
                    {closeWindowLabel}
                </button>
            </div>
        </div>

    return (
        <div className={styles['teacher-menu-wrapper']}>

            <Outlet/>
            {(showToTeacherModal && guidanceDangerousHtml) &&
                <Modal show={true} backdropClicked={() => setShowToTeacherModal(false)}>
                    {/* {dummyToTeacherNotes} */}
                    {guidanceDangerousHtml}
                </Modal>
            }

            <div className={styles['master-img']}>
                <img alt="" src="https://alphabet-planet-ui.s3.eu-west-1.amazonaws.com/images-ui/alphabet_planet_ui_page_teacher_master.svg" />
            </div>

            <nav className={styles['teacher-menu']}>
                {teacherNavMenuNode.children.map(link => {
                    const label = link.children.find(n => n.name === "Label")?.children.find(n => n.name.startsWith(cmsLangId))?.description;
                    console.assert(label, "Missing label in TeacherNavMenu from ICSX");

                    const imageNode = link.children.find(n => n.name === "Image");
                    console.assert(imageNode, "Missing node: 'Image' in " + link.name);

                    const imgSrc = imageNode?.children.find(n => n.name.startsWith(cmsLangId))?.description
                        ?? imageNode?.children.find(n => n.name.startsWith("<0>"))?.description;
                    console.assert(imgSrc, "Missing source of image in Image > " + link.name);

                    const pageNodeId = link.children.find(n => n.name === "PageNodeId");
                    console.assert(pageNodeId, "Cannot find a node with the name PageNodeId in " + link.name);

                    const linkToPageId = pageNodeId?.description;
                    console.assert(linkToPageId, "Missing ID in" + link.name + " > " + pageNodeId);

                    const pageThatLinkIsLinkingTo = pagesNode.children.find(n => n.id.toString() === linkToPageId);
                    console.assert(pageThatLinkIsLinkingTo, "Cannot find page with ID " + linkToPageId + ". Make sure the ID of the node that represents the page is in the description of " + link.name + " > PageNodeId");

                    const titleOfLinkToPage = pageThatLinkIsLinkingTo?.children.find(n => n.name === "Title")?.children.find(n => n.name.startsWith(cmsLangId))?.description;
                    console.assert(titleOfLinkToPage, "Cannot get the title of " + pageThatLinkIsLinkingTo?.name + ". Make sure the Title Node's language-child has a value in its description");

                    return (
                        titleOfLinkToPage &&
                        <div key={link.id} className={styles['teacher-option-wrapper']}>
                            <Link to={"" + parseToURL(titleOfLinkToPage)}>
                                <div className={styles['teacher-option_content-wrapper']}>
                                    <img className={styles['teacher-option_img']} alt="" src={imgSrc}></img>
                                    <span className={styles['teacher-option_text']}>{label}</span>
                                </div>
                            </Link>
                        </div>
                    )
                })}
            </nav>

            <button className={styles['guidance-btn']}
                onClick={() => setShowToTeacherModal(true)}
                type='button'>
                <FaLightbulb className={styles['lightbulb--lit']} aria-hidden="true" />
                <FaRegLightbulb /> {guidanceLabel}
            </button>
        </div>
    );
};