import { useContext } from 'react';
import styles from './BiographyLanguageSelect.module.css';
import { BiographyLanguageContextType, biographyLanguageContext } from '../../../utils/BiographyLanguageContext';
import { useMGMTTranslations } from '../../../utils/TranslationHooks';
import { LanguageISO639_1, useUserLanguage } from '../../../utils/languages';

export const BiographyLanguageSelect = () => {

    const mgmtTranslate = useMGMTTranslations();
    const { biographyLanguage, setBiographyLanguage } = useContext(biographyLanguageContext) as BiographyLanguageContextType;

    const userLanguage = useUserLanguage();
    const biographyLanguages = userLanguage?.biographyLanguages;
    const languageSelectOptions = biographyLanguages?.map(iso => {
        const langIso = mgmtTranslate("language_" + iso);
        console.assert(langIso, "Missing MGMT translation for " + "language_" + iso);
        return (langIso &&
            <option value={iso} key={iso}>
                {langIso}
            </option>
        )
    });

    const languageSelectLabel = mgmtTranslate("global_chooseALanguage") + ":";

    return (
        <div className={styles['language-select']}>
            <label htmlFor="language-select">{languageSelectLabel}</label>

            {biographyLanguage &&
                <select
                    id="language-select"
                    name="languages"
                    style={{ textTransform: "capitalize" }}
                    onChange={(e) => {
                        setBiographyLanguage(e.target.value as LanguageISO639_1);
                    }}
                    value={biographyLanguage}
                >
                    {languageSelectOptions}
                </select>}
        </div>

    )
}