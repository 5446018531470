import React from "react";
import {LargeHeader} from "../Header/LargeHeader";
import {ThemeName, Themes} from "../../../utils/Themes";
import styles from "./TopicHeader.module.css";

interface Props {
    themeName?: ThemeName,
    children: string
}

export const TopicHeader = ({themeName = "alfa", children}: Props) => {
    const imageRight = themeName === "delta" || themeName === "charlie" || themeName === "socialCompetence" || themeName === "activities"  || themeName === "print";
    const theme = Themes[themeName];

    return (
        <header className={`${styles["header-wrapper"]} ${imageRight? styles["reverse-order"]: ""}`}>
            <div>
                <img
                    className={styles["header-image"]}
                    src={theme.image}
                    alt={""}
                />
            </div>
            <LargeHeader themeName={themeName} headingLevel={1}>{ children }</LargeHeader>
        </header>
    )
}