// TODO: Make it scroll (or go stright to the anchor point) on first load */

import { useMemo, useEffect } from "react";
import { useLocation } from "react-router-dom";

/**
 * Works only when the element with the hash already is mounted,
 * meaning it won't work during the first page load.
 */
export const ScrollToAnchor = () => {
    const location = useLocation();

    let hashElement = useMemo(() => {
        let hash = decodeURIComponent(location.hash);
        const removeHashCharacter = (str: string) => {
            return str.slice(1);
        };

        if (hash) {
            let element = document.getElementById(removeHashCharacter(hash));
            return element;
        } else {
            return null;
        }
    }, [location]);

    useEffect(() => {
        hashElement &&
            hashElement?.scrollIntoView({
                behavior: "smooth",
                block: "start"
            })
    }, [location, hashElement])

    return null;
};