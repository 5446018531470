import {useContext} from "react";
import {categoryTreeContext} from "./CategoryTreeContext";
import {basicSessionInfoContext, BasicSessionInfoContextType} from "./BasicSessionContext";
import {CategoryTreeNode} from "./CategoryTreeNode";
import { MGMTTranslationsContext, MGMTTranslationsContextType } from "./MgtmTranslationsContext";
import { useUserLanguage } from "./languages";

/*----------- ICSX ----------- */
export const useTranslatePage = (firstNodeName: string) => {
    const categoryTree = useContext(categoryTreeContext);
    const { cmsLangId } = useContext(basicSessionInfoContext) as BasicSessionInfoContextType;

    const pageNode = categoryTree?.pages;
    if(!pageNode){
        return () => ""; //Node is probably not loaded yet
    }

    const firstNodeNamePage = `${firstNodeName}-page`
    const currentPageNode = pageNode.children.find(p => p.name === firstNodeNamePage);
    if(!currentPageNode){
        console.warn(`Could not find node '${firstNodeNamePage}'`);
        return () => "";
    }

    return (nodeName: string) => getText(currentPageNode, cmsLangId, nodeName);
}

export const useTranslateGlobal = () => {
    const categoryTree = useContext(categoryTreeContext);
    const { cmsLangId } = useContext(basicSessionInfoContext) as BasicSessionInfoContextType;

    const globalTranslateNode = categoryTree?.globalTranslations;

    if(!globalTranslateNode){
        return () => "";
    }
    return (nodeName: string) => getText(globalTranslateNode, cmsLangId, `${nodeName}-translation`);
}

export const getText = (rootNode: CategoryTreeNode, cmsLangId: string, secondNodeName: string) => {
    const text =  rootNode?.children
        .find(n => n.name === secondNodeName)?.children
        .find(n => n.name.includes(cmsLangId))?.description || "";

    if(!text){
        console.warn(`Could not find node '${secondNodeName}' for language '${cmsLangId}'`)
    }
    return text;
}

/*----------- ---- ----------- */

/*----------- MGMT ----------- */

export const useMGMTTranslations = () => {
    const { translations } = useContext(MGMTTranslationsContext) as MGMTTranslationsContextType;
    if (!translations) {
        throw new Error('useMGMTTranslations must be used within a MGMTTranslationsProvider');
    }
    const userLang = useUserLanguage();

    return (translationKey: string) => userLang && translations?.[translationKey]?.[userLang?.locale];
}

/*----------- ---- ----------- */