/**Takes a string and returns it as something that can be used in a URL */

export function parseToURL(string: string) {
    let res = string;
    res = res.toLowerCase();
    res = res.replaceAll(" ", "-");
    res = res.replaceAll("&shy;", "");

    // Use built-in encoder at last in case something was missed
    // res = encodeURI(res);

    return res;
}