import { Link } from 'react-router-dom';
import { CategoryTreeNode } from '../../../utils/CategoryTreeNode';
import styles from './StoryPreview.module.css';

interface StoryPreviewProps {
    href: string;
    imgSrc: string;
    alt?: string;
    headline: string;
}

export const StoryPreview = ({ href, imgSrc, alt, headline }: StoryPreviewProps) => {
    //TODO: unnecessary to get the story node as prop. Just make headline mandatory instead.

    return (
        <div className={styles.wrapper}>
            <Link style={{}} to={href}>
                <div className={styles['story-preview']}>
                    <img className={styles.img} src={imgSrc} alt={alt ? alt : ""} />
                    <div className={styles['headline-box']}>
                        <div style={{ textDecoration: "none" }} className={styles.headline}>{headline}</div>
                    </div>
                </div>
            </Link>
        </div>
    );
};