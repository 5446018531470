import { CategoryTreeNode } from "./CategoryTreeNode";
import { URLs } from "./URLs";
import { LanguageISO639_1 } from "./languages";

/* Currently, the app does not know whether or not there is an audio source for a given biography.
    We just assume all the audios exist, and let the app attempt to load the audio.
    Worth noting that each user language has a list of allowed biography languages,
    and the app shouldn't ask for a biography audio for other language apart from the ones in the list.
*/

export interface Character_has_filename {
    inhabitantNodeId: CategoryTreeNode["id"];
    /**Folder structure in the relevant section in S3 should correspond to this */
    filename: string;
    /**Url is set in the getBiographyAudios function. */
    url?: string;
}

const baseUri = URLs.BiographyAudio;

export function getBiographyAudioSrc(characterId: CategoryTreeNode["id"], languageISO: LanguageISO639_1) {
    const character_has_filename = biographyAudios.find(el => el.inhabitantNodeId === characterId.toString());
    if (character_has_filename) {
        return `${baseUri}/${languageISO}/${character_has_filename.filename}`;
    }
    return undefined;
}

const biographyAudios: Character_has_filename[] = [

    {
        //"Amal/Aida",
        "inhabitantNodeId": "120478804",
        "filename": "01.mp3"
    },
    {
        //"Alf/Alf",
        "inhabitantNodeId": "120479933",
        "filename": "02.mp3"
    },
    {
        //"Alfamesteren/Alfabetmesteren",
        "inhabitantNodeId": "122489964",
        "filename": "03.mp3"
    },
    {
        //"Baltasar/Boris",
        "inhabitantNodeId": "120481788",
        "filename": "04.mp3"
    },
    {
        //"Bo/Bo",
        "inhabitantNodeId": "120487877",
        "filename": "05.mp3"
    },
    {
        //"Cora/Cleo",
        "inhabitantNodeId": "120488373",
        "filename": "06.mp3"
    },
    {
        //"Clara/Clara",
        "inhabitantNodeId": "120491245",
        "filename": "07.mp3"
    },
    {
        //"David/David",
        "inhabitantNodeId": "120490688",
        "filename": "08.mp3"
    },
    {
        //"Dennis/Dennis",
        "inhabitantNodeId": "120490732",
        "filename": "09.mp3"
    },
    {
        //"Elon/Elon",
        "inhabitantNodeId": "120490907",
        "filename": "10.mp3"
    },
    {
        //"Emo/Emo",
        "inhabitantNodeId": "120490941",
        "filename": "11.mp3"
    },
    {
        //"Fiona/Fia",
        "inhabitantNodeId": "120491053",
        "filename": "12.mp3"
    },
    {
        //"Finn/Finn",
        "inhabitantNodeId": "120491102",
        "filename": "13.mp3"
    },
    {
        //"Gustav/Gustav",
        "inhabitantNodeId": "120491424",
        "filename": "14.mp3"
    },
    {
        //"Gina/Greta",
        "inhabitantNodeId": "120491599",
        "filename": "15.mp3"
    },
    {
        //"Hong/Hong",
        "inhabitantNodeId": "120491845",
        "filename": "16.mp3"
    },
    {
        //"Hui/Ho",
        "inhabitantNodeId": "120491933",
        "filename": "17.mp3"
    },
    {
        //"Inu/Ivan",
        "inhabitantNodeId": "120492086",
        "filename": "18.mp3"
    },
    {
        //"Ivo/Ino",
        "inhabitantNodeId": "120492276",
        "filename": "19.mp3"
    },
    {
        //"Julian/Julian",
        "inhabitantNodeId": "120492359",
        "filename": "20.mp3"
    },
    {
        //"Jo/Jo",
        "inhabitantNodeId": "120492417",
        "filename": "21.mp3"
    },
    {
        //"Kelly/Kelly",
        "inhabitantNodeId": "120492588",
        "filename": "22.mp3"
    },
    {
        //"Kai/Kaj",
        "inhabitantNodeId": "120492639",
        "filename": "23.mp3"
    },
    {
        //"Lea/Lea",
        "inhabitantNodeId": "120492729",
        "filename": "24.mp3"
    },
    {
        //"Leo/Leo",
        "inhabitantNodeId": "120492821",
        "filename": "25.mp3"
    },
    {
        //"Li/Lo",
        "inhabitantNodeId": "120493082",
        "filename": "26.mp3"
    },
    {
        //"Lu/Li",
        "inhabitantNodeId": "120493171",
        "filename": "27.mp3"
    },
    {
        //"Mirek/Mirek",
        "inhabitantNodeId": "120493229",
        "filename": "28.mp3"
    },
    {
        //"Magne Mikk/Mange Mikk",
        "inhabitantNodeId": "120493407",
        "filename": "29.mp3"
    },
    {
        //"Nanda/Nanda",
        "inhabitantNodeId": "120493507",
        "filename": "30.mp3"
    },
    {
        //"Nilan/Nilan",
        "inhabitantNodeId": "120493545",
        "filename": "31.mp3"
    },
    {
        //"Omar/Ola",
        "inhabitantNodeId": "120493648",
        "filename": "32.mp3"
    },
    {
        //"Opera/Opera",
        "inhabitantNodeId": "120494880",
        "filename": "33.mp3"
    },
    {
        //"Penny/Penny",
        "inhabitantNodeId": "120495018",
        "filename": "34.mp3"
    },
    {
        //"Polly/Polly",
        "inhabitantNodeId": "120495130",
        "filename": "35.mp3"
    },
    {
        //"Qamile/Qamile",
        "inhabitantNodeId": "120495259",
        "filename": "36.mp3"
    },
    {
        //"QI/QI",
        "inhabitantNodeId": "120495352",
        "filename": "37.mp3"
    },
    {
        //"Ruben/Ruben",
        "inhabitantNodeId": "120495446",
        "filename": "38.mp3"
    },
    {
        //"Rut/Rut",
        "inhabitantNodeId": "120495508",
        "filename": "39.mp3"
    },
    {
        //"Sara/Sara",
        "inhabitantNodeId": "120495562",
        "filename": "40.mp3"
    },
    {
        //"Simon/Simon",
        "inhabitantNodeId": "120495595",
        "filename": "41.mp3"
    },
    {
        //"Tara/Tara",
        "inhabitantNodeId": "120495692",
        "filename": "42.mp3"
    },
    {
        //"Tomas/Tomas",
        "inhabitantNodeId": "120495744",
        "filename": "43.mp3"
    },
    {
        //"Ulf/Urban",
        "inhabitantNodeId": "120495932",
        "filename": "44.mp3"
    },
    {
        //"Uffe/Ulf",
        "inhabitantNodeId": "120496059",
        "filename": "45.mp3"
    },
    {
        //"Viktor/Viktor",
        "inhabitantNodeId": "120496128",
        "filename": "46.mp3"
    },
    {
        //"Vivian/Viola",
        "inhabitantNodeId": "120496202",
        "filename": "47.mp3"
    },
    {
        //"Walter/Walter",
        "inhabitantNodeId": "120496254",
        "filename": "48.mp3"
    },
    {
        //"Willy Web/Willy Web",
        "inhabitantNodeId": "120496335",
        "filename": "49.mp3"
    },
    {
        //"Xander/Xander",
        "inhabitantNodeId": "120496559",
        "filename": "50.mp3"
    },
    {
        //"X2.5/X2.5",
        "inhabitantNodeId": "120496690",
        "filename": "51.mp3"
    },
    {
        //"Ylva/Ylva",
        "inhabitantNodeId": "120496804",
        "filename": "52.mp3"
    },
    {
        //"Yngve/Yngve",
        "inhabitantNodeId": "120496828",
        "filename": "53.mp3"
    },
    {
        //"Zola/Zola",
        "inhabitantNodeId": "120496909",
        "filename": "54.mp3"
    },
    {
        //"Zalo/Zalo",
        "inhabitantNodeId": "120496959",
        "filename": "55.mp3"
    },
    {
        //"Æsa/Ärla",
        "inhabitantNodeId": "120497376",
        "filename": "56.mp3"
    },
    {
        //"Æge/Ärling",
        "inhabitantNodeId": "120497486",
        "filename": "57.mp3"
    },
    {
        //"Ørjan/Örjan",
        "inhabitantNodeId": "120497664",
        "filename": "58.mp3"
    },
    {
        //"Øyvind/Östen",
        "inhabitantNodeId": "120499322",
        "filename": "59.mp3"
    },
    {
        //"Åsne/Åsa",
        "inhabitantNodeId": "120497044",
        "filename": "60.mp3"
    },
    {
        //"Åge/Åke",
        "inhabitantNodeId": "120497143",
        "filename": "61.mp3"
    },
    {
        //Alfa,
        "inhabitantNodeId": "122489707",
        "filename": "62.mp3"
    },
    {
        //Bravo,
        "inhabitantNodeId": "122489805",
        "filename": "63.mp3"
    },
    {
        //Charlie,
        "inhabitantNodeId": "122489927",
        "filename": "64.mp3"
    },
    {
        //Delta,
        "inhabitantNodeId": "122489955",
        "filename": "65.mp3"
    },



];