import React from 'react';
import styles from './CardListing.module.css';

/** Component that can be wrapped around list elements to make them appear in a grid. The list elements has to be direct children of this component for the CSS to work properly */
export const CardListing = ({ children }: { children: React.ReactNode }) => {
    return (
        <div className={styles['card-list']}>
            {children}
        </div>
    );
};