import React from "react";
import {Link} from "react-router-dom";
import styles from "./TopicNavTile.module.css"

interface Props {
    image?: string;
    text: string;
    link: string;
    className?: string;
    languageId?: number;
}

export const TopicNavTile = ({image, text, link, className, languageId}: Props) => {
    let smallSize = languageId !== 8 ? styles.smallSize : '';

    return (
        <Link
            className={`${styles.link} ${className}`}
            to={link}
        >
            <div
                className={`${styles.container} ${smallSize}`}
            >
                {image && <img className={styles.image} src={image} alt={""} />}
                <div className={styles.text}>
                    {text}
                </div>
            </div>
        </Link>
    )
}