import axios, { AxiosError } from "axios";
import { SessionInfoNode } from "./SessionInfoNode";

/** Cache busting made to prevent browser from using a cached response from Cartoonist.
 * Going from ABP to Cartoonist and back to ABP sometimes caused CORS error for getSessionInfo and getBasicSessionInfo.  */
const cacheBust = () => new Date().getTime();

/** Used as condition for widthCredentials, which needs to be false for development when using Polypane browser.
 * The withCredentials option needs to be true in prod, however. 
 */
const isDevelop = process.env.NODE_ENV === 'development';

export const getSessionInfo = async () => {

    let data : SessionInfoNode | null = null;
    let error : AxiosError | null = null;
    let isPending = null;
    let host = window.location.href.indexOf("alphabetplanet-stage.creaza.com") > -1 ?
        'https://stage.creaza.com' : 'https://www.creaza.com';

    const url = host + '/ICSXapi/users/GetSessionInfo?' + cacheBust();
    
    await axios.get(url, {withCredentials: isDevelop ? false : true})
        .then(response => {
            if (response.data && response.data.user && response.data.user.audio_recording_popups && response.data.user.audio_recording_popups.roles)
                response.data.user.roles = response.data.user.audio_recording_popups.roles;

            data = response.data
        })
        .catch(e => error = e);

    return { data, isPending, error };
}

export const getBasicSessionInfo = async () => {
    let data : SessionInfoNode | null = null;
    let error : AxiosError | null = null;
    let host = window.location.href.indexOf("alphabetplanet-stage.creaza.com") > -1 ?
        'https://stage.creaza.com' : 'https://www.creaza.com';

    const url = host + '/ICSXapi/users/GetBasicSessionInfo?' + cacheBust();
    
    await axios.get(url, {withCredentials: isDevelop ? false : true})
        .then(response => data = response.data)
        .catch(e => error = e);

    return { data, error };
}