import React, { useContext } from 'react';
import { CategoryTreeNode } from '../../../utils/CategoryTreeNode';
import { ObjectType } from '../../../utils/exerciseService';
import ExercisesQueryParameters from '../../../utils/ExercisesQueryParameters';
import useFetchExercises from '../../../utils/useFetchExercises';
import PageGeneralMain from '../../PageGeneralMain';
import AlphabetNavBar from '../../UIComponents/AlphabetNavBar';
import { Breadcrumbs } from '../../UIComponents/Breadcrumbs/Breadcrumbs';
import { Breadcrumb } from "../../UIComponents/Breadcrumbs/Breadcrumb";
import { StoryPreview } from '../../UIComponents/StoryPreview/StoryPreview';
import { WrapMainContentWithOnboarding } from '../../UIUtils/WrapMainContentWithOnboarding/WrapMainContentWithOnboarding';

import styles from './StoriesListing.module.css'
import { BackButtonLink } from '../../UIComponents/BackButtonLink/BackButtonLink';
import { getExerciseThumbnail } from '../../../utils/getExerciseThumbnail';
import { BasicSessionInfoContextType, basicSessionInfoContext } from '../../../utils/BasicSessionContext';

interface StoriesListingProps {
    url: string;
    breadcrumbs?: Breadcrumb[];
    storiesCategoryNode: CategoryTreeNode;
    storiesPageNode: CategoryTreeNode;
}



export const StoriesListing = ({ url, breadcrumbs, storiesCategoryNode, storiesPageNode }: StoriesListingProps) => {
    /*     const norwegianTitle = mainStoriesCategoryNode.name; */

    const { cmsLangId, basicSessionInfo } = useContext(basicSessionInfoContext) as BasicSessionInfoContextType;


    const title = storiesPageNode.children.find(n => n.name === "Title")?.children.find(n => n.name.includes(cmsLangId))?.description;
    //const categoriesThatContainFortellinger = categories.filter(category => category.children.find(child => child.name === "Fortellinger"));

    /** It's not given that a language of stories should be the same as the language of the session. 
      * For example, if the user is Japaneese and is using the app to learn Swedish, we might want the language of the stories to be in Swedish instead of Japaneese.
      */
    const storyLanguageId = basicSessionInfo?.languageId; // For now, language of the story is the same as the language of the session.

    // Fetch exercises so we can get their thumbnail for StoryPreview cards
    const params: ExercisesQueryParameters = {
        objectType: ObjectType.crz_exercise,
        hasContent: true, // Exercise is the stories main exercise (not )
        categoryId: storiesCategoryNode.id,
        languageId: storyLanguageId
    }
    const { data, isPending, error } = useFetchExercises(params);

    return (
        <>
            <img alt="" className={styles['background-image']} src='https://alphabet-planet-ui.s3.eu-west-1.amazonaws.com/images-ui/alphabet_planet_ui_page_biographies.svg' />
            {/* <Breadcrumbs breadcrumbs={breadcrumbs} currentPageTitle={title} pushContentDown /> */}
            <PageGeneralMain>
                <BackButtonLink linkTo={"./.."} />
                <h1 className={styles['page-title']}>{title}</h1>
                {/* <WrapMainContentWithOnboarding audio="https://alphabet-planet-ui.s3.eu-west-1.amazonaws.com/audio-info/7.mp3" > TODO: uncomment the WrapMainContentWithOnboarding after the audio file is fixed */}
                <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-evenly", gap: "24px" }}>
                    {storiesCategoryNode.children.map(story => {
                        /* Using the mainExercise's thumbnail */
                        const correspondingExercise = data?.exercises.find(ex => ex.categories.includes(story.id));
                        const imgSrc = correspondingExercise && getExerciseThumbnail(correspondingExercise);
                        /* ----- */

                        const letter = story.name[1];

                        return (
                            (correspondingExercise && imgSrc) &&
                                <div key={story.id}>
                                    <div id={letter} className={'letter_character'} style={{ position: 'relative', top: "38px", left: "85%", pointerEvents: "none", color: "var(--dark_purple)", fontWeight: "600", backgroundColor: "var(--white)", maxWidth: "1.5em", paddingInline: "0.3em", borderRadius: "100%", aspectRatio: "1", textAlign: 'center' }}>{letter}</div>
                                    <StoryPreview
                                        imgSrc={imgSrc}
                                        alt=''
                                        headline={correspondingExercise.name}
                                        href={story.id.toString()}
                                    />
                                </div>
                        );
                    })}
                </div>
                {/*  </WrapMainContentWithOnboarding> */}
                <div className='whiteSpace' style={{ paddingBlock: "64px" }}></div>
            </PageGeneralMain>
            <AlphabetNavBar />
        </>
    );
};