import styles from './Playground.module.css'
import { useContext } from 'react';
import { CategoryTreeNode } from '../../utils/CategoryTreeNode';
import ExercisesQueryParameters from '../../utils/ExercisesQueryParameters';
import useFetchExercises from '../../utils/useFetchExercises';
import PageGeneralMain from '../PageGeneralMain';
import { Breadcrumb } from "../UIComponents/Breadcrumbs/Breadcrumb";
import { ExerciseListing } from '../UIComponents/ExerciseListing/ExerciseListing';
import { BackButtonLink } from '../UIComponents/BackButtonLink/BackButtonLink';
import { BasicSessionInfoContextType, basicSessionInfoContext } from '../../utils/BasicSessionContext';
import { Topic } from '../../utils/Topic';

interface PlaygroundProps {
    breadcrumbs?: Breadcrumb[];
    /** The node that consists of the CMS values */
    pageNode: CategoryTreeNode;
    /** The node that the authors use when assigning activity exercises */
    activitiesCategoryNode: CategoryTreeNode;
}

const Playground = ({ breadcrumbs, pageNode, activitiesCategoryNode }: PlaygroundProps) => {
    const { cmsLangId, basicSessionInfo } = useContext(basicSessionInfoContext) as BasicSessionInfoContextType;

    const queryParams: ExercisesQueryParameters = { categoryId: activitiesCategoryNode.id, languageId: basicSessionInfo?.languageId }
    const { data, isPending, error } = useFetchExercises(queryParams);

    const cmsValues = {
        title: pageNode.children.find(n => n.name === "Title")?.children.find(n => n.name.includes(cmsLangId))?.description,
    }

    const filterTags = activitiesCategoryNode.children;

    return (
        <>
            <img className={styles['background-image']} alt="" src="https://alphabet-planet-ui.s3.eu-west-1.amazonaws.com/images-ui/alphabet_planet_ui_page_playground.svg" ></img>
            {/* <Breadcrumbs breadcrumbs={breadcrumbs} currentPageTitle={categoryNode.name} pushContentDown /> */}
            <PageGeneralMain>
                <BackButtonLink linkTo={"/teacher"} title={"Gå tilbake"} />
                <h1 className={styles['page-title']} >{cmsValues.title}</h1>
                {data?.exercises && <ExerciseListing exercises={data.exercises} filterTags={filterTags} />}
            </PageGeneralMain>
        </>
    );
};


export default Playground;