import { useState } from "react";
import { Link } from "react-router-dom";
import styles from "./ScrollToAnhorNav.module.css";
import { parseToURL } from "../../../utils/parseToURL";
import { FaChevronRight } from "react-icons/fa";

interface Props {
    heading: string;
    navItems: string[];
}

/**
 *  Nav element as a table of content for one page
 * @returns nav element containing links to each anchor point 
 */
export const ScrollToAnchorNav = ({ heading, navItems }: Props) => {

    const [isClosed, setIsClosed] = useState(true);

    return (
        <nav className={styles['scroll-to-achor-nav']}>
            <div className={styles.header}>
                <div className={styles.toggle} onClick={() => setIsClosed(prev => !prev)}>
                    <span
                        className={`${styles.icon} ${isClosed ? '' : styles['icon--open']}`}
                        aria-hidden="true"
                    >
                        <FaChevronRight />
                    </span>
                    <h2
                        className={`${styles.heading}`}
                    >
                        {heading}
                    </h2>
                </div>
            </div>
            <ol className={`${styles.list} ${isClosed ? styles['list--closed'] : ''}`}>
                {navItems.map(navItem => {
                    const hash = parseToURL(navItem);
                    return (
                        <li className={styles['list-item']} key={hash}>
                            <Link to={`#${hash}`} >{navItem}</Link>
                        </li>
                    )
                })}
            </ol>
        </nav>
    )
}