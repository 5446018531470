import styles from './RoleSelectorPage.module.css';
import {useMGMTTranslations, useTranslatePage} from "../../utils/TranslationHooks";
import { Link } from "react-router-dom";
import { useContext, useState } from 'react';
import { BasicSessionInfoContextType, basicSessionInfoContext } from '../../utils/BasicSessionContext';
import Modal from "../UIComponents/Modal/Modal";
import AssignmentByCode from "./AsignmentByCode";

interface RoleSelectorPageProps {
}

export const RoleSelectorPage = ({ }: RoleSelectorPageProps) => {
  const mgmtTranslate = useMGMTTranslations();
  const translatePage = useTranslatePage("SelectRole");
  const StudentBtnIconSrc =
    "https://alphabet-planet-ui.s3.eu-west-1.amazonaws.com/images-ui/alphabet_planet_ui_planet.svg";

  const { basicSessionInfo } = useContext(basicSessionInfoContext) as BasicSessionInfoContextType;
  const languageId = basicSessionInfo?.languageId;
  const showTeacherLinkDescription = languageId !== 8; // Description should not be shown in Swedish
  const [showAssignmentCodePopup, setShowAssignmentCodePopup] = useState(false);

  return (
    <nav className={styles["role-selector-menu"]}>
      <Link to={"/student"}>
        <div className={`${styles["student-link"]} ${styles["menu-element"]}`}>
          <img
            src={StudentBtnIconSrc}
            alt=""
            className={styles["student-icon"]}
          />
          <div className={styles["student-link-text-wrapper"]}>
            <div className={styles["student-text"]}>
              {translatePage("StudentLink-label")}
            </div>
            <div className={styles["student-link-description"]}>
              {translatePage("StudentLink-extended-label")}
            </div>
          </div>
        </div>
      </Link>

        <button
            onClick={() => setShowAssignmentCodePopup(true)}
            className={`${styles["menu-element"]} ${styles["code-link"]}`}
        >
            <img
                className={styles["code-img"]}
                alt=""
                src={"https://alphabet-planet-ui.s3.eu-west-1.amazonaws.com/images-ui/assigment_by_code.jpg"}/>
            {mgmtTranslate("assignment_byCode_button")}
        </button>
        <Modal
            backButton={true}
            style={{maxWidth: "500px"}}
            show={showAssignmentCodePopup} heading={mgmtTranslate("assignment_byCode_heading") || ""}
            backdropClicked={() => setShowAssignmentCodePopup(false)}
        >
            <AssignmentByCode/>
        </Modal>

      <Link
        to={"/teacher"}
        className={styles["menu-wrapper"]}
      >
        <div className={`${styles["teacher-link"]} ${styles["menu-element"]}`}>
          <div>{translatePage("TeacherLink-label")}</div>
          {showTeacherLinkDescription &&
            <div className={styles["teacher-link-description"]}>{translatePage("TeacherLink-extended-label")}</div>
          }
        </div>
      </Link>

      <Link to={"https://www.creaza.com/home"}>
        <div className={`${styles["exit-link"]} ${styles["menu-element"]}`}>
          {translatePage("CloseAbpLink")}
        </div>
      </Link>
    </nav>
  );
};