import DOMPurify from 'dompurify';
import {useContext, useEffect, useState} from 'react';

import styles from './AssignmentPrompt.module.css';
import {CategoryTreeContextType, categoryTreeContext} from '../../../utils/CategoryTreeContext';
import {BasicSessionInfoContextType, basicSessionInfoContext} from '../../../utils/BasicSessionContext';
import {useMGMTTranslations} from "../../../utils/TranslationHooks";

const AssignmentPrompt = (props: any) => {
    const mgmtTranslate = useMGMTTranslations();

    /* ------ Get CMS values ------- */
    const {cmsLangId} = useContext(basicSessionInfoContext) as BasicSessionInfoContextType;
    const {components, globalTranslations} = useContext(categoryTreeContext) as CategoryTreeContextType;

    const assignActivityFormNode = components?.children.find(node => node.name === "AssignActivityForm");
    console.assert(assignActivityFormNode, "Can't find AssignActivityForm as child of " + components?.name);

    const titleInputLabelNode = assignActivityFormNode?.children.find(node => node.name === "TitleInput-label");
    console.assert(titleInputLabelNode, "Can't find the node TitleInput-label as child of " + assignActivityFormNode?.name);
    const titleInputLabel = titleInputLabelNode?.children.find(node => node.name.includes(cmsLangId))?.description;
    console.assert(titleInputLabel, "Can't find node with language id" + cmsLangId + " as child of " + titleInputLabelNode?.name);

    const dueDateInputLabelNode = assignActivityFormNode?.children.find(node => node.name === "DueDateInput-label");
    console.assert(dueDateInputLabelNode, "Can't find the node DueDateInput-label as child of " + assignActivityFormNode?.name);
    const dueDateInputLabel = dueDateInputLabelNode?.children.find(node => node.name.includes(cmsLangId))?.description;
    console.assert(dueDateInputLabel, "Can't find node with languageId " + cmsLangId + " as child of " + dueDateInputLabelNode?.name);

    const createActivityLabelNode = assignActivityFormNode?.children.find(node => node.name === "CreateActivity-label");
    console.assert(createActivityLabelNode, "Can't find node CreateActivity-label as child of " + assignActivityFormNode?.name);
    const createActivityLabel = createActivityLabelNode?.children.find(node => node.name.includes(cmsLangId))?.description;
    console.assert(createActivityLabel, "Can't find node with language id " + cmsLangId + " as child of " + createActivityLabelNode?.name);

    const cancelLabelNode = globalTranslations?.children.find(node => node.name === "Cancel-translation");
    console.assert(cancelLabelNode, "Can't find node Cancel-translation as child of " + globalTranslations)
    const cancelLabel = cancelLabelNode?.children.find(node => node.name.includes(cmsLangId))?.description;
    console.assert(cancelLabel, "Can't find node with language id " + cmsLangId + " as child of " + cancelLabelNode?.name);

    /* --------------------------------- */


    const [exercise, setExercise] = useState(props.exercise);
    const [exerciseTitle, setTitle] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [dateTime, setDateTime] = useState(dueDateInputLabel || "");
    const disabled = false //props.confirmBtnDisabled(inputValue);

    const newExercise = props.exercise;

    useEffect(() => {
        setExercise(props.exercise);
        if (props.exercise) {
            setTitle(props.exercise.name);
        }
    }, [])


    useEffect(() => {
        if (exercise?.id !== newExercise?.id)
            setTitle(props.exercise?.name);
        setDescription(props.exercise?.description || "")
    }, [exercise?.id, newExercise?.id, props.exercise?.name, props.exercise?.description]);


    const handleTitleChange = (e: any) => {
        setTitle(e.target.value);
    }

    const handleDescriptionChange = (e: any) => {
        setDescription(e.target.value)
    }

    const handleDateChange = (e: any) => {
        setDateTime(e.target.value);
    }

    const handleConfirmClicked = () => {
        console.log(props.exercise);
        let milliseconds;

        if (dateTime) {
            const date = new Date(dateTime);
            milliseconds = date.getTime();
        }

        let requestBody = {
            assignerId: props.session.user.id, //get user session id
            invitationId: props.newLinkNode.id,
            title: DOMPurify.sanitize(exerciseTitle), // exercise title
            description: DOMPurify.sanitize(description), // from exercise?
            contentId: props.exercise.contentId,
            exerciseId: props.exercise.id,//id of exercise?
            dueDate: milliseconds, // from date input
            assignmentId: ''//no value?
        }

        // Sanitize anchor tags.
        const attribute = "href=";
        if (requestBody.description.includes(attribute)) {
            requestBody.description = exercise.description.replaceAll(attribute, 'target="_blank" rel="noopener noreferrer" ' + attribute);
        }

        props.confirmClicked(requestBody);
    };

    const handleCancelClicked = () => {
        dueDateInputLabel && setDateTime(dueDateInputLabel);
        setTitle(props.exercise?.name);
        props.cancelClicked();
    };

    let promptContent = <div></div>

    if (true) {
        promptContent =
            <div className={styles.prompt}>
                <h2 className={styles.header}>{mgmtTranslate("assigment_assignExercise")}</h2>

                <label>
                    <div className={styles.label}>{mgmtTranslate("assignment_exerciseTitle")}</div>
                    <input
                        type="text"
                        className={styles.input}
                        value={exerciseTitle}
                        onChange={e => handleTitleChange(e)}>
                    </input>
                </label>

                <label>
                    <div className={styles.label}>{mgmtTranslate("assignment_exerciseDescription")}</div>
                    <textarea
                        className={styles.textarea}
                        value={description}
                        onChange={e => handleDescriptionChange(e)}>
                    </textarea>
                </label>
                <div className={styles.buttons}>
                    <button
                        className={styles.button}
                        disabled={disabled}
                        onClick={() => handleConfirmClicked()}>
                        {createActivityLabel}
                    </button>
                    <button
                        className={styles.button}
                        onClick={() => handleCancelClicked()}>
                        {cancelLabel}
                    </button>
                </div>
            </div>
    }


    return (
        <>
            {promptContent}
        </>
    )
};

export default AssignmentPrompt;