import React from "react";
import styles from "./MediumHeader.module.css";
import {ThemeName, Themes} from "../../../utils/Themes";

interface Props {
    headingLevel: number;
    children: any;
    themeName?: ThemeName;
}

export const MediumHeader = ({children, headingLevel, themeName}: Props) => {
    const Heading = `h${headingLevel}`
    return (
        //@ts-ignore
        <Heading
            className={styles['medium-header']}
            style={{
                color: themeName ? Themes[themeName].backgroundColor : "white",
                WebkitTextStroke: `3px ${themeName ? Themes[themeName].darkColor : "black"}`
            }}
        >
            {children}
        </Heading>
    )
}