import { useContext, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import PAGE_16_9 from '../Page_16_9';
import Modal from '../UIComponents/Modal/Modal';

import styles from './Map.module.css';

/*
import alphabetMap from '../../assets/alphabet-planet/map.svg';
import btn_a from '../../assets/alphabet-planet/button_a.svg'; 
import btn_b from '../../assets/alphabet-planet/button_b.svg';
import btn_c from '../../assets/alphabet-planet/button_c.svg';
import btn_d from '../../assets/alphabet-planet/button_d.svg';
import btn_e from '../../assets/alphabet-planet/button_e.svg';
import btn_f from '../../assets/alphabet-planet/button_f.svg';
import btn_g from '../../assets/alphabet-planet/button_g.svg';
import btn_h from '../../assets/alphabet-planet/button_h.svg';
import btn_i from '../../assets/alphabet-planet/button_i.svg';
import btn_j from '../../assets/alphabet-planet/button_j.svg';
import btn_k from '../../assets/alphabet-planet/button_k.svg';
import btn_l from '../../assets/alphabet-planet/button_l.svg';
import btn_m from '../../assets/alphabet-planet/button_m.svg';
import btn_n from '../../assets/alphabet-planet/button_n.svg';
import btn_o from '../../assets/alphabet-planet/button_o.svg';
import btn_p from '../../assets/alphabet-planet/button_p.svg';
import btn_q from '../../assets/alphabet-planet/button_q.svg';
import btn_r from '../../assets/alphabet-planet/button_r.svg';
import btn_s from '../../assets/alphabet-planet/button_s.svg';
import btn_t from '../../assets/alphabet-planet/button_t.svg';
import btn_u from '../../assets/alphabet-planet/button_u.svg';
import btn_v from '../../assets/alphabet-planet/button_v.svg';
import btn_w from '../../assets/alphabet-planet/button_w.svg';
import btn_x from '../../assets/alphabet-planet/button_x.svg';
import btn_y from '../../assets/alphabet-planet/button_y.svg';
import btn_z from '../../assets/alphabet-planet/button_z.svg';
import btn_ae from '../../assets/alphabet-planet/button_ae.svg';
import btn_oe from '../../assets/alphabet-planet/button_oe.svg';
import btn_aa from '../../assets/alphabet-planet/button_aa.svg';
import btn_book from '../../assets/alphabet-planet/button_book.svg';
import btn_arrow from '../../assets/alphabet-planet/button_arrow.svg';
import btn_mic from '../../assets/alphabet-planet/button_mic.svg';
*/
import { CategoryTreeNode } from "../../utils/CategoryTreeNode";
import LetterPage from './LetterPage/LetterPage';
import { NewLinkNode } from '../../utils/AssignmentService/NewLinkNode';
import { createNewAssignment, initAssign } from '../../utils/AssignmentService/AssignmentService';
import { getSessionInfo } from '../../utils/SessionService/SessionService';
import AssignmentPrompt from '../UIComponents/Prompts/AssignmentPrompt';
import { SessionInfoNode } from '../../utils/SessionService/SessionInfoNode';
import AssignmentLinkAndRedirectPrompt from '../UIComponents/Prompts/AssignmentLinkAndRedirectPrompt';
import { FaWindowClose } from 'react-icons/fa';
import { parseToURL } from '../../utils/parseToURL';
import { BackButtonLink } from '../UIComponents/BackButtonLink/BackButtonLink';
import useFetchExercises from '../../utils/useFetchExercises';
import { Exercise } from '../../utils/exerciseService';
import { PlanetContextType, planetContext } from '../../utils/PlanetContext';
import { BasicSessionInfoContextType, basicSessionInfoContext } from '../../utils/BasicSessionContext';
import { MapIntroVideo } from "./MapIntroVideo";
import { CategoryTreeContextType, categoryTreeContext } from '../../utils/CategoryTreeContext';

//console.log("ENVIRONMENT S3 URL: " + process.env['REACT_APP_UI_URL']);
const baseUrlAlphabetUi = 'https://alphabet-planet-ui.s3.eu-west-1.amazonaws.com/images-ui/';
const alphabetMap = baseUrlAlphabetUi + 'map_without_library_stage.svg' //'alphabet_planet_ui_map.svg';
const btn_a = baseUrlAlphabetUi + 'alphabet_planet_ui_button_a.svg';
const btn_b = baseUrlAlphabetUi + 'alphabet_planet_ui_button_b.svg';
const btn_c = baseUrlAlphabetUi + 'alphabet_planet_ui_button_c.svg';
const btn_d = baseUrlAlphabetUi + 'alphabet_planet_ui_button_d.svg';
const btn_e = baseUrlAlphabetUi + 'alphabet_planet_ui_button_e.svg';
const btn_f = baseUrlAlphabetUi + 'alphabet_planet_ui_button_f.svg';
const btn_g = baseUrlAlphabetUi + 'alphabet_planet_ui_button_g.svg';
const btn_h = baseUrlAlphabetUi + 'alphabet_planet_ui_button_h.svg';
const btn_i = baseUrlAlphabetUi + 'alphabet_planet_ui_button_i.svg';
const btn_j = baseUrlAlphabetUi + 'alphabet_planet_ui_button_j.svg';
const btn_k = baseUrlAlphabetUi + 'alphabet_planet_ui_button_k.svg';
const btn_l = baseUrlAlphabetUi + 'alphabet_planet_ui_button_l.svg';
const btn_m = baseUrlAlphabetUi + 'alphabet_planet_ui_button_m.svg';
const btn_n = baseUrlAlphabetUi + 'alphabet_planet_ui_button_n.svg';
const btn_o = baseUrlAlphabetUi + 'alphabet_planet_ui_button_o.svg';
const btn_p = baseUrlAlphabetUi + 'alphabet_planet_ui_button_p.svg';
const btn_q = baseUrlAlphabetUi + 'alphabet_planet_ui_button_q.svg';
const btn_r = baseUrlAlphabetUi + 'alphabet_planet_ui_button_r.svg';
const btn_s = baseUrlAlphabetUi + 'alphabet_planet_ui_button_s.svg';
const btn_t = baseUrlAlphabetUi + 'alphabet_planet_ui_button_t.svg';
const btn_u = baseUrlAlphabetUi + 'alphabet_planet_ui_button_u.svg';
const btn_v = baseUrlAlphabetUi + 'alphabet_planet_ui_button_v.svg';
const btn_w = baseUrlAlphabetUi + 'alphabet_planet_ui_button_w.svg';
const btn_x = baseUrlAlphabetUi + 'alphabet_planet_ui_button_x.svg';
const btn_y = baseUrlAlphabetUi + 'alphabet_planet_ui_button_y.svg';
const btn_z = baseUrlAlphabetUi + 'alphabet_planet_ui_button_z.svg';
const btn_ae = baseUrlAlphabetUi + 'alphabet_planet_ui_button_ae.svg';
const btn_oe = baseUrlAlphabetUi + 'alphabet_planet_ui_button_oe.svg';
const btn_aa = baseUrlAlphabetUi + 'alphabet_planet_ui_button_aa.svg';
const btn_book = baseUrlAlphabetUi + 'library_round.svg'; //'alphabet_planet_ui_button_book.svg';
const btn_arrow = baseUrlAlphabetUi + 'alphabet_planet_ui_button_beach_ball.svg';
const btn_mic = baseUrlAlphabetUi + 'stage_round.svg'; //'alphabet_planet_ui_button_mic.svg';
const btn_begreper : string = 'https://alphabet-planet-ui.s3.eu-west-1.amazonaws.com/topic-tree/images/begreper.svg';

interface mapProps {
    categories?: CategoryTreeNode[];
    songsPageTitle?: string;
    libraryPageTitle?: string;
    storiesPageTitle: string;
    lettersInOrder: (CategoryTreeNode | undefined)[];
    inhabitantsNode: CategoryTreeNode;
    planetIdNode: CategoryTreeNode;
    letterPagePageNode: CategoryTreeNode;
}

function Map({ categories, songsPageTitle, libraryPageTitle, storiesPageTitle, lettersInOrder, inhabitantsNode, planetIdNode, letterPagePageNode }: mapProps) {
    const [showIntroVideo, setShowIntroVideo] = useState(false);

    const classNameLetter = styles["nav-map__btn-letter"];
    const classNameAlternative = styles["nav-map__btn-alternative"];

    const { pages } = useContext(categoryTreeContext) as CategoryTreeContextType;
    const pageNode = pages?.children.find(node => node.name === "Map-page");
    console.assert(pageNode, "Can't find the node Map-page as child of " + pages?.name);
    const { cmsPlanetId } = useContext(planetContext) as PlanetContextType;
    const { basicSessionInfo, cmsLangId } = useContext(basicSessionInfoContext) as BasicSessionInfoContextType;
    const languageId = basicSessionInfo?.languageId;

    const [selectedMapItem, setSelectedMapItem] = useState<CategoryTreeNode | null | undefined>(null);

    const [showToTeacherModal, setShowToTeacherModal] = useState(false);

    const displayInfo = (target: Element) => {
        const letter = target.getAttribute("alt"); // temporary way of getting the letter
        const selectedMapItem = lettersInOrder?.find(n => n?.name[0] === letter);
        selectedMapItem && setSelectedMapItem(selectedMapItem);
    }

    /* Lists made in hurry as substitute for the list above. Works for Norwegian and Swedish alphabet. */
    let tempLeftList = [54.8, 46.8, 82.8, 82.8, 8.3, 76.8, 24.8, 49.4, 27.8, 60.8, 15.8, 44.8, 1.8, 32.8, 62.8, 66.6, 1.8, 24.3, 33.8, 67.8, 11.8, 63.8, 83.8, 90.8, 21, 18, 72.8, 48.4, 16.8]; // TODO: get values from CMS.
    let tempTopList = [62, 88, 86, 29, 2.4, 40, 58, 13, 30, 87, 48, 61, 12, 59.5, 56, 21, 30, 13, 30, 86.5, 31.6, 38, 2, 10, 34, 77, 62.8, 36, 2]; // TODO: get values from CMS.

    // Rearrange letters if Swedish
    if (languageId === 8) {
        rearrangepositionsForSwedish(tempLeftList);
        rearrangepositionsForSwedish(tempTopList);
    }
    function rearrangepositionsForSwedish(list: number[]) {
        const lastElement = list.pop();
        const secondLastElement = list.pop();
        const thirdLastElement = list.pop();
        (lastElement && secondLastElement && thirdLastElement) && list.push(lastElement, thirdLastElement, secondLastElement);
    }

    const liElements = lettersInOrder.map((letterNode, index) => {
        return (
            letterNode &&
            <li
                className={styles["nav-map__btn-letter"]}
                style={{ ['--order' as any]: index + 1, left: tempLeftList[index].toString() + "%", top: tempTopList[index].toString() + "%" }}
                key={letterNode.id} >
                <button className={styles.animate_on_load} onClick={(e) => displayInfo(e.currentTarget.children[0])} >
                    <img alt={letterNode.name[0]} src={"https://alphabet-planet-ui.s3.eu-west-1.amazonaws.com/images-ui/alphabet_planet_ui_button_" + letterNode.name[1] + ".svg"} />
                </button>
            </li>
        )
    })

    // Add the Library and Songs page link
    liElements.push(
        <>
            {libraryPageTitle &&
                <li
                    className={classNameAlternative}
                    style={{ ['--order' as any]: 32, left: "5%", top: "42%" }}
                >
                    <Link to="library" >
                        <div className={styles.animate_on_load} >
                            <img
                                src={btn_book}
                                alt="" //TODO: get value from CMS
                            />
                        </div>
                    </Link>
                </li>
            }
            {songsPageTitle &&
                <li
                    className={classNameAlternative}
                    style={{ ['--order' as any]: 33, left: "83%", top: "57%" }}
                >
                    <Link to={"" + parseToURL(songsPageTitle)} >
                        <div className={styles.animate_on_load} >
                            <img
                                src={btn_mic}
                                alt="" //TODO: get value from cms
                            />
                        </div>
                    </Link>
                </li>
            }
            {languageId !== 8 ?
                <li
                    className={classNameAlternative}
                    style={{ ['--order' as any]: 34, left: "37%", top: "10%" }}
                >
                    <Link to={window.location.href.indexOf("alphabetplanet-stage.creaza.com") > -1 ?
                        "https://portal-stage.creaza.com/?page=concept&group=norsk" :
                        "https://portal.creaza.com/?page=concept&group=norsk"} >
                        <div className={styles.animate_on_load} >
                            <img
                                src={btn_begreper}
                                alt="" //TODO: get value from cms
                            />
                        </div>
                    </Link>
                </li> : null
            }
        </>
    )

    const visitExerciseCategoryNode = categories?.find(n => n.name.includes("Letter's main") && n.name.includes(cmsPlanetId!));
    const visitExerciseCategoryId = visitExerciseCategoryNode?.id;
    //const swedishCorrespondingExerciseCategoryNode = swedishVisitExerciseCategoryNode?.children.find(n => n.name === selectedMapItem?.name);
    const storiesExerciseCategoryNode = categories?.find(n => n.name.includes("Stories") && n.name.includes(cmsPlanetId!));

    const { data: dataExercises, isPending, error } = useFetchExercises({ hitsPerPage: 1000, languageId: languageId }, [storiesExerciseCategoryNode!.id, visitExerciseCategoryId!]);
    const visitExercises = visitExerciseCategoryNode && dataExercises?.exercises.filter(ex => ex.categories.includes(visitExerciseCategoryNode.id));
    const storiesExercises = storiesExerciseCategoryNode && dataExercises?.exercises.filter(ex => ex.categories.includes(storiesExerciseCategoryNode.id) && ex.hasContent === true);
    const selectedLettersStories = selectedMapItem ? storiesExerciseCategoryNode?.children.filter(n => n.name.startsWith("<" + selectedMapItem.name)) : undefined;
    const selectedLettersStoriesIds = selectedLettersStories?.map(storyNode => storyNode.id);
    const selectedStoriesExercises = (selectedMapItem) ? storiesExercises?.filter(ex => selectedLettersStoriesIds?.some(id => ex.categories.includes(id))) : undefined;

    const selectedLetterInhabitants = inhabitantsNode.children.filter(inhabitantNode => {
        return inhabitantNode.children.find(n => n.name === "BelongsToLetter")?.children.find(n => n.name.includes(planetIdNode.description))?.description === selectedMapItem?.name;
    })

    // Get the Swedish visit-me exercise by finding the name of one of the characters in the exercise name // TODO: find a better way
    const tempInhabitantNameNode = selectedMapItem ? selectedLetterInhabitants[0]?.children.find(n => n.name === "Name")?.children.find(n => n.name.includes(planetIdNode.description)) : undefined;
    const tempSwedishSelectedLetterVisitExercise = tempInhabitantNameNode && visitExercises?.find(ex => ex.name.includes(tempInhabitantNameNode.description)); // TODO: hent norsk besøk-meg oppgave



    // ----- Audio for inforation button
    const alphabetMasterAudioRef = useRef<null | HTMLAudioElement>(null);
    const closeBtnRef = useRef<null | HTMLButtonElement>(null);
    const onboardingAudioUrlNode = pageNode?.children.find(node => node.name === "OnboardingAudioUrl"); // "https://alphabet-planet-ui.s3.eu-west-1.amazonaws.com/audio-info/1fugl.mp3" // "https://alphabet-planet-ui.s3.eu-west-1.amazonaws.com/audio-info/1.mp3";
    console.assert(onboardingAudioUrlNode, "Can't find node OnboardingAudioUrl as child of " + pageNode?.name);
    const alphabetMasterAudioSrc = (cmsPlanetId && cmsLangId) &&
        onboardingAudioUrlNode?.children.find(node => node.name.includes(cmsPlanetId && cmsLangId))?.description;
    console.assert(alphabetMasterAudioSrc, "Can't find node with planet id " + cmsPlanetId + " and language id " + cmsLangId + " as child of " + onboardingAudioUrlNode?.name);

    const handleAlphabetMasterClick = () => {
        if (alphabetMasterAudioRef.current) {
            if (alphabetMasterAudioRef.current.duration > 0 && !alphabetMasterAudioRef.current.paused) { //Audio is playing
                alphabetMasterAudioRef.current.pause();
                alphabetMasterAudioRef.current.style.opacity = "0";
                closeBtnRef.current!.style.opacity = "0";
            } else { //Not playing. Paused, ended or never played.
                alphabetMasterAudioRef.current.play();
                alphabetMasterAudioRef.current.style.opacity = "1";
                closeBtnRef.current!.style.opacity = "1";
            }
            if (alphabetMasterAudioRef.current.controls === false) {
                alphabetMasterAudioRef.current.controls = true;
            }
        }
    }
    const handleCloseAlphabetMasterClick = () => {
        if (alphabetMasterAudioRef.current!.controls === true) {
            closeBtnRef.current!.style.opacity = "0";
            alphabetMasterAudioRef.current!.pause();
            alphabetMasterAudioRef.current!.controls = false;
        }
    }
    // -----

    /**Toggle. Set to true to develop it. Set to false before deploy. Remove when no longer needed */
    const noteReadyForPublishing = false;
    const noteToTeacher =
        <div className={styles['teacher-note']} onClick={() => setShowToTeacherModal(true)}>
            <div>Til lærer</div>
        </div>

    /*     const dummyToTeacherNotes =
            <div className={styles['information-to-teacher']} >
                <div style={{ width: "100%", textAlign: 'end' }}><button onClick={() => setShowToTeacherModal(false)} style={{ fontSize: "1.25rem" }}><FaWindowClose /></button></div>
                <h2>Kjære lærer!</h2>
                <p><strong>Velkommen til Alfabetplaneten! i Creaza drømte vi lenge om å skape en verden der elevene i begynneropplæringen kunne være skapende og kreative, leke seg til læring og kose seg med lesing og skriving. Det tror vi vi har klart gjennom produktet du har foran deg.</strong></p>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <video controls controlsList='nodownload' style={{ maxWidth: "min(100%, 580px)", boxShadow: "var(--card-box-shadow)" }}>
                        <source src='https://alphabet-planet-ui.s3.eu-west-1.amazonaws.com/video-info/alphabet_planet_video_info_1.mp4' />
                    </video>
                </div>
                <h3>Forsiden</h3>
                <p>Du står nå på læremiddelets forside. Nederst er <em>Alfabetmesteren</em>, utover kartet ser du alle bokstavene i alfabetet og tre ikoner som representerer <em>Biblioteket</em>, <em>Lekeplassen</em> og <em>Scenen</em>. </p>
                <h3>Alfabetmesteren</h3>
                <p>Alfabetmesteren bestemmer på Alfabetplaneten. Hun er en klok person som vet det aller meste, og hun har en god relasjon til alle som bor på Alfabetplaneten. Når hun trenger det, får hun hjelp fra bokfuglene sine: Alfa, Beta, Charlie og Delta.</p>
                <h3>Bokstavene</h3>
                <p>Trykk på en bokstav og kom til en <em>bokstavside</em>. På hver bokstav møter elevene to karakterer som de kan bli kjent med, besøke, leke med og bruke i egen læringsprosess. Man kan også se og lytte til bokstavene.</p>
                <h3>Biblioteket</h3>
                <p>Trykk på den brune boka på forsiden for å komme til Biblioteket. I biblioteket finner dere både <em>biografier</em>, <em>fortellinger</em> og <em>oppgaver</em> klare til bruk.</p>
                <h3>Lekeplassen</h3>
                <p>Enhver planet med respekt for seg selv må ha en lekeplass. Lekeplassen kommer dere til dersom dere trykker på ballen. På lekeplassen finner dere <em>leker</em> og <em>aktiviteter</em> hvor elevene kan jobbe med ulike ferdigheter, både faglige og sosiale.</p>
                <h3>Scenen</h3>
                <p>Om dere trykker på mikrofonen på kartet, kommer dere til Alfabetplanetens scene. Her er det laget mange sanger, rim og regler. Noen hjelper dere med hverdagsrutinene i klasserommet, noen leker med språket og noen er rett og slett mest til fornøyelse og underholdning.</p>
                <p>På Alfabetplanetens <a href='https://web.creaza.com/no/alfabetplaneten-veiledning'>veiledningsside</a> finner du enda mer informasjon og flere pedagogiske tips til hvordan du kan ta Alfabetplaneten i bruk med dine elever.</p>
                <strong>Vi i Creaza ønsker dere mange gode stunder med Alfabetplaneten, og håper dere vil elske Alfabetplaneten like mye som vi gjør!</strong>
                <div style={{ width: "100%", textAlign: 'end', marginBlockStart: "1.25rem", }}><button onClick={() => setShowToTeacherModal(false)} style={{ textDecoration: "underline" }} >Lukk vindu</button></div>
            </div> */


    /*     function getLetterIndex(letterNode: CategoryTreeNode) {
            return letterCategories.findIndex(element => element.id === letterNode.id);
        } */

    /*     function getLetterIndex(letterNode: CategoryTreeNode) {
            const index = lettersInOrder.indexOf(letterNode);
            if (index) {
                return index;
            }
            else {
                return null;
            }
        } */

    return (
        <>
            <div aria-label={"Back"} style={{ top: "0.75rem", left: "0.75rem", position: "absolute", zIndex: "1" }}>
                <BackButtonLink linkTo='/' title="Gå tilbake" />
            </div>
            <MapIntroVideo openedManually={showIntroVideo} onClose={() => setShowIntroVideo(false)} />
            <PAGE_16_9 bgImg={alphabetMap} bgColor='#e2c7a6' scrollHorizontally={selectedMapItem === null ? true : false}>
                {alphabetMasterAudioSrc &&
                    <>
                        <div className={`${classNameLetter} ${styles.alphabet_master} ${styles["intro-video-button"]}`} style={{ ['--order' as any]: 1, left: "15px", bottom: "0" }}>
                            <button className={styles.animate_on_load} onClick={() => setShowIntroVideo(true)} >
                                <img style={{ width: "min(12vw, 16vh)", }} src={`https://alphabet-planet-ui.s3.eu-west-1.amazonaws.com/images-characters/onboarding_help_new.svg`} alt="Vis introduksjonsvideo" />
                            </button>
                        </div>
                        <div style={{ position: "absolute", left: "1%", bottom: "1%", zIndex: 1, display: "hidden", margin: 0 }}>
                            <button ref={closeBtnRef} onClick={handleCloseAlphabetMasterClick} style={{ float: "right", opacity: "0", fontSize: "1.125rem", fontWeight: "bold", padding: "2px", }} >x</button>
                            <audio ref={alphabetMasterAudioRef} controls={false} controlsList="noplaybackrate nodownload" >
                                <source src={alphabetMasterAudioSrc} type="audio/mpeg" />
                            </audio>
                        </div>
                    </>
                }
                <nav style={{ width: "100%" }}>
                    <ul>
                        {liElements}
                    </ul>
                </nav>
                {(selectedMapItem) &&
                    <Modal show={true} backdropClicked={() => setSelectedMapItem(null)} style={{ maxWidth: "max-content", minWidth: "min-content", width: "80vw" }}>
                        <LetterPage
                            selectedMapItem={selectedMapItem}
                            handlePreviousLetter={lettersInOrder[lettersInOrder.indexOf(selectedMapItem) - 1] === undefined ? null : () => setSelectedMapItem(lettersInOrder[lettersInOrder.indexOf(selectedMapItem) - 1] ? lettersInOrder[lettersInOrder.indexOf(selectedMapItem) - 1] : null)}
                            handleNextLetter={lettersInOrder[lettersInOrder.indexOf(selectedMapItem) + 1] === undefined ? null : () => setSelectedMapItem(lettersInOrder[lettersInOrder.indexOf(selectedMapItem) + 1] ? lettersInOrder[lettersInOrder.indexOf(selectedMapItem) + 1] : null)}
                            closeWindow={() => setSelectedMapItem(null)} /* assignExercise={createAssignment} userSession={userSession} */
                            storiesPageTitle={storiesPageTitle}
                            inhabitants={selectedLetterInhabitants}
                            categories={categories!}
                            planetIdNode={planetIdNode}
                            letterPagePageNode={letterPagePageNode}
                            swedishVisitExercise={tempSwedishSelectedLetterVisitExercise}
                            storyExercises={selectedStoriesExercises}
                        />
                    </Modal>
                }
                {noteReadyForPublishing && noteToTeacher}
            </PAGE_16_9>
        </>
    );
}

export default Map;