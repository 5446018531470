import React, { useState } from "react";
import Modal from "../Modal/Modal";
import styles from "./InfoButton.module.css";
import { FaLightbulb, FaRegLightbulb, FaWindowClose } from "react-icons/fa";
import { useTranslateGlobal } from "../../../utils/TranslationHooks";
import { Markdown } from "../Markdown/Markdown";

interface Props {
    heading?: string;
    text: string;
    topLeft?: boolean;
}

export const InfoButton = ({ heading, text, topLeft }: Props) => {
    const [showModal, setShowModal] = useState(false);
    const translateGlobal = useTranslateGlobal();

    return (
        <>
            <Modal
                show={showModal}
                backdropClicked={() => setShowModal(false)}
                heading={heading || translateGlobal("Info")}
                style={{ /* TODO rmg: Move new style modal to generic component */
                    backgroundColor: "rgb(250, 225, 255)",
                    border: "none",
                    boxShadow: "rgba(0, 0, 0, 0.25) 0px 0px 5px"
                }}
            >
                <div className={`${styles["button-wrapper"]}`}>
                    <button
                        className={styles["close-button"]}
                        onClick={() => {
                            setShowModal(false);
                        }}>
                        <FaWindowClose aria-label={translateGlobal("CloseWindow")} />
                    </button>
                    <div><Markdown>{text}</Markdown></div>
                </div>
            </Modal>
            <button className={`${styles.button} ${topLeft ? styles["top-left"] : ""}`} onClick={() => setShowModal(true)} type="button">
                <div className={styles['button-content']}>
                    <FaLightbulb className={styles['lightbulb--lit']} aria-hidden="true" />
                    <FaRegLightbulb />
                    {translateGlobal("Info")}
                </div>
            </button>
        </>
    )
}