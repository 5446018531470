import React, {useContext, useEffect, useState} from 'react';
import {Video} from "../UIComponents/Video/Video";
import Modal from "../UIComponents/Modal/Modal";
import styles from "./MapIntroVideo.module.css";
import {FaWindowClose} from "react-icons/fa";
import {basicSessionInfoContext, BasicSessionInfoContextType} from "../../utils/BasicSessionContext";
import {useMGMTTranslations, useTranslateGlobal, useTranslatePage} from "../../utils/TranslationHooks";

//Use this to test the modal locally
const testIntroVideo = false; //Should be false on commit

if (testIntroVideo) {
    localStorage.removeItem("abp_dontShowIntro");
}

interface Props {
    openedManually?: boolean;
    onClose: () => void;
}

enum LangType {
    nb = "no",
    nn = "nn",
    sv = "sv"
}

export const MapIntroVideo = ({openedManually, onClose}: Props) => {
    const {cmsLangId} = useContext(basicSessionInfoContext) as BasicSessionInfoContextType;
    const [show, setShow] = useState(!localStorage.abp_dontShowIntro || openedManually);
    const [selectedVideo, setSelectedVideo] = useState(0);

    const translateGlobal = useTranslateGlobal();
    const translatePage = useTranslatePage("LetterPage");
    const mgmtTranslate = useMGMTTranslations();

    const pathPrefix = "https://alphabet-planet-ui.s3.eu-west-1.amazonaws.com/video-info/";
    let lang: LangType = LangType.nb;

    switch (cmsLangId) {
        case "<1>":
            lang = LangType.nb;
            break;
        case "<2>":
            lang = LangType.nn;
            break;
        case "<8>":
            lang = LangType.sv;
            break;
        default:
    }

    useEffect(() => {
        //Only show the video once
        localStorage.abp_dontShowIntro = true;
    }, []);

    useEffect(() => {
        if (openedManually) {
            setShow(true)
        }
    }, [openedManually]);

    if (!show) {
        return null;
    }

    type VideoType = {
        fileName: string;
        thumbnail: string;
        label: string;
        altText: string;
    };

    const videos: VideoType[] = [
        {
            thumbnail: "film_01.png",
            fileName: mgmtTranslate("onboarding_video1fileName") || "",
            label: mgmtTranslate("onboarding_video1Label") || "",
            altText: mgmtTranslate("onboarding_video1AltText") || ""
        }, {
            thumbnail: "film_02.png",
            fileName: mgmtTranslate("onboarding_video2fileName") || "",
            label: mgmtTranslate("onboarding_video2Label") || "",
            altText: mgmtTranslate("onboarding_video2AltText") || ""
        }, {
            thumbnail: "film_03.png",
            fileName: mgmtTranslate("onboarding_video3fileName") || "",
            label: mgmtTranslate("onboarding_video3Label") || "",
            altText: mgmtTranslate("onboarding_video3AltText") || ""
        },{
            thumbnail: "film_04.png",
            fileName: mgmtTranslate("onboarding_video4fileName") || "",
            label: mgmtTranslate("onboarding_video4Label") || "",
            altText: mgmtTranslate("onboarding_video4AltText") || ""
        }
    ]

    return (
        <Modal
            heading={translatePage("Onboarding-heading")}
            backdropClicked={() => {
                setShow(false);
                onClose();
            }} show={show}>
            <div className={styles.container}>
                <div className={styles.mainContent}>

                    <div key={selectedVideo}>
                        <Video src={`${pathPrefix}${videos[selectedVideo].fileName}` || ""}/>
                    </div>

                    {lang !== LangType.sv && /* Only the main video is available in Swedish */
                        <div className={styles.videoThumbnails}>
                        {
                            videos.map((video, index) => (
                                <button
                                    onClick={() => setSelectedVideo(index)}
                                    key={index}
                                    className={`${styles.videoInfo} ${selectedVideo === index ? styles.selected : ""}`}
                                >
                                    <h2 className={styles.videoLabel}>{video.label}</h2>
                                    <img
                                        className={styles.videoImage}
                                        src={`${pathPrefix}${video.thumbnail}`}
                                        alt={video.altText}
                                    />
                                </button>
                            ))
                        }
                    </div>}
                    <div>

                    </div>
                </div>
                <button
                    className={styles.closeButton}
                    onClick={() => {
                        setShow(false);
                        onClose();
                    }}>
                    <FaWindowClose aria-label={translateGlobal("CloseWindow")}/>
                </button>
            </div>
        </Modal>
    )
}