import React from "react";
import styles from "./AssignmentByCode.module.css";
import {FaArrowAltCircleRight} from 'react-icons/fa';
import {APIEndpoints} from "../../utils/APIEndpoints";
import axios from "axios";
import {useMGMTTranslations} from "../../utils/TranslationHooks";

enum StatusType {
    Default = "Default",
    ValidationSuccess = "ValidationSuccess",
    ValidationFailed = "ValidationFailed",
    Assigned = "Assigned",
    AlreadyAssigned = "AlreadyAssigned"
}

export const AssignmentByCode = () => {
    const mgmtTranslate = useMGMTTranslations();
    const [code, setCode] = React.useState<string>("");
    const [status, setStatus] = React.useState<StatusType>(StatusType.Default);

    const checkCode = (c: string) => {
        const codeValue = c.toUpperCase();
        setCode(codeValue);
        setStatus(StatusType.Default);

        if (codeValue.length < 5) {
            return;
        }

        axios.get(`${APIEndpoints.AssignmentVerifyInvitation}type=assignment&id=${codeValue}&validateOnly=1`,
            {withCredentials: true})
            .then((response) => {
                if (response.data.success) {
                    setStatus(StatusType.ValidationSuccess);
                    sendCode(codeValue);
                } else if (response.data.message === "User has already a valid submit of the assignment") {
                    setStatus(StatusType.AlreadyAssigned);
                } else {
                    setStatus(StatusType.ValidationFailed);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const sendCode = (codeValue: string) => {
        axios.get(`${APIEndpoints.AssignmentVerifyInvitation}type=assignment&id=${codeValue}`,
            {withCredentials: true})
            .then((response) => {
                if (response.data.success) {
                    setStatus(StatusType.Assigned);
                } else if (response.data.message === "User has already a valid submit of the assignment") {
                    setStatus(StatusType.AlreadyAssigned);
                } else {
                    setStatus(StatusType.ValidationFailed);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }

    return (
        <div className={styles.AssigmentByCode}>
            <label className={styles.label}>
                {mgmtTranslate("assignment_byCode_label")}
                <input autoComplete="off" value={code} onChange={(e) => {
                    checkCode(e.currentTarget.value)
                }} maxLength={5} type={"text"} className={styles.input}></input>
            </label>

            {status === StatusType.ValidationFailed &&
                <div className={`${styles.validationFeedback} ${styles.failed}`}>
                    {mgmtTranslate("assignment_byCode_invalidCode")}
                </div>}
            {status === StatusType.Assigned &&
                <div className={`${styles.validationFeedback} ${styles.success}`}>
                    {mgmtTranslate("assignment_byCode_success")}
                </div>}
            {status === StatusType.AlreadyAssigned &&
                <div className={`${styles.validationFeedback} ${styles.success}`}>
                    {mgmtTranslate("assignment_byCode_alreadyAssigned")}
                </div>}


            <button
                aria-disabled={!(status === StatusType.Assigned || status === StatusType.AlreadyAssigned)}
                className={`
                    ${styles.continueButton} ${status === StatusType.Assigned ||
                status === StatusType.AlreadyAssigned ? "" : styles.disabled
                }`}
                onClick={() => {
                    if (status === StatusType.Assigned || status === StatusType.AlreadyAssigned) {
                        window.location.href = "https://www.creaza.com/portfolio#/myWork";
                    }
                }}
            >
                <FaArrowAltCircleRight/>
            </button>
        </div>
    )
}
export default AssignmentByCode;