import { CategoryTreeNode } from "./CategoryTreeNode";

export const baseURL = "https://www.creaza.com/ICSXapi/exercise/List?"

export enum ObjectType {
    /** This type represents regular exercises that are supposed to be opened and "solved" by the user. */
    crz_exercise = "crz_exercise",
    /** This type represents exercises that are consumable products. These "exercises" are ment to be watched, not solved. */
    crz_exercise_samfunn = "crz_exercise_samfunn",
    crz_exercise_english = "crz_exercise_english",
    crz_exercise_universe = "crz_exercise_universe" // might not need crz_exercise_universe ever...
}

/** 
 * Structure of the response from /ICSX/exercise/
 */
export interface Exercise {
    id: string;
    name: string;
    description?: string;
    contentId?: string;
    categories: string[];
    ageGroupLevels?: any[];
    thumbnailId?: string;
    hasContent?: boolean;
    isSamfunnJunior?: boolean;
    exerciseCategory?: string;
    languageId?: string;
    thumbnailURL?: string;
    creatorId: string;
    sortIndex?: string;
    exportId: string;
    objectType: ObjectType;
    type: string;
    hasPermission: boolean;
}

export interface ResponseData {
    exercises: Exercise[];
    count: number;
    maxPage: number;
    page: number;
}

export enum SortType {
    recommended = "recommended",
    popular = "popular",
    newest = "newest" // Passing "newest" can make the response empty
}

export function sortExercisesBySortIndex(exercises: Exercise[]) {
    // Quickfix: Be able to sort Norwegian and Swedish special letters alphabetically
    exercises.forEach(exercise => {
        exercise.sortIndex = exercise.sortIndex?.replaceAll("å", "€01").replaceAll("Å", "€02")
            .replaceAll("ä", "€03").replaceAll("Ä", "€04").replaceAll("ö", "€05").replaceAll("Ö", "€06");
    })

    exercises && exercises.sort((exercise1, exercise2) => {
        if (exercise1.sortIndex !== undefined && exercise2.sortIndex === undefined) {
            return -1
        }
        if (exercise1.sortIndex === undefined && exercise2.sortIndex !== undefined) {
            return 1;
        }
        if ((exercise1.sortIndex !== undefined && exercise2.sortIndex !== undefined) && exercise1.sortIndex < exercise2.sortIndex) {
            return -1;
        }
        if ((exercise1.sortIndex !== undefined && exercise2.sortIndex !== undefined) && exercise1.sortIndex > exercise2.sortIndex) {
            return 1;
        }
        return 0;
    });
}

/**
 * At the time fo writing this, this function has never been tested. Use at your own risk.
 * @param exercises to be sorted.
 * @param categories in the order you want the exercises to be sorted by.
 * @returns new array of exercises sorted by the order of the categories.
 */
export function sortExercisesbyCategory(exercises: Exercise[], categories: CategoryTreeNode[]) {

    let exercisesSortedByCategory: Exercise[] = [];

    const categoryIds = categories.map(category => category.id);
    categoryIds.forEach(id => {
        const exercisesInCategory = exercises.filter(exercise => exercise.categories.includes(id));
        /* Sort this level by sortIndex */
        sortExercisesBySortIndex(exercisesInCategory);
        exercisesSortedByCategory.push(...exercisesInCategory)
    })

    /* Add rest of the exercises that is not part of any of the categories.
     This will be unnecessary if exercises always belong to one of the categories.
     This part can be removed if you would rather return only exercises that belong to any of the given categories. */
    exercises.forEach(exercise => {
        exercisesSortedByCategory.includes(exercise) === false && exercisesSortedByCategory.push(exercise);
    })

    return exercisesSortedByCategory;
}