import React from 'react';
import styles from './VideoPage.module.css';
import PageGeneralMain from "../PageGeneralMain";
import {BackButtonLink} from "../UIComponents/BackButtonLink/BackButtonLink";
import {Video} from "../UIComponents/Video/Video";
import {useParams} from "react-router-dom";

interface VideoPageProps {

}

export const VideoPage = ({}: VideoPageProps) => {
    const { exportId, title } = useParams();

    if(!exportId || !title){
        return null;
    }

    const backgroundImage = "https://alphabet-planet-ui.s3.eu-west-1.amazonaws.com/topic-tree/images/rim_og_rytme_bg.svg";
    const src = "https://www.creaza.com/file/ci/" + exportId.toString() + "/aaa";

    return (
        <div>
            <img alt="" className={styles['background-image']} src={backgroundImage} />
            <PageGeneralMain>
                <BackButtonLink linkTo={"./../.."} />
                <div className={styles['video-wrapper']}>
                    <h1 className={styles['page-title']}>{decodeURI(title)}</h1>
                    <Video src={src} />
                </div>
            </PageGeneralMain>
        </div>
    );
};