import { useContext } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { CategoryTreeNode } from '../../utils/CategoryTreeNode';
import { RoleSelectorPage } from '../RoleSelectorPage/RoleSelectorPage';
import { TeacherFrontPage } from '../TeacherFrontPage/TeacherFrontPage';
import { BackButtonLink } from '../UIComponents/BackButtonLink/BackButtonLink';

import styles from './TempStructureWrapper.module.css';
import { BasicSessionInfoContextType, basicSessionInfoContext } from '../../utils/BasicSessionContext';
import { PlanetContextType, planetContext } from '../../utils/PlanetContext';

interface TempStructureWrapperProps {
    pagesNode: CategoryTreeNode;
    componentsNode: CategoryTreeNode;
    teacherNavMenuNode: CategoryTreeNode;
}

export const TempStructureWrapper = ({ pagesNode, componentsNode, teacherNavMenuNode }: TempStructureWrapperProps) => {
    const sessionInfoContext = useContext(basicSessionInfoContext) as BasicSessionInfoContextType;
    const { cmsPlanetId } = useContext(planetContext) as PlanetContextType;

    const teacherFrontPageNode = pagesNode.children.find(n => n.name === "TeacherFrontpage-page");

    const {urlParam } = useParams();

    /* Routing pages */
    const roleSelectorPage = <RoleSelectorPage />
    const teacherPage = teacherFrontPageNode &&
        <TeacherFrontPage
            teacherFrontPageNode={teacherFrontPageNode}
            teacherNavMenuNode={teacherNavMenuNode}
            pagesNode={pagesNode}
        ></TeacherFrontPage>

    /* url sources to ui elements */
    const backgroundImgSrc = "https://alphabet-planet-ui.s3.eu-west-1.amazonaws.com/images-ui/alphabet_planet_ui_background_stars.svg"; //TODO: get from CMS
    const logo = cmsPlanetId && componentsNode.children.find(n => n.name === "AlphabetPlanet-logo")?.children.find(n => n.name.includes(sessionInfoContext.cmsLangId) && n.name.includes(cmsPlanetId))?.description;

    return (
        <div className={styles['temp-structure-wrapper']}>
            <img className={styles['background-image']} alt="" src={backgroundImgSrc}></img>
            {window.location.href.endsWith("teacher") &&
                <div style={{ position: "absolute", top: "1.5rem", left: "1.5rem" }}>
                    <BackButtonLink linkTo='/' title="Gå tilbake" />
                </div>
            }
            <img className={styles.logo} alt="" src={logo}></img>

            {urlParam === "teacher" ? teacherPage : roleSelectorPage}
        </div>
    );
};