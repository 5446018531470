import { useContext, useEffect, useRef, useState } from 'react';
import { CategoryTreeNode } from "../../utils/CategoryTreeNode";
import { getCharacterImage } from '../../utils/nodeUtils';
import { AudioOnClickButton } from '../UIUtils/AudioOnClickButton/AudioOnClickButton';
import styles from './CharacterWithAudio.module.css';
import { LanguageISO639_1, useUserLanguage } from '../../utils/languages';
import { BiographyLanguageContextType, biographyLanguageContext } from '../../utils/BiographyLanguageContext';
import { getBiographyAudioSrc } from '../../utils/BiographyAudio';

interface ChararacterWithAudioProps {
    characterNode: CategoryTreeNode;
    planetIdNode: CategoryTreeNode;
}

const CharacterWithAudio = ({ characterNode, planetIdNode }: ChararacterWithAudioProps) => {

    /*     const [currentAudio, setCurrentAudio] = useState<HTMLAudioElement>();
    
        const audio = useRef<null | HTMLAudioElement>(null);
    
        audio.current?.load();
    
        function handleCharacterClick(target: null | HTMLAudioElement) {
    
            if (target !== null && target.paused) {
                setCurrentAudio(target);
                target.play();
            }
            else {
                target?.pause();
            }
        }
     */
    /* Temporary way of getting audio */
    const baseAudioUrl = "https://s3-eu-west-1.amazonaws.com/creaza-cartoonist-svg/alphabetplanet-ui/character-audio/";
    const characterAudioUrlName = characterNode.description.replace(".", "").replace("Å", "Aa"); // (one of the characters has a "." in their name, which is not part of the URL)
    const tempAudioSrcOld = baseAudioUrl + characterAudioUrlName + (["Aage", "Uffe"].includes(characterAudioUrlName) ? ".wav" : ".mp3"); // Hopefully they all become mp3 soon
    /* ---- */

    // Use the default audio soruce which is in the character node, or if other language is selected, use the audio source for that language, which we access directly from S3 for now.
    const userLanguage = useUserLanguage();
    const { biographyLanguage } = useContext(biographyLanguageContext) as BiographyLanguageContextType;
    const biographyAudioSrc = userLanguage?.defaultSpokenLanguage === biographyLanguage ?
        characterNode.children.find(n => n.name === "BiographyAudio")?.children.find(n => n.name.includes(planetIdNode.description))?.description
        :
        getBiographyAudioSrc(characterNode.id, biographyLanguage as LanguageISO639_1);

    const inhabitantNameNode = characterNode.children.find(n => n.name === "Name")?.children.find(n => n.name.includes(planetIdNode.description));
    const profilePicNode = characterNode.children.find(n => n.name === "ProfilePic");
    const inhabitantImageNode = profilePicNode?.children.find(n => n.name.includes(planetIdNode.description))
        ?? profilePicNode?.children.find(n => n.name.includes("<default>"));


    return (biographyAudioSrc ?
        /*                <div className={styles.character_card}>
                           <h3 className={styles.title} >{characterNode.description}</h3>
                           <img onClick={() => { handleCharacterClick(audio.current) }} className={styles.svg} alt="" src={getCharacterImage(characterNode)} style={{ width: "140px", maxHeight: "200px" }} />
                           <audio ref={audio} className={styles.audio_player} controls controlsList="noplaybackrate nodownload" style={{ width: "256px", }}  >
                               <source src={tempAudioSrc} type="audio/mpeg" />
                               Your browser does not support the audio element.
                           </audio>
                       </div> */

        <div >
            <AudioOnClickButton audioSrc={biographyAudioSrc} playerCSS={{ right: "110%", bottom: "4.6rem", }} >
                <img alt={'Hør biografien til ' + inhabitantNameNode?.description} src={inhabitantImageNode?.description} className={styles.svg} ></img>
            </AudioOnClickButton>
        </div>
        :
        null
    );
};

export default CharacterWithAudio;