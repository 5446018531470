import React, { useContext } from 'react';
import { Link, Route, Routes, useParams } from 'react-router-dom';
import { ObjectType } from '../../utils/exerciseService';
import { parseToURL } from '../../utils/parseToURL';
import PageGeneralMain from '../PageGeneralMain';
import { sangerInfoNO, sangerInfoSV } from '../../utils/TopicStructure';

import PAGE_16_9 from '../Page_16_9';
import { Breadcrumbs } from '../UIComponents/Breadcrumbs/Breadcrumbs';
import { Breadcrumb } from "../UIComponents/Breadcrumbs/Breadcrumb";
import { VideoCard } from '../UIComponents/VideoCard/VideoCard';

import styles from './Stage.module.css'
import { StageVideoPage } from './StageVideoPage/StageVideoPage';

import { CardListing } from '../UIComponents/CardListing/CardListing';
import { WrapMainContentWithOnboarding } from '../UIUtils/WrapMainContentWithOnboarding/WrapMainContentWithOnboarding';
import { CategoryTreeNode } from '../../utils/CategoryTreeNode';
import { BackButtonLink } from '../UIComponents/BackButtonLink/BackButtonLink';
import { BasicSessionInfoContextType, basicSessionInfoContext } from '../../utils/BasicSessionContext';
import { InfoButton } from '../UIComponents/InfoButton/InfoButton';

const baseUrlAlphabetUi = "https://creaza-cartoonist-svg.s3.eu-west-1.amazonaws.com/alphabetplanet-ui/";
const backgroundImg = baseUrlAlphabetUi + 'alphabet_planet_ui_page_stage.svg';

interface StageProps {
    breadcrumbs: Breadcrumb[];
    videosNode: CategoryTreeNode;
    pageNode: CategoryTreeNode;
}

/**
 * Page that displays songs/videos
 */
const Stage = ({ pageNode, breadcrumbs, videosNode }: StageProps) => {

    const {cmsLangId} = useContext(basicSessionInfoContext) as BasicSessionInfoContextType;

    const title = pageNode.children.find(n => n.name === "Title")?.children.find(n => n.name.startsWith(cmsLangId))?.description;
    const videos = videosNode.children.find(n => n.name.includes(cmsLangId))?.children;
    let infoDescription = cmsLangId === "<8>" ? sangerInfoSV : sangerInfoNO;

/*     const thisPageURL = parseToURL("/" + url + "/" + title);

    const stageBreadcrumb: Breadcrumb = {
        title: title,
        to: thisPageURL,
        thumbnail: "https://alphabet-planet-ui.s3.eu-west-1.amazonaws.com/images-ui/alphabet_planet_ui_button_mic.svg"
    } */

    const routesToVideoPages = videos && videos.map(video => {
        const title = video.children.find(n => n.name === "Title")?.description;
        const src = video.children.find(n => n.name === "VideoUrl")?.description;
        const karaoke = video.children.find(n => n.name === "KaraokeUrl")?.description;
        const lyrics = video.children.find(n => n.name === "LyricsUrl")?.description;

        return (title && src) && 
        <>
            <Route path={"/" + parseToURL(title)} element={<StageVideoPage title={title} src={src} karaoke={karaoke} lyrics={lyrics} /* breadcrumbs={[...breadcrumbs, stageBreadcrumb]} */ />} key={video.id} />
        </>
    });

    const stagePage = (title && videos) &&
        <div /* className={styles['background-image']}  *//* style={{backgroundImage: 'url(https://alphabet-planet-ui.s3.eu-west-1.amazonaws.com/images-ui/alphabet_planet_ui_page_stage.svg)', height: '100vh'}} */>
            <img alt="" src='https://alphabet-planet-ui.s3.eu-west-1.amazonaws.com/images-ui/alphabet_planet_ui_page_stage.svg' className={styles['background-image']} />
            {/* <Breadcrumbs breadcrumbs={breadcrumbs} currentPageTitle={title} pushContentDown /> */}
            <PageGeneralMain >
                {<BackButtonLink title={""} />}
                    <h1 className={styles['page-title']}>{title}</h1>
                    <div className={styles['info-btn']}>
                        <InfoButton text={infoDescription} />
                    </div>
                    <ul style={{ paddingInlineStart: 0, /* overflowX: 'hidden', */ }}> {/* "OverflowX: hidden" is only there to prevent scrollbar from appearing on certain screen sizes after onboarding was added */}
                        <CardListing>
                            {videos.map(video => {
                                const title = video.children.find(n => n.name === "Title")?.description;
                                const thumbnail = video.children.find(n => n.name === "ThumbnailUrl")?.description;
                                return (
                                    (title && thumbnail) &&
                                    <li key={video.id}>
                                        <Link to={"" + parseToURL(title)}>
                                            <VideoCard title={title} thumbnail={thumbnail} />
                                        </Link>
                                    </li>
                                )
                            })}
                        </CardListing>
                    </ul>       
            </PageGeneralMain>
        </div>;

    return (
            <div>
                <Routes >
                    <Route path="/" element={stagePage}  ></Route>
                    {routesToVideoPages}
                </Routes>
            </div>


    );
};

export default Stage;