import { createContext, useMemo, useState } from "react";
import { SessionInfoNode } from "./SessionService/SessionInfoNode";

interface SessionInfoProviderProps {
    children: React.ReactNode;
}

export type SessionInfoContextType = {
    sessionInfo: SessionInfoNode | undefined;
    setSessionInfo: (sessionInfo: SessionInfoNode) => void;
  };

export const sessionContext = createContext<SessionInfoContextType | null>(null);

export const SessionInfoProvider = ({ children }: SessionInfoProviderProps) => {
    const [sessionInfoNode, setSessionInfo] = useState<SessionInfoNode | undefined>(undefined);

    const sessionInfo = useMemo(() => sessionInfoNode, [sessionInfoNode]);

    return (
        <sessionContext.Provider value={{ sessionInfo, setSessionInfo }}>
            {children}
        </sessionContext.Provider>
    );
}