import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import styles from './Page_16_9.module.css';

interface Page_16_9Props {
    children?: React.ReactNode;
    bgImg?: string;
    bgColor?: string;
    /**Set this to true if you want to transform vertical wheel scroll to horizontal scroll
     * Default is false.
     */
    scrollHorizontally?: boolean;
}

/*
* This component is used for pages that does not have a normal responsiveness,
* but instead have a fixed 16:9 ratio. It has its own set of CSS rules.
* Just wrap the content with this component and pass the background image as the bgImg prop.
*/
function Page_16_9({ children, bgImg, bgColor, scrollHorizontally }: Page_16_9Props) {

    const horizontalScrollElement = useRef<null | HTMLDivElement>(null);

    function transformVerticalScrollToHorizontalScroll(event: React.WheelEvent<HTMLDivElement>) {
        /* Return if user is not scrolling vertically or if user is holding the shift key while scrolling. */
        if (!event.deltaY || event.shiftKey) {
            return;
        }

        if (horizontalScrollElement.current) horizontalScrollElement.current.scrollLeft += event.deltaY + event.deltaX;
    }

    window.addEventListener("orientationchange", scrollToMiddle);

    /* Scroll by dragging with mouse */
    const [isDragging, setIsDragging] = useState(false);
    const [lastX, setLastX] = useState(0);

    /*     useEffect(() => {
            if (horizontalScrollElement.current) {
                horizontalScrollElement.current.style.cursor = isDragging ? "grabbing" : "grab";
            }
        }, [isDragging, lastX])
     */


/*         useLayoutEffect(() => {
            horizontalScrollElement.current && horizontalScrollElement.current.scrollTo(horizontalScrollElement.current.scrollHeight / 2, 0);
        }, []) */
        useLayoutEffect(() => {
            const scrollableElement = document.getElementById("scrollable-element");
            scrollableElement?.scrollTo(scrollableElement.scrollHeight / 2, 0);
        }, [])

        function scrollToMiddle() {
            horizontalScrollElement.current?.scrollTo(horizontalScrollElement.current.scrollHeight / 2, 0);
        }

    return (
        <div className={styles.page_16_9__wrapper__container} style={{ backgroundColor: bgColor }} >
            <div className={styles.page_16_9__wrapper}
                ref={horizontalScrollElement}
                /* Scroll horizontally when scrolling vertically */
                onWheel={(e) => scrollHorizontally ? transformVerticalScrollToHorizontalScroll(e) : null}
                /* Scroll by dragging with mouse */
                onMouseDown={(e) => {
                    setIsDragging(() => true);
                    setLastX(e.clientX);
                }}
                onMouseUp={e => {
                    setIsDragging(() => false);
                }}
                onMouseLeave={e => {
                    setIsDragging(() => false);
                }}
                onMouseMove={e => {
                    if (isDragging) {
                        const currentX = e.clientX;
                        const scrollDistance = currentX - lastX;
                        if (horizontalScrollElement.current) horizontalScrollElement.current.scrollLeft -= scrollDistance;
                        setLastX(currentX);
                    }
                }}
            >
                {/* Icon that communicates the possibility to deag to scroll horizontally */}
                <div className={styles['horizontal-scroll-icon']} id="scrollable-element" aria-hidden={true}>
                    <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="50" height="16.92772" viewBox="0 0 50 16.92772">
                        <path d="m19.24571,3.99902h-8.94803V1.80633c0-1.5554-1.6333-2.38079-2.66674-1.34765L.26434,7.82321c-.35245.35236-.35245.92895,0,1.2813l7.36661,7.36452c1.03344,1.03314,2.66674.20776,2.66674-1.34764v-2.1927h8.94803c.82137,0,1.48723-.66585,1.48723-1.48723v-5.95522c0-.82137-.66585-1.48723-1.48723-1.48723Z" />
                        <path d="m30.75429,12.9287h8.94803v2.19269c0,1.5554,1.6333,2.38079,2.66674,1.34765l7.36661-7.36452c.35245-.35236.35245-.92895,0-1.2813L42.36905.45869c-1.03344-1.03314-2.66674-.20776-2.66674,1.34764v2.1927h-8.94803c-.82137,0-1.48723.66585-1.48723,1.48723v5.95522c0,.82137.66585,1.48723,1.48723,1.48723Z" />
                        <rect x="22.07084" y="3.97306" width="2.28231" height="8.98159" rx="1.14115" ry="1.14115" />
                        <rect x="25.69104" y="3.97306" width="2.28231" height="8.98159" rx="1.14115" ry="1.14115" />
                    </svg>
                </div>
                <img src={bgImg} className={styles.page_16_9__background_img} alt="" onLoad={(e) => {scrollToMiddle(); e.currentTarget.style.opacity = "1"}} />
                <div className={styles.children}>
                    <img src={bgImg} className={styles['background-img-ghost']} alt="" />
                    {children}
                </div>
            </div>
        </div>
    );
}

export default Page_16_9;