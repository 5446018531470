import React from 'react';

import styles from "./ButtonNew.module.css"

const ButtonNew = (props : any) => {
    return (
        <button className={styles.exercise_btn} hidden={props.hidden} onClick={props.onClick}>
            {props.children}
        </button>
    )
}

export default ButtonNew;