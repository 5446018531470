export interface Theme {
    color: string;
    backgroundColor: string;
    lightColor: string;
    darkColor: string;
    image: string;
}

export type ThemeName = "alfa" | "bravo" | "charlie" | "delta" | "socialCompetence" | "activities" | "print";

type ThemeType = {
    [key in ThemeName]: Theme;
};

export const Themes: ThemeType = {
    alfa: {
        color: "#FF9FFA",
        backgroundColor:  "#FFD2F0",
        lightColor: "#D163A8",
        darkColor: "#A83870",
        image: "https://alphabet-planet-ui.s3.eu-west-1.amazonaws.com/topic-tree/images/alfa.svg"
    },
    bravo: {
        color: "#EABE52",
        backgroundColor: "#FFEABB",
        lightColor: "#EABE52",
        darkColor: "#CC7E47",
        image: "https://alphabet-planet-ui.s3.eu-west-1.amazonaws.com/topic-tree/images/bravo.svg"
    },
    charlie: {
        color: "#61BC67",
        backgroundColor: "#CFFFC2",
        lightColor: "#558E47",
        darkColor: "#225E26",
        image: "https://alphabet-planet-ui.s3.eu-west-1.amazonaws.com/topic-tree/images/charlie.svg"
    },
    delta: {
        color: "#64BCF9",
        backgroundColor: "#C5E8FF",
        lightColor: "#2776B7",
        darkColor: "#2753B7",
        image: "https://alphabet-planet-ui.s3.eu-west-1.amazonaws.com/topic-tree/images/delta.svg"
    },
    socialCompetence: {
        color: "#EABE52",
        backgroundColor: "#FFEABB",
        lightColor: "#EABE52",
        darkColor: "#CC7E47",
        image: "https://alphabet-planet-ui.s3.eu-west-1.amazonaws.com/topic-tree/images/sosial_kompetanse.svg"
    },
    activities: {
        color: "#EABE52",
        backgroundColor: "#FFEABB",
        lightColor: "#EABE52",
        darkColor: "#CC7E47",
        image: "https://alphabet-planet-ui.s3.eu-west-1.amazonaws.com/topic-tree/images/aktiviteter.svg"
    },
    print: {
        color: "#EABE52",
        backgroundColor: "#BFABFF",
        lightColor: "#FF6BE2",
        darkColor: "#CC7E47",
        image: "https://alphabet-planet-ui.s3.eu-west-1.amazonaws.com/topic-tree/images/print.svg"
    }
}
