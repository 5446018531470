import { createContext, useMemo, useState } from "react";
import { BasicSessionInfoNode } from "./SessionService/SessionInfoNode";
import {LangById} from "./LangTypes";


interface BasicSessionInfoProviderProps {
    children: React.ReactNode;
}

export interface BasicSessionInfoContextType {
    basicSessionInfo: BasicSessionInfoNode | undefined;
    setBasicSessionInfo: (basicSessionInfo: BasicSessionInfoNode) => void;
    cmsLangId: string;
    languageId: BasicSessionInfoNode["languageId"] | undefined;
}

export const basicSessionInfoContext = createContext<BasicSessionInfoContextType | null>(null);

export const BasicSessionInfoProvider = ({ children }: BasicSessionInfoProviderProps) => {
    const [basicSessionInfoNode, setBasicSessionInfo] = useState<BasicSessionInfoNode | undefined>(undefined);
    const basicSessionInfo = useMemo(() => basicSessionInfoNode, [basicSessionInfoNode]);
    
    const userLangId = basicSessionInfo?.languageId;
    const cmsLangId = "<" + basicSessionInfo?.languageId.toString() + ">";

    //Set html lang attribute
    const langId = basicSessionInfo?.languageId.toString() || "1";
    const lang = LangById[langId];
    document.documentElement.setAttribute("lang", lang);

    //Set document title
    let title = "";
    switch(lang){
        case "sv":
            title = "Alfabetsplaneten";
            break;
        case "en":
            title = "The Alphabet Planet";
            break;
        default:
            title = "Alfabetplaneten";
            break;
    }
    document.title = title;

    return (
        <basicSessionInfoContext.Provider value={{basicSessionInfo, setBasicSessionInfo, cmsLangId, languageId: userLangId }} >
            {children}
        </basicSessionInfoContext.Provider>
    )

}