import React, { useContext, useEffect, useState } from 'react';
import styles from "./LibraryPage.module.css"
import { planetContext, PlanetContextType } from "../../utils/PlanetContext";
import { LibrarianView } from "./LibrarianView";
import { BackButtonLink } from "../UIComponents/BackButtonLink/BackButtonLink";
import { StoryPreview } from "../UIComponents/StoryPreview/StoryPreview";
import { CategoryTreeNode } from "../../utils/CategoryTreeNode";
import { getExerciseThumbnail } from "../../utils/getExerciseThumbnail";
import ExercisesQueryParameters from "../../utils/ExercisesQueryParameters";
import { ObjectType } from "../../utils/exerciseService";
import useFetchExercises from "../../utils/useFetchExercises";
import { basicSessionInfoContext, BasicSessionInfoContextType } from "../../utils/BasicSessionContext";
import { AudioPlayer } from "../UIUtils/AudioPlayer/AudioPlayer";
import { LargeHeader } from "../UIComponents/Header/LargeHeader";
import { categoryTreeContext, CategoryTreeContextType } from "../../utils/CategoryTreeContext";
import hiddenStyle from "../../utils/VisuallyHidden.module.css";
import { BiographyLanguageSelect } from '../UIComponents/BiographyLanguageSelect/BiographyLanguageSelect';
import { useUserLanguage } from '../../utils/languages';
import { BiographyLanguageContextType, biographyLanguageContext } from '../../utils/BiographyLanguageContext';
import { getBiographyAudioSrc } from '../../utils/BiographyAudio';

interface Props {
    libraryStoriesNode: CategoryTreeNode;
    libraryPageNode: CategoryTreeNode;
}

export interface Librarian {
    id: string;
    name: string;
    profilePic: string;
    biographyAudio: string;
}

export const LibraryPage = ({ libraryStoriesNode, libraryPageNode }: Props) => {
    const { libraryInhabitants } = useContext(categoryTreeContext) as CategoryTreeContextType;
    const { basicSessionInfo } = useContext(basicSessionInfoContext) as BasicSessionInfoContextType;
    const { cmsPlanetId } = useContext(planetContext) as PlanetContextType;
    const { cmsLangId } = useContext(basicSessionInfoContext) as BasicSessionInfoContextType;
    const [currentPlayingSrc, setCurrentPlayingSrc] = useState<string | undefined>();
    const [audioIsPlaying, setAudioIsPlaying] = useState(false);

    const userLanguage = useUserLanguage();
    const { biographyLanguage } = useContext(biographyLanguageContext) as BiographyLanguageContextType;

    const pageTitle = libraryPageNode?.children
        .find(n => n.name === "Title")?.children
        .find(n => n.name.includes(cmsLangId))?.description || "Biblioteket";

    const accessibilityTitle = libraryPageNode?.children
        .find(n => n.name === "AccessibilityTitle")?.children
        .find(n => n.name.includes(cmsLangId))?.description || "";

    const getLibrarians = () => {
        return libraryInhabitants?.children.map(inhabitant => {
            const id = inhabitant.children.find(
                node => node.name === "Name")?.children.find(
                    node => cmsPlanetId && node.name.includes(cmsPlanetId))?.description || "";

            const name = inhabitant.children.find(
                node => node.name === "Name")?.children.find(
                    node => cmsPlanetId && node.name.includes(cmsPlanetId))?.description || "";

            const profilePic = inhabitant.children.find(
                node => node.name === "ProfilePic")?.children.find(
                    node => node.name === "<default>")?.description || ""; // TODO: try finding cmsPlanetId first

            // Get audio from category node if the selected language is not the default language.
            // (The default audio source is defined in the inhabitant's category node)
            const biographyAudio = biographyLanguage && (biographyLanguage !== userLanguage?.defaultSpokenLanguage) ?
                getBiographyAudioSrc(inhabitant.id, biographyLanguage)
                :
                inhabitant.children.find(
                    node => node.name === "BiographyAudio")?.children.find(
                        node => node.name === cmsPlanetId)?.description || "";

            return {
                id,
                name,
                profilePic,
                biographyAudio
            } as Librarian;
        }) || []
    }

    // Update audio src when a new language is selected
    useEffect(() => {
        setCurrentPlayingSrc(undefined);
        setAudioIsPlaying(false);
    }, [biographyLanguage]);

    const librarians = getLibrarians();

    const alfa = librarians.find(l => l.id === "Alfa");
    const bravo = librarians.find(l => l.id === "Bravo");
    const charlie = librarians.find(l => l.id === "Charlie");
    const delta = librarians.find(l => l.id === "Delta");
    const alphabetMaster = librarians.find(l => l.id === "Alfabetmesteren" || l.id === "Alfabetsmästarinnan");
    const librarianOrderedList = [alfa, bravo, alphabetMaster, charlie, delta]

    const storyLanguageId = basicSessionInfo?.languageId;

    // Fetch exercises so we can get their thumbnail for StoryPreview cards
    const params: ExercisesQueryParameters = {
        objectType: ObjectType.crz_exercise,
        hasContent: true, // Exercise is the stories main exercise
        categoryId: libraryStoriesNode.id,
        languageId: storyLanguageId
    }
    const { data } = useFetchExercises(params);

    return (
        <div className={styles["library-page"]}>
            <div className={styles['library-background-color']}></div>
            <BackButtonLink linkTo={"./.."} title={""} />
            <h1 className={hiddenStyle.visuallyhidden}>{accessibilityTitle}</h1>
            <div className={styles["library-top"]}>
                <img
                    className={styles["library-image"]}
                    src="https://alphabet-planet-ui.s3.eu-west-1.amazonaws.com/library-page/library-background.svg"
                    alt=""
                />
                <div style={{ backgroundColor: "var(--white)", width: "fit-content", padding: "1rem", borderRadius: "18px", position: "absolute", top: "2rem", right: "7%", }}>
                    <BiographyLanguageSelect />
                </div>
                <div className={styles["librarians-container"]}>
                    {librarianOrderedList.map(librarian => {
                        if (librarian) {
                            return (
                                <LibrarianView
                                    key={librarian.id}
                                    librarian={librarian}
                                    flipHorizontally={
                                        librarian.id !== "Charlie" &&
                                        librarian.id !== "Delta" &&
                                        librarian.id !== "Alfabetmesteren"
                                    }
                                    isPlaying={
                                        librarian.biographyAudio ===
                                        currentPlayingSrc && audioIsPlaying
                                    }
                                    onAudioStart={() =>
                                        setCurrentPlayingSrc(librarian.biographyAudio)
                                    }
                                    onAudioStop={() =>
                                        setCurrentPlayingSrc(undefined)
                                    }
                                />
                            );
                        }
                    })}
                </div>
                <AudioPlayer
                    src={currentPlayingSrc}
                    onStop={() => {
                        setAudioIsPlaying(false);
                    }}
                    onStart={(src) => {
                        setCurrentPlayingSrc(src);
                        setAudioIsPlaying(true);
                    }} />
            </div>

            <LargeHeader headingLevel={1}>{pageTitle}</LargeHeader>

            <div className={styles["story-list-wrapper"]}>
                <div className={styles["story-list"]}>
                    {libraryStoriesNode.children.map((story) => {
                        /* Using the mainExercise's thumbnail */
                        const correspondingExercise = data?.exercises.find(ex => ex.categories.includes(story.id));
                        if (!correspondingExercise) {
                            return null; //Don't render stories without exercises
                        }

                        const imgSrc = correspondingExercise && getExerciseThumbnail(correspondingExercise);
                        const storyHeadline = correspondingExercise?.name;
                        return (
                            <div key={story.id}>
                                <StoryPreview
                                    /* @ts-ignore */
                                    story={story as CategoryTreeNode}
                                    imgSrc={imgSrc}
                                    alt=''
                                    headline={storyHeadline}
                                    href={story.id.toString()}
                                />
                            </div>
                        )
                    }
                    )}
                </div>
            </div>
        </div>
    )
}