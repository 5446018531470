import React, { useEffect, useContext, useState, useMemo } from 'react';
import { CategoryTreeNode } from '../../utils/CategoryTreeNode';
import { useFetchCategoryTree } from '../../utils/useFetchCategoryTree';
import { Breadcrumb } from '../UIComponents/Breadcrumbs/Breadcrumb';
import { parseToURL } from '../../utils/parseToURL';
import { BasicSessionInfoNode, SessionInfoNode } from '../../utils/SessionService/SessionInfoNode';
import { getBasicSessionInfo, getSessionInfo } from '../../utils/SessionService/SessionService';
import { Route, Routes, Link, Navigate } from 'react-router-dom';
import { TempStructureWrapper } from '../TempStructureWrapper/TempStructureWrapper';
import Stage from '../Stage/Stage';
import { Tasks } from '../Library/Tasks/Tasks';
import Playground from '../Playground/Playground';
import Map from '../../pages/map/Map';
import { SessionInfoContextType, sessionContext } from '../../utils/SessionContext';
import { BasicSessionInfoContextType, basicSessionInfoContext } from '../../utils/BasicSessionContext';
import { planetContext } from '../../utils/PlanetContext';
import { categoryTreeContext } from '../../utils/CategoryTreeContext';
import { LibraryPage } from "../LibraryPage/LibraryPage";
import { Topic } from '../../utils/Topic';
import { StoriesListing } from '../Library/Stories/StoriesListing';
import Story from '../Library/Stories/Story/Story';
import { TopicNode } from '../UIComponents/TopicNode/TopicNode';
import { getTopicsPrimary } from "../../utils/TopicStructure";
import { RhymesPage } from "../RhymesPage/RhymesPage";
import { VideoPage } from "../VideoPage/VideoPage";
import { redirectToLogin } from '../../utils/redirectToLogin';
import { URLs } from '../../utils/URLs';
import {PrintPage} from "../PrintPage/PrintPage";

export const AlphabetPlanet = () => {
    const { data, error, isPending } = useFetchCategoryTree();
    const categories = data?.categoriesAlphabetPlanet;
    const { basicSessionInfo, setBasicSessionInfo, cmsLangId } = useContext(basicSessionInfoContext) as BasicSessionInfoContextType;
    const planetsNode = categories?.find(n => n.name === "Planets-node");
    const planetNode = cmsLangId ? planetsNode?.children.find(n => n.name.includes(cmsLangId)) : undefined;
    const planetIdNode = planetNode?.children.find(n => n.name === "PlanetId");
    const lettersInOrderStrings = planetNode?.children.find(n => n.name === "LettersInOrder")?.description.split(",");
    const lettersNode = categories?.find(n => n.name === "Letters-node");

    const letterNodesInOrder = lettersInOrderStrings?.map(letterString => {
        return (
            lettersNode?.children.find(letter => letter.name === letterString)
        );
    });

    /* ---------- Topic Tree ----------- */
    const languageId = basicSessionInfo?.languageId;
    const cmsPlanetId = planetIdNode?.description;
    const planetId = cmsPlanetId?.replaceAll("<", "").replaceAll(">", "");

    /* 
    The plan was once to store the topic tree exernally in S3,
    hence the URL and creation of file name.
    Anyways, we found it to be easier to manage when we just kept it in the project,
    especially while the topic tree concept was still in development.
    */
    const baseUrl = "https://alphabet-planet-ui.s3.eu-west-1.amazonaws.com/topic-tree/latest/";
    const langIdString = languageId?.toString()
    const fileName = (planetId && langIdString) && "topic-tree__" + planetId + "____" + langIdString + "__.json";
    const topicTreeUrl = fileName && baseUrl + fileName;

    const [topicTree, setTopicTree] = useState<Topic[]>([])

    useEffect(() => {
        languageId && setTopicTree(getTopicsPrimary(languageId));
    }, [topicTreeUrl]);

    /* ------------------------------------ */

    // Inform about a frustrating React behaviour
    useEffect(() => {
        if (window.location.href.includes("localhost:3000")) {
            console.log("New in React 18: useEffect runs twice when a component mounts. The behaviour applies to development mode only; production behavior is unchanged. Read more: https://reactjs.org/docs/strict-mode.html#ensuring-reusable-state");
        }
    }, []);

    const wholeUrl: string = window.location.href;
    const urlPathAfterDomain: string[] = wholeUrl.split("/").slice(3);
    let url: string = urlPathAfterDomain.splice(0, urlPathAfterDomain.length - 1).join("/");

    // When running locally using 'npm start', NODE_ENV is set to 'development' by default
    // we utilze this to set url to 'abp' which makes library,stage,playground work in local dev as well
    // TODO: set url to process.env.REACT_APP_<variable name> (eg REACT_APP_ROUTING_URL).
    // Thus, the url will be set dynamically depending on environment. / Magnus Norvik 2022-09-09
    if (process.env.NODE_ENV === 'development') {
        url = "abp";
    }

    /** Helper function that recursively searches the input node and its descendants, and returns the first node that matches the contidion.
     * Use it to get a specific node 
     * or combine it with `Array.filter()` to get all nodes that matches the condition.
     * Combine it with `Array.find()` to search through an array of nodes.
     * 
     * Examples of use: 
     * `const tasksNode = findNode(node => node.name === "tasks", rootNode);`,
     * `const taskNodes = nodeArray.filter(node => findNode(n => n.name === "tasks", node)); // find all task nodes in array of nodes`,
     * `const taskNode = nodeArray.find(node => findNode(n => n.name === "tasks", node)); // find first task node in array of nodes`
     */
    function findNode(callback: (node: CategoryTreeNode) => boolean, currentNode: CategoryTreeNode): CategoryTreeNode | undefined {
        if (callback(currentNode)) {
            return currentNode;
        }
        for (const child of currentNode.children) {
            const result = findNode(callback, child);
            if (result) {
                return result;
            }
        }
        return undefined;
    }

    const printNode  = categories?.find(n => n.name === `${cmsPlanetId}Print-node`);
    const libraryStoriesNode = categories?.find(n => n.name === `${cmsPlanetId}Library`);
    const rhymesExerciseNode = categories?.find(n => n.name === `${cmsPlanetId}Rhymes`);
    const categoriesContainingVisitMe = categories?.filter(category => category.children.find(node => node.name === "Besøk meg"));
    const allVisitMeIds = categoriesContainingVisitMe?.map(category => category.children.find(node => node.name === "Besøk meg")!.id);

    const baseUrlAlphabetUi = 'https://alphabet-planet-ui.s3.eu-west-1.amazonaws.com/images-ui/';
    const alphabetMap = baseUrlAlphabetUi + 'alphabet_planet_ui_map.svg';
    const mapBreadcrumb: Breadcrumb = {
        title: "Alfabet\u00ADplaneten",
        thumbnail: alphabetMap,
        to: '/' + url + '/alphabetPlanet.html',
    }

    const libraryBreadcrumb: Breadcrumb = {
        title: "Biblioteket",
        thumbnail: baseUrlAlphabetUi + 'alphabet_planet_ui_page_library.svg',
        to: parseToURL("/" + url + "/biblioteket"),
    }

    // --- There is no reason for not combining the following two functions.
    useEffect(() => {
        async function updateBasicSessionInfo() {
            const res = await getBasicSessionInfo() as any;
            const basicSessionInfo: BasicSessionInfoNode = res.data;
            setBasicSessionInfo(basicSessionInfo);
        }
        updateBasicSessionInfo();
    }, [setBasicSessionInfo])

    const { sessionInfo, setSessionInfo } = useContext(sessionContext) as SessionInfoContextType;
    useEffect(() => {
        /* Fetch and set session info when app renders */
        async function updateSession() {
            const res = await getSessionInfo() as any;
            const sessionInfo: SessionInfoNode = res.data;
            setSessionInfo(sessionInfo);
        }
        updateSession();
    }, [setSessionInfo]);
    // ---

    // --- Add values to local storage. These are currently only used by Matomo.
    const undefinedValue = "Unknown"
    localStorage.setItem("organizationUnit", sessionInfo?.user?.organizationunit ?? undefinedValue);
    localStorage.setItem("userId", sessionInfo?.user?.id.toString() ?? undefinedValue);
    const municipalityDomain = sessionInfo?.user?.email.split("@")[1] ?? undefinedValue;
    localStorage.setItem("municipalityDomain", municipalityDomain ?? undefinedValue);
    // ---

    /** Two-letter locale that represents a language and be added to url */
    let urlLocale = basicSessionInfo?.locale && basicSessionInfo.locale.slice(basicSessionInfo.locale.length - 2).toLocaleLowerCase();

    const pagesNode = categories?.find(n => n.name === "Pages-node");
    const songsPageNode = pagesNode?.children.find(n => n.name === "Songs-page");
    const songsPageNodeTitle = songsPageNode?.children.find(n => n.name === "Title")?.children.find(n => n.name.includes(cmsLangId))?.description;
    const libraryPageNode = pagesNode?.children.find(n => n.name === "Library-page");
    const libraryPageTitle = libraryPageNode?.children.find(n => n.name === "Title")?.children.find(n => n.name.includes(cmsLangId))?.description;
    const socialCompetenceNode = categories?.find(n => n.name === `${cmsPlanetId}Social competence`);

    const songsNode = categories?.find(n => n.name === "Songs-node");
    const componentsNode = categories?.find(n => n.name === "Components-node");
    const teacherNavMenuNode = categories?.find(n => n.name === "TeacherNavMenu-node");
    const globalTranslationsNode = categories?.find(n => n.name === "GlobalTranslations-node");
    const storiesCategoryNode = cmsPlanetId ? categories?.find(n => n.name.includes("Stories") && n.name.includes(cmsPlanetId)) : undefined;
    const storiesPageNode = pagesNode?.children.find(n => n.name === "Stories-page");
    const storiesPageTitle = storiesPageNode?.children.find(n => n.name === "Title")?.children.find(n => n.name.includes(cmsLangId))?.description;
    const tasksPageNode = pagesNode?.children.find(n => n.name === "Exercises-page");
    const tasksPageTitle = tasksPageNode?.children.find(n => n.name === "Title")?.children.find(n => n.name.includes(cmsLangId))?.description;
    const activitiesPage = pagesNode?.children.find(n => n.name === "Activities-page");
    const activitiesPageTitle = activitiesPage?.children.find(n => n.name === "Title")?.children.find(n => n.name.includes(cmsLangId))?.description;
    const inhabitantsNode = categories?.find(n => n.name === "Inhabitants-node");
    const libraryInhabitantsNode = categories?.find(n => n.name === "LibraryInhabitants-node");
    const letterPagePageNode = pagesNode?.children.find(n => n.name === "LetterPage-page");
    const ExercisesPageNode = pagesNode?.children.find(n => n.name === "Exercises-page");
    const exercisesCategoryNode = planetIdNode && categories?.find(n => n.name.includes(planetIdNode.description) && n.name.includes("Exercises"));
    const activitiesCategoryNode = planetIdNode && categories?.find(n => n.name.includes(planetIdNode.description) && n.name.includes("Activities"));
    const storyPageNode = pagesNode?.children.find(n => n.name === "Story-page");
    const storiesTeacherUrl = cmsLangId === "<1>" ? "/teacher/topic/122439779" : "./..";
    const abpLogo = componentsNode?.children.find(n => n.name === "AlphabetPlanet-logo")?.children.find(n => n.name.includes(cmsLangId))?.description;

    /* Create activity topics from ICSX categories */
    const activitiesTopics = useMemo(() => {
        const pageNode = (activitiesCategoryNode && activitiesPageTitle) && {
            categoryId: "aktiviteter",
            level: ">>",
            parentId: "",
            published: "TRUE",
            title: activitiesPageTitle,
            guidance: activitiesCategoryNode?.description,
            themeName: "activities",
        } as Topic;
        const leafNodes = (activitiesCategoryNode && activitiesPageTitle) &&
            activitiesCategoryNode?.children.map((category) => {
                return (
                    {
                        categoryId: category.id.toString(),
                        level: ">>>",
                        parentId: "aktiviteter",
                        published: "TRUE",
                        title: category.name,
                        guidance: category.description,
                    } as Topic
                )
            });
        return (pageNode && leafNodes) && [pageNode, ...leafNodes];
    }, [activitiesCategoryNode?.id]);

    /* Add activity topics to the topics list */
    useEffect(() => {
        if (activitiesTopics) setTopicTree((prev) => [...prev, ...activitiesTopics]);
    }, [activitiesTopics]);


    // Fallback in case the locale field is not in the userSession response. It has happened before.
    /*   if (!urlLocale && langId) {
        console.log("Missing locale field in GetSessionInfo response");
        switch (langId.toString()) {
          case "1":
              urlLocale = "no"
            break;
    
            case "2":
              urlLocale = "us";
            break;
    
            case "3":
              urlLocale = "ny";
            break;
    
            case "8":
              urlLocale = "se";
            break;
        
          default:
              
            break;
        }
      } */

    const isProd = window.location.hostname === URLs.AbpProd.replace(/^(https?:\/\/)|(\/)/g, ''); /* Remove 'http', 'https' and '/' from URL */
    const isStage = window.location.hostname === URLs.AbpStage.replace(/^(https?:\/\/)|(\/)/g, ''); /* Remove 'http', 'https' and '/' from URL */
    const hasAbpAccess = sessionInfo?.user ? sessionInfo.user.hasAbpAccess : undefined;
    const isAdmin = sessionInfo?.user?.roles?.isAdmin;
    const isDeveloper = process.env.NODE_ENV === 'development' && hasAbpAccess; // hasAbpAccess is part of the condition to mimic behavior when not logged in
    const userIsNotLoggedIn = sessionInfo !== undefined && sessionInfo?.user === undefined;

    /*if (userIsNotLoggedIn) {
        redirectToLogin();
    }*/

    const mainContent =
        <main>
            <Routes>
                {rhymesExerciseNode &&
                    <Route
                        path={"/teacher/rhymes"}
                        element={
                            <RhymesPage rhymesExerciseNode={rhymesExerciseNode}/>
                        }
                    />
                }
                {printNode &&
                    <Route
                        path={"/teacher/print"}
                        element={
                            <PrintPage printNode={printNode} themeName={'print'}/>
                        }
                    />
                }
                <Route
                    path={"/teacher/rhymes/:exportId/:title"}
                    element={
                        <VideoPage />
                    }
                />
                {(pagesNode && componentsNode && teacherNavMenuNode) &&
                    <>
                        <Route path='/' element={<TempStructureWrapper pagesNode={pagesNode} componentsNode={componentsNode} teacherNavMenuNode={teacherNavMenuNode} />} />
                        <Route path={'/:urlParam'} element={<TempStructureWrapper pagesNode={pagesNode} componentsNode={componentsNode} teacherNavMenuNode={teacherNavMenuNode} />} />
                    </>
                }

                {/* Map page */}
                {(categories && storiesPageTitle && letterNodesInOrder && inhabitantsNode && planetIdNode && letterPagePageNode) &&
                    <Route
                        path={'/student'}
                        element={
                            <Map
                                categories={categories} songsPageTitle={songsPageNodeTitle}
                                libraryPageTitle={libraryPageTitle}
                                storiesPageTitle={storiesPageTitle}
                                lettersInOrder={letterNodesInOrder}
                                inhabitantsNode={inhabitantsNode}
                                planetIdNode={planetIdNode}
                                letterPagePageNode={letterPagePageNode}
                            />
                        }
                    />
                }

                {(storiesCategoryNode && storyPageNode && storiesPageTitle) &&
                    <>
                        <Route path={"/teacher/" + parseToURL(storiesPageTitle)} element={<StoriesListing url={url} storiesCategoryNode={storiesCategoryNode} storiesPageNode={storiesPageNode} />} />
                        <Route path={"/teacher/" + parseToURL(storiesPageTitle) + "/:storyId"} element={<Story backBtnUrl={storiesTeacherUrl} storyPageNode={storyPageNode} />} />
                        <Route path={"/student/:storyId"} element={<Story backBtnUrl={"./.."} storyPageNode={storyPageNode} />} />
                        <Route path={"/student/library/:storyId"} element={<Story backBtnUrl={"./.."} storyPageNode={storyPageNode} />} />
                    </>
                }

                {(songsPageNode && songsNode && songsPageNodeTitle) &&
                    <>
                        <Route
                            path={'/teacher/' + parseToURL(songsPageNodeTitle) + '/*'}
                            element={<Stage videosNode={songsNode} pageNode={songsPageNode} breadcrumbs={[mapBreadcrumb]} />}
                        />
                        <Route
                            path={'/student/' + parseToURL(songsPageNodeTitle) + '/*'}
                            element={<Stage videosNode={songsNode} pageNode={songsPageNode} breadcrumbs={[mapBreadcrumb]} />}
                        />
                    </>
                }
                {(tasksPageTitle && ExercisesPageNode && exercisesCategoryNode) &&
                    <Route
                        path={"/teacher/" + parseToURL(tasksPageTitle)}
                        element={<Tasks breadcrumbs={[mapBreadcrumb, libraryBreadcrumb]} exercisesCategoryNode={exercisesCategoryNode} pageNode={ExercisesPageNode} />} />
                }
                {(activitiesPageTitle && activitiesCategoryNode) &&
                    <Route
                        path={'/teacher/' + parseToURL(activitiesPageTitle)}
                        element={
                            <Playground breadcrumbs={[mapBreadcrumb]} pageNode={activitiesPage} activitiesCategoryNode={activitiesCategoryNode} />
                        }
                    />
                }

                {(libraryStoriesNode && libraryPageNode) &&
                    <Route
                        path={"/student/" + parseToURL("library")}
                        element={
                            <LibraryPage
                                libraryPageNode={libraryPageNode}
                                libraryStoriesNode={libraryStoriesNode}
                            />}
                    />}
                {storiesPageNode && storiesCategoryNode &&
                    <Route
                        path="/teacher/topic"
                        element={
                            <TopicNode
                                topicTree={topicTree}
                                activityUrl={"/teacher/topic/aktiviteter"}
                                songUrl={"/teacher/" + parseToURL(songsPageNodeTitle || "")}
                                storiesPageNode={storiesPageNode}
                                storiesCategoryNode={storiesCategoryNode}
                                abpLogo={abpLogo || ""}
                            />
                        }
                    >
                        <Route
                            path={":topicId"}
                            element={
                                <TopicNode
                                    topicTree={topicTree}
                                    activityUrl={"never"}
                                    songUrl={"never"}
                                    storiesPageNode={storiesPageNode}
                                    storiesCategoryNode={storiesCategoryNode}
                                />
                            }
                        >
                        </Route>
                    </Route>
                }
                {storyPageNode &&
                    <Route
                        path={"/teacher/topic/" + "sosial_kompetanse/:storyId"}
                        element={
                            <Story backBtnUrl={"./.."} storyPageNode={storyPageNode} />
                        }
                    >
                    </Route>}
                {/* Redirect when entering Forståelse > Fortellinger */}
                {/* Forståelse: 122439779, Fortellinger: 122440443 */}
{/*                 <Route path={"/teacher/topic/122440443"} element={<Navigate to={"/teacher/topic/122439779/fortellinger"} />} />
                {(storiesCategoryNode && storiesPageNode) &&
                    <Route
                        path={"/teacher/topic/122439779/fortellinger"}
                        element={<StoriesListing url={url} storiesPageNode={storiesPageNode} storiesCategoryNode={storiesCategoryNode} />}
                    />
                }
                {storyPageNode &&
                    <Route
                        path={"/teacher/topic/122439779/fortellinger/:storyId"}
                        element={
                            <Story backBtnUrl={storiesTeacherUrl} storyPageNode={storyPageNode} />
                        }
                    />
                } */}

                {/* Redirect front page to topic page */}
                <Route
                    path={"/teacher"}
                    element={
                        <Navigate to={"/teacher/topic"}/>
                    }
                />

                {/*pagesNode && componentsNode && teacherNavMenuNode &&
                    <Route path={url + "/" + urlLocale + '/topics'} element={
                        <TempStructureWrapper
                            pathAfterDomain={url + "/" + urlLocale}
                            pagesNode={pagesNode}
                            componentsNode={componentsNode}
                            teacherNavMenuNode={teacherNavMenuNode}/>
                        }>
                    <Route path={":nodeId"} element={<TopicNode/>}></Route>
                </Route>*/}
            </Routes>
        </main>

    return (
        <planetContext.Provider value={{ cmsPlanetId: cmsPlanetId, alphabet: lettersInOrderStrings }}>
            {(isProd && hasAbpAccess) || (isStage && isAdmin) || (isDeveloper) ?
                <categoryTreeContext.Provider
                    value={{
                        inhabitants: inhabitantsNode,
                        libraryInhabitants: libraryInhabitantsNode,
                        components: componentsNode,
                        globalTranslations: globalTranslationsNode,
                        pages: pagesNode,
                        songs: songsNode,
                        stories: storiesCategoryNode,
                    }} >
                    <div>
                        {mainContent}
                    </div>
                </categoryTreeContext.Provider>
                :
                <>
                    {error && <p><strong lang="en">{error?.message}</strong></p>}
                </>
            }
        </planetContext.Provider>

    );
};
