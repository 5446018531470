import ReactDOM from 'react-dom';
import styles from './Modal.module.css';
import {CSSProperties, useEffect, useRef, useState} from "react";
import { FaWindowClose } from 'react-icons/fa';
import {useMGMTTranslations} from "../../../utils/TranslationHooks";

interface Props {
    show?: boolean;
    backdropClicked?: () => void;
    style?: CSSProperties;
    heading?: string;
    children?: any;
    backButton?: boolean;
}

const Modal = (props: Props) => {
    const [prevFocus] = useState(document.activeElement);
    const modalRef = useRef<HTMLDivElement>(null);
    const mgmtTranslate = useMGMTTranslations();

    useEffect(() => {
        if(modalRef.current) {
            //Reset focus on every update
            trapFocus(modalRef.current)
            focusFirstElement(modalRef.current);
        }
    });

    useEffect(() => {
        return () => {
            //Load previous focus. If none, focus on the first element of the page
            if(prevFocus){
                if(prevFocus){
                    //@ts-ignore
                    prevFocus.focus();
                }
                else{
                    focusFirstElement(document.body);
                }
            }
        }
    });

    return (
        <>
            {ReactDOM.createPortal(
                <div
                    role={"dialog"}
                    aria-labelledby={"modal-id"}
                    hidden={!props.show}
                    className={styles.backdrop}
                    onClick={props.backdropClicked}
                >
                    <div ref={modalRef} onClick={e => e.stopPropagation()} className={styles.modal} style={props.style}>
                        {(props.heading || props.backButton) &&
                            <div className={styles["heading-wrapper"]}>
                                {
                                    props.heading &&
                                    <h1 className={styles["modal-header"]} id="modal-id">{props.heading}</h1>
                                }
                                {
                                    props.backButton &&
                                    <button
                                        onClick={props.backdropClicked} style={{fontSize: "1.25rem"}}
                                    >
                                        <FaWindowClose aria-label={mgmtTranslate("global_close") || ""}/>
                                    </button>
                                }
                            </div>
                        }
                        {props.children}

                        <div className={styles['cartoony-shadow-bottom']} aria-hidden={true} ></div>
                        <div className={styles['cartoony-shadow-left']} aria-hidden={true}></div>
                        <div className={styles['cartoony-shadow-right']} aria-hidden={true}></div>
                        <svg className={styles['cartoony-shadow-curve-bottom-left']} height="40" width="40" aria-hidden={true}><path d="M0,0 C0,40 0,40 40,40 L0,40 Z" /></svg>
                        <svg className={styles['cartoony-shadow-curve-bottom-right']} height="40" width="40" aria-hidden={true}><path d="M0,0 C0,40 0,40 40,40 L0,40 Z" /></svg>

                    </div>
                </div>,
                document.getElementById('rootAbp')!
            )}
        </>

    )
};


const getFocusableElementList = (element: HTMLElement) => {
    return element.querySelectorAll('a[href]:not([disabled]), ' +
        'button:not([disabled]), ' +
        'textarea:not([disabled]), ' +
        'input[type="text"]:not([disabled]), ' +
        'input[type="radio"]:not([disabled]), ' +
        'input[type="checkbox"]:not([disabled]), ' +
        'select:not([disabled]), ' +
        'audio:not([disabled]), ' +
        'video:not([disabled])');
}

const focusFirstElement = (element: HTMLElement) => {
    //@ts-ignore
    getFocusableElementList(element)[0]?.focus();
}

const trapFocus = (element: HTMLElement) => {
    const focusableEls = getFocusableElementList(element);
    const firstFocusableEl = focusableEls[0];
    const lastFocusableEl = focusableEls[focusableEls.length - 1];

    element.addEventListener('keydown', function(e) {
        const isTabPressed = (e.key === 'Tab');

        if (!isTabPressed) {
            return;
        }

        if ( e.shiftKey ) /* shift + tab */ {
            if (document.activeElement === firstFocusableEl) {
                //@ts-ignore
                lastFocusableEl.focus();
                e.preventDefault();
            }
        } else /* tab */ {
            if (document.activeElement === lastFocusableEl) {
                //@ts-ignore
                firstFocusableEl.focus();
                e.preventDefault();
            }
        }
    });
}

export default Modal;