import React from 'react';
import { FaPlay } from 'react-icons/fa';
import styles from './VideoCard.module.css';

interface VideoCardProps {
    title: string;
    thumbnail: string;
}

export const VideoCard = ({ title, thumbnail }: VideoCardProps) => {
    return (
        <div className={styles['video-card']} >
            <div className={styles.thumbnail}>
                <img className={styles.thumbnail__img} alt='' src={thumbnail} />
                <FaPlay className={styles['thumbnail__play-icon']} />
            </div>
            <div className={styles['title-box']}>
                <div className={styles.title} >{title}</div>
            </div>
        </div>
    );
};