import { Topic } from "../../utils/Topic";
import { ThemeName, Themes } from "../../utils/Themes";
import styles from "./TopicTreeLeafPage.module.css";
import { BackButtonLink } from "../UIComponents/BackButtonLink/BackButtonLink";
import { TopicTreeLeafNode } from "./TopicTreeLeafNode";
import { TopicHeader } from "../UIComponents/TopicNode/TopicHeader";
import { Markdown } from "../UIComponents/Markdown/Markdown";
import { PageWithSidebars } from "../UIUtils/PageWithSidebars/PageWithSidebars";

interface TopicTreeLeafPageProps {
    topic: Topic;
    /** The list of all topics in the app */
    topicTree: Topic[];
    themeName?: ThemeName;
}

export const TopicTreeLeafPage = ({ topic, topicTree, themeName }: TopicTreeLeafPageProps) => {

    const children = topicTree.filter(t => t.parentId === topic.categoryId);

    const scrollToAnchorNavItems = [
        ...children.map(childTopic => childTopic.title),
    ];

    // If topic has no children, the topic becomes a leaf node
    if (!children || children.length < 1) {
        children.push({ ...topic, title: "" });
    }

    const theme = themeName && Themes[themeName as ThemeName];
    const themeColor = theme?.lightColor;

    return (
        <div className={styles["topic-tree-leaf-page"]}
            style={{
                backgroundColor: themeName ? Themes[themeName].backgroundColor : "white"
            }}>

            <BackButtonLink linkTo={`${topic.parentId}`} />
            <PageWithSidebars
                header={<TopicHeader themeName={themeName}>{topic.title}</TopicHeader>}
                tableOfContent={scrollToAnchorNavItems} >
                <div className={styles.container}>
                    {topic.guidance &&
                        <Markdown>{topic.guidance}</Markdown>
                    }
                    {children.map(childTopic => (
                        <TopicTreeLeafNode
                            key={childTopic.categoryId}
                            topic={childTopic}
                            themeColor={themeColor}
                        />
                    ))}
                </div>
            </PageWithSidebars>
        </div>
    )
} 