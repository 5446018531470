import React, {useContext} from "react";
import ExercisesQueryParameters from "../../utils/ExercisesQueryParameters";
import {ObjectType} from "../../utils/exerciseService";
import useFetchExercises from "../../utils/useFetchExercises";
import {basicSessionInfoContext, BasicSessionInfoContextType} from "../../utils/BasicSessionContext";
import styles from "../LibraryPage/LibraryPage.module.css";
import {getExerciseThumbnail} from "../../utils/getExerciseThumbnail";
import {StoryPreview} from "../UIComponents/StoryPreview/StoryPreview";
import {CategoryTreeNode} from "../../utils/CategoryTreeNode";
import {categoryTreeContext, CategoryTreeContextType} from "../../utils/CategoryTreeContext";
import {useParams} from "react-router-dom";

interface Props {
    categoryId: string;
    prefixUrl?: string;
}

export const StoryList = ({categoryId, prefixUrl}: Props) => {
    const {stories} = useContext(categoryTreeContext) as CategoryTreeContextType;
    const {basicSessionInfo} = useContext(basicSessionInfoContext) as BasicSessionInfoContextType;
    const storyLanguageId = basicSessionInfo?.languageId;
    const {topicId} = useParams();

    // Fetch exercises so we can get their thumbnail for StoryPreview cards
    const params: ExercisesQueryParameters = {
        objectType: ObjectType.crz_exercise,
        hasContent: true, // Exercise is the stories main exercise
        categoryId: categoryId,
        languageId: storyLanguageId
    }
    const { data } = useFetchExercises(params);

    if(!data){
        return null;
    }

    return (
        <div className={styles["story-list-wrapper"]}>
            <div className={styles["story-list"]}>
                {stories && stories.children.map((story) => {
                    /* Using the mainExercise's thumbnail */
                    //@ts-ignore
                    const correspondingExercise = data?.exercises.find(ex => ex.categories.indexOf(story.id) > -1) ;

                    if(!correspondingExercise){
                        return null; //Don't render stories without exercises
                    }

                    const imgSrc = correspondingExercise && getExerciseThumbnail(correspondingExercise);
                    const storyHeadline = correspondingExercise?.name;
                    const letter = story.name[1];
                    return (
                        <div id={letter} key={story.id}>
                            <StoryPreview
                                /* @ts-ignore */
                                story={story as CategoryTreeNode}
                                imgSrc={imgSrc || ""}
                                alt=''
                                headline={storyHeadline || ""}
                                href={`${prefixUrl? `${prefixUrl}${story.id}` : `${topicId}/${story.id}`}`}
                            />
                        </div>
                    )}
                )}
            </div>
        </div>
    )
}