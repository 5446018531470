import { Topic } from "../../../utils/Topic";
import { Link } from "react-router-dom";
import styles from "./TopicTile.module.css"
import { ThemeName, Themes } from "../../../utils/Themes";
import { topicIconBaseURL } from "./TopicIcon";

interface Props {
    topic: Topic;
    themeName?: ThemeName;
    guidance?: string;
}

export const TopicTile = ({ topic, themeName = "alfa", guidance }: Props) => {
    const backgroundImage = topic.icon ? `${topicIconBaseURL}${topic.icon}` : undefined;

    return (
        <Link
            className={styles.title}
            to={`${topic.categoryId}`}>
            <div className={styles["topic-tile"]}>
                <div
                    className={styles["image-background"]}
                    style={{
                        backgroundColor: Themes[themeName].color,
                    }}
                >
                    {backgroundImage && <img className={styles.image} src={backgroundImage} alt="" />}
                </div>
                <div className={styles.text}>
                    {topic.title}
                </div>
            </div>
        </Link>
    )
}