import React from 'react';

import styles from './PageGeneralMain.module.css'

interface PageGeneralProps {
    children: React.ReactNode;
}

const PageGeneralMain = ({ children }: PageGeneralProps) => {
    return (
        <div className={styles.page_general__main}>
            {children}
        </div>
    );
};

export default PageGeneralMain;