import { Topic } from "../../utils/Topic";
import styles from "./TopicTreeBranchPage.module.css";
import { TopicTile } from "../UIComponents/TopicNode/TopicTile";
import { ThemeName, Themes } from "../../utils/Themes";
import { NodeWrapper } from "../UIComponents/NodeWrapper/NodeWrapper";
import { BackButtonLink } from "../UIComponents/BackButtonLink/BackButtonLink";
import { TopicHeader } from "../UIComponents/TopicNode/TopicHeader";
import { CategoryTreeNode } from "../../utils/CategoryTreeNode";
import { StoriesBranch } from "./StoriesBranch";
import { InfoButton } from "../UIComponents/InfoButton/InfoButton";

interface TopicTreeBranchPageProps {
    topic: Topic;
    topicTree: Topic[];
    themeName?: ThemeName;
    storiesCategoryNode: CategoryTreeNode;
    storiesPageNode: CategoryTreeNode;
}

export const TopicTreeBranchPage = (
    {
        topic,
        topicTree,
        themeName,
        storiesCategoryNode,
        storiesPageNode
    }: TopicTreeBranchPageProps) => {
    const children = topicTree.filter(t => t.parentId === topic.categoryId);
    const guidance = topic.guidance;

    //Special handling for "forståelse" / fortellinger
    if (topic.categoryId === "122439779" || topic.categoryId === "129319026") {
        return (
            <StoriesBranch
                storiesCategoryNode={storiesCategoryNode}
                storiesPageNode={storiesPageNode}
                topic={topic}
                themeName={themeName}
            />
        )
    }

    return (
        <div className={styles["topic-tree-branch-page"]}
            style={{
                backgroundColor: themeName ? Themes[themeName].backgroundColor : "white"
            }}
        >
            <BackButtonLink linkTo={`${topic.parentId}`} />
            <div className={styles.container}>
                <TopicHeader themeName={themeName}>{topic.title}</TopicHeader>
                <div className={styles.content}>
                    {topic.guidance &&
                        <div className={styles['info-btn']}>
                            <InfoButton text={topic.guidance} />
                        </div>
                    }
                    <NodeWrapper gap={true}>
                        {children
                            .filter(t => t.published === "TRUE")
                            .map((t, index) => {
                                return (
                                    <TopicTile
                                        key={index}
                                        topic={t}
                                        themeName={themeName}
                                        guidance={guidance && index === 0 ? guidance : undefined}
                                    />
                                )
                            })}
                    </NodeWrapper>
                </div>
            </div>
        </div>
    )
} 