import React from "react";
import styles from "./NodeWrapper.module.css";

interface Props {
    children?: JSX.Element[] | JSX.Element;
    alignTop?: boolean;
    justifyLeft?: boolean;
    gap?: boolean;
}

export const NodeWrapper = ({children, alignTop, justifyLeft, gap}: Props) => {
    return (
        <div className={`
            ${styles["topic-node-wrapper"]} 
            ${alignTop ? styles["align-top"] : ""}
            ${justifyLeft ? styles["justify-left"] : ""}
            ${gap ? styles["gap"] : ""}
        `}>
            {children}
        </div>
    )
}
