import {CategoryTreeNode} from "./CategoryTreeNode";

export interface PrintElement {
    title: string;
    description: string;
    thumbnail: string;
    pdf: string;
    id: string
}

export interface PrintCategory {
    name: string;
    children: PrintElement[];
}

export interface PrintStructure {
    description: string;
    categories: PrintCategory[];
    categoryNames: string[];
}

export function parsePrintTree(printNode: CategoryTreeNode) : PrintStructure {
    let printStructure: PrintStructure = {description: "", categories: [], categoryNames: []};
    let categories: CategoryTreeNode[] = printNode.children;
    let count = 0;

    printStructure.description = printNode.description;

    categories.forEach(node => {
        let printCategory: PrintCategory = {name: node.description, children: []};

        node.children.forEach(printEl    => {
            let printElement: PrintElement = {title: '', description: '', thumbnail: '', pdf: '', id: 'printElement_' + count};

            if (printEl.children[0])
                printElement.title = printEl.children[0].description;

            if (printEl.children[1])
                printElement.description = printEl.children[1].description;

            if (printEl.children[2])
                printElement.thumbnail = printEl.children[2].description;

            if (printEl.children[3])
                printElement.pdf = printEl.children[3].description;

            count++;
            printCategory.children.push(printElement);
        });

        printStructure.categories.push(printCategory);
        printStructure.categoryNames.push(printCategory.name);
    });

    return printStructure;
}

export function isInstanceOfPrintElement(element : any): boolean {
    return 'pdf' in element;
}