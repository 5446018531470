import {useParams} from "react-router-dom";
import {Topic} from "../../../utils/Topic";
import {TopicTreeLeafPage} from "../../TopicTreeLeafPage/TopicTreeLeafPage";
import {TopicTreeBranchPage} from "../../TopicTreeBranchPage/TopicTreeBranchPage";
import {TopicTreeRootPage} from "../../TopicTreeRootPage/TopicTreeRootPage";
import {CategoryTreeNode} from "../../../utils/CategoryTreeNode";

interface Props {
    topicTree: Topic[];
    activityUrl: string;
    songUrl: string;
    storiesCategoryNode: CategoryTreeNode;
    storiesPageNode: CategoryTreeNode;
    abpLogo?: string;
}

export const TopicNode = ({ topicTree, activityUrl, songUrl, storiesPageNode, storiesCategoryNode, abpLogo}: Props) => {
    const { topicId } = useParams();

    const thisTopic = topicTree.find(t => t.categoryId === topicId);
    const isLeafPage = thisTopic?.level.length === 2;
    // If the topic is a leaf page, we can get the themeName from the parent
    const themeName = thisTopic?.themeName || topicTree.find(t => t.categoryId === thisTopic?.parentId)?.themeName;

    if (!thisTopic) {
        return (
            <TopicTreeRootPage
                topics={topicTree.filter(topic => topic.level.length === 1)}
                activityUrl={activityUrl}
                songUrl={songUrl}
                abpLogo={abpLogo}
            />
        )
    }
    else if(isLeafPage){
        return (
            <TopicTreeLeafPage
                topic={thisTopic}
                topicTree={topicTree}
                themeName={themeName}
            />
        )
    }
    else{
        return (
            <TopicTreeBranchPage
                topic={thisTopic}
                topicTree={topicTree}
                themeName={themeName}
                storiesCategoryNode={storiesCategoryNode}
                storiesPageNode={storiesPageNode}
            />
        )
    }
}