import React, { useContext } from 'react';
import { CategoryTreeNode } from '../../../utils/CategoryTreeNode';
import { ObjectType } from '../../../utils/exerciseService';
import ExercisesQueryParameters from '../../../utils/ExercisesQueryParameters';
/* import { getFilters } from '../../../utils/getFilters'; */
import useFetchExercises from '../../../utils/useFetchExercises';
import PageGeneralMain from '../../PageGeneralMain';
import { Breadcrumbs } from '../../UIComponents/Breadcrumbs/Breadcrumbs';
import { Breadcrumb } from "../../UIComponents/Breadcrumbs/Breadcrumb";
import { ExerciseListing } from '../../UIComponents/ExerciseListing/ExerciseListing';
import { WrapMainContentWithOnboarding } from '../../UIUtils/WrapMainContentWithOnboarding/WrapMainContentWithOnboarding';

import styles from './Tasks.module.css';
import { BackButtonLink } from '../../UIComponents/BackButtonLink/BackButtonLink';
import { BasicSessionInfoContextType, basicSessionInfoContext } from '../../../utils/BasicSessionContext';

interface TasksProps {
    breadcrumbs: Breadcrumb[];
    exercisesCategoryNode: CategoryTreeNode;
    pageNode: CategoryTreeNode;
}

export const Tasks = ({ breadcrumbs, exercisesCategoryNode, pageNode }: TasksProps) => {

    const { cmsLangId, basicSessionInfo } = useContext(basicSessionInfoContext) as BasicSessionInfoContextType;

    const fetchingParams: ExercisesQueryParameters = { categoryId: exercisesCategoryNode.id, languageId: basicSessionInfo?.languageId };
    const { data, isPending, error } = useFetchExercises(fetchingParams);
    const allExercises = data?.exercises;

    const filterTags = exercisesCategoryNode.children;

    const cmsValues = {
        title: pageNode.children.find(n => n.name === "Title")?.children.find(n => n.name.includes(cmsLangId))?.description,
    }


    return (
        <div>
            <img className={styles['background-image']} alt="" src={'https://alphabet-planet-ui.s3.eu-west-1.amazonaws.com/images-ui/alphabet_planet_ui_page_tasks.svg'} ></img>
            {/* <Breadcrumbs breadcrumbs={breadcrumbs} currentPageTitle={mainNode.name} pushContentDown /> */}
            <PageGeneralMain>
                {allExercises &&
                    <div>
                        <BackButtonLink linkTo={"./.."} title={"Gå tilbake"} />
                        <h1 className={styles['page-title']}>{cmsValues.title}</h1>
                        <ExerciseListing exercises={allExercises} filterTags={filterTags} />
                    </div>
                }
            </PageGeneralMain>
        </div>
    );
};