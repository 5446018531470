import { Exercise } from "./exerciseService";

export function getPreviewUrl(exercise: Exercise) {
    const url = 'https://www.creaza.com/preview?alphabetplanet&exerciseId=' + exercise.id + "&temaTekst";
    if (exercise.exportId !== "0" && exercise.exportId) {
        return url + "=" + exercise.exportId;
    }
    else {
        return url;
    }
}