import axios, { AxiosError } from 'axios';
import { useContext, useEffect, useState } from 'react';
import { Exercise } from './exerciseService';
import ExercisesQueryParameters from "./ExercisesQueryParameters";
import ExercisesResponseData from './ExercisesResponseData';
import { APIEndpoints } from './APIEndpoints';
/* import { getExerciseThumbnail } from './getExerciseThumbnail'; */
import { BasicSessionInfoContextType, basicSessionInfoContext } from './BasicSessionContext';


/*
 * These are the optional parameters according to List.java:

    curriculumId: "curriculumId", // number
    languageId: "languageId", // number
    categoryId: "categoryId", // numberList
    ageGroupLevel: "ageGroupLevel", // numberList
    toolType: "toolType", // stringList
    userInputString: "userInputString", // string
    isTitleSearch: "isTitleSearch", // boolean
    hitsPerPage: "hitsPerPage", // number
    pageToShow: "pageToShow", // number
    sortType: "sortType", // string ("recommended", "newest", "popular")
    createdByMe: "createdByMe", // boolean
    hasContent: "hasContent", // boolean
    isSamfunnJunior: "isSamfunnJunior", // boolean
    objectType: "objectType", // string ("crz_exercise", "crz_exercise_samfunn (these are products, not exercises, and does not only realate to social studies), "crz_exercise_english", "crz_exercise_universe")
    categoryIntersection: "categoryIntersection", // boolean. if true and several categoryIds are sent the resultset will consist of exercises having all these categories.
    exerciseCategory: "exerciseCategory" // string. Seems like many exercises in db has "null" as value for this property, so I don't think this argument will do much of a job.
*/

/**
 * Returns response from get request. response.data will containt "exercises", "count", "maxPage", and "page" if request is successful.
 *
 * @param queryParameters - Object containing parameters to insert in the query string
 *
*/
const useFetchExercises = (queryParameters: ExercisesQueryParameters, categoryIds?: string[]) => {

    const { basicSessionInfo } = useContext(basicSessionInfoContext) as BasicSessionInfoContextType;

    // Make sure language id is included as parameter in the request.
    if (!queryParameters.languageId) {
        queryParameters.languageId = basicSessionInfo?.languageId;
    }

    const [data, setData] = useState<ExercisesResponseData | null>(null);
    const [error, setError] = useState<AxiosError | null>(null);
    const [isPending, setIsPending] = useState<boolean>(true);

    //const abortController = new AbortController();
    const url = APIEndpoints.Exercises;
    const isDevelop = process.env.NODE_ENV === 'development';

    // Set hitsPerPage to a high number as default since we don't use pagination in Alphabet planet
    const queryArguments = {
        hitsPerPage: 10000,
        ...queryParameters
    };

    useEffect(() => {
        setIsPending(true);
        setData(null);

        const potentiallySeveralCategoryIds = categoryIds ? "categoryId=" + categoryIds.toString().replaceAll(",", "&categoryId=") : "";

        axios.get(url + potentiallySeveralCategoryIds, {
            params: queryArguments,
            /* signal: abortController.signal, */
            withCredentials: isDevelop ? false : true
        })
            .then(response => {

                // Parse soft hyphens
                const resdata: ExercisesResponseData = response.data;

                resdata.exercises.forEach(ex => parseSoftHyphen(ex));
                //------------

                // Make links open in new tab to avoid doubled top menu bar while this project is in iFrame
                const attribute = "href=";
                resdata.exercises.forEach(exercise => {
                    if (exercise.description?.includes(attribute)) {
                        exercise.description = exercise.description.replaceAll(attribute, 'rel="noopener noreferrer" target="_blank" ' + attribute);
                    }
                })
                //------------

                // Set getThumbnail property. Commented out because exercises do not have a getThumbnail property, but it might come in handy at some point
                /* resdata.exercises.forEach(ex => ex.getThumbnail = () => {return getExerciseThumbnail(ex)}); */

                setData(resdata);
            })
            .catch(error => {
                setError(error);
            })
            .finally(() => setIsPending(false));

    }, [url, queryParameters.categoryId, queryParameters.hitsPerPage, queryParameters.objectType, queryParameters.pageToShow, queryParameters.sortType, queryParameters.userInputString, queryParameters.hasContent])


    function parseSoftHyphen(node: Exercise) {
        node.name = node.name.replace("&shy;", "\u00AD");
        node.description = node.description?.replace("&shy;", "\u00AD");
    }

    return { data, isPending, error };
}

export default useFetchExercises;
