import React from 'react';

import styles from './Button.module.css';

const Button = (props : any) => {
  return (
    <button className={styles.exercise_btn} hidden={props.hidden} aria-label={props.ariaLabel} onClick={props.onClick}>
        {props.children}
    </button>
  )
}

export default Button;