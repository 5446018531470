import React from "react";
import styles from "./LargeHeader.module.css";
import {ThemeName, Themes} from "../../../utils/Themes";

interface Props {
    headingLevel: number;
    children: any;
    themeName?: ThemeName;
}
export const LargeHeader = ({children, headingLevel, themeName}: Props) => {
    const Heading = `h${headingLevel}`
    return (
        //@ts-ignore
        <Heading
            className={styles['large-header']}
            style={{
                color: themeName ? Themes[themeName].lightColor : "white",
                //WebkitTextStroke: `4px ${themeName ? Themes[themeName].darkColor : "darkblue"}`
            }}
        >
            {children}
        </Heading>
    )
}