import { TopicIcon } from "../pages/UIComponents/TopicNode/TopicIcon";
import { Topic } from "./Topic";

/**
 * @param languageId 
 * @returns List of topics for the given language
 */

export const getTopicsPrimary = (languageId: number) => {
  switch (languageId) {
    case 1: // Norwegian Bokmål
      return topics__no____1__.concat(socialCompetenceTopics__no____1__);

    case 3: // Norwegian Nynorsk
      return [...topics__no____3__, ...socialCompetenceTopics__no____3__];

    case 8: // Swedish
      return [...topics__se____8__, ...socialCompetenceTopics__se____8__];

    default:
      console.error("Unsupported languageId: " + languageId);
      return [];
  }
}

/* Information about the topics:
  The topics are stored in this file, but they don't have to be.
  At the time of writing this, we don't have a way for the authors to edit them,
  at least not a solution that would make it easy for them as well as for you as a developer.
  
  I've decided to just keep all of them here in one place instead of mapping them from another source.
  This is because I will soon leave Creaza, so I want to make it as easy as possible for you to create a new CMS.

  Thomas Bugge, 08.02.2024
*/

/**
 * Hierarchical structure of topics.
 * At the time of writing this,
 * the tree level is decided by number of characters in the "level" value.
 * Level value with exactly two characters is a leaf page.
 * Level with three characters (currently the deepest level),
 * means it represents a section of a page.
 */
const topics__no____1__: Topic[] = [
  {
    "level": ">",
    "categoryId": "122439762",
    "parentId": "122439758",
    "title": "Avkoding",
    "guidance": `
I denne delen er det fokus på grunnleggende skrive- og leseopplæring, og oppgavene står i stigende vanskelighetsgrad. Her utforsker elevene lyder for å utvikle fonologisk og fonemisk bevissthet. De arbeider med formell og funksjonell bokstavkompetanse og utvikler fonologiske strategier.  

Trykk på kategoriene under for tips til hvordan du og elevene dine kan jobbe dere frem mot fonologisk avkoding.`,
    "icon": TopicIcon.Alfa,
    "published": "TRUE",
    themeName: "alfa"
  },
  {
    "level": ">>",
    "categoryId": "122439773",
    "parentId": "122439762",
    "title": "Rim",
    "guidance": `
Start gjerne med rim og rimord for å la elevene leke med lyd og rytme i språket. Les høyt for elevene, og lytt ut lyder sammen med dem. Elevene kan også pare rimord gjennom oppgavene. Rimbevissthet er viktig for å utvikle fonologisk bevissthet.

*Tips: Du finner flere rim, regler, sanger og leker knyttet til dette på «Biblioteket» og «Scenen», og under «Aktiviteter».*`,
    "icon": TopicIcon.Bok1,
    "published": "TRUE"
  },
  {
    "level": ">>>",
    "categoryId": "122439774",
    "parentId": "122439773",
    "title": "Rim",
    "guidance": "",
    "icon": "",
    "published": "TRUE"
  },
  {
    "level": ">>>",
    "categoryId": "122439777",
    "parentId": "122439773",
    "title": "Rimord",
    "guidance": ``,
    "icon": "",
    "published": "TRUE"
  },
  {
    "level": ">>",
    "categoryId": "122439783",
    "parentId": "122439762",
    "title": "Bokstavlyd",
    "guidance": `
Å lytte ut lyder, samt å finne førstelyd og sistelyd, hjelper elevene å utvikle fonemisk bevissthet. Her finner du lekne oppgaver og myldrebilder med fokus på lyder og hvordan de kobles til bokstaver. Gjennom de 29 bokstavbøkene utvikles formell og funksjonell bokstavkompetanse i tillegg til fonemisk bevissthet. 
    
La også elevene utforske og oppdage forbindelsen mellom lyd og bokstav gjennom oppdagende skriving, som det finnes flere opplegg for under Aktiviteter. Gjennom slike opplegg utvikler elevene funksjonell bokstavkompetanse. Ved å arbeide med flere ulike metoder, kan elevene utvikle automatisert lyd-bokstav-kobling.`,
    "icon": TopicIcon.Bok2,
    "published": "TRUE",
  },
  {
    "level": ">>>",
    "categoryId": "122440260",
    "parentId": "122439783",
    "title": "Bokstavbøker",
    "guidance": "",
    "icon": "",
    "published": "TRUE"
  },
  {
    "level": ">>>",
    "categoryId": "122440261",
    "parentId": "122439783",
    "title": "Myldrebilder",
    "guidance": "",
    "icon": "",
    "published": "TRUE"
  },
  {
    "level": ">>>",
    "categoryId": "122440262",
    "parentId": "122439783",
    "title": "Første lyd",
    "guidance": "",
    "icon": "",
    "published": "TRUE"
  },
  {
    "level": ">>>",
    "categoryId": "122440263",
    "parentId": "122439783",
    "title": "Siste lyd",
    "guidance": "",
    "icon": "",
    "published": "TRUE"
  },
  {
    "level": ">>",
    "categoryId": "122440265",
    "parentId": "122439762",
    "title": "Bokstav\u00ADsekvens",
    "guidance": `På veien mot fonologisk avkoding vil elevene etter å ha fokusert på enkeltlyder, ha behov for å utforske sammensatte lyder. Her finnes det korte og morsomme tekster og rim med tilhørende oppgaver der elevene arbeider med høyfrekvente bokstavsekvenser, som endelser og begynnelser i ord, samt diftonger, sj-lyd, kj-lyd og ng-lyd. Les gjerne tekstene for eller sammen med elevene og reflekter sammen om forbindelsen mellom lyd og bokstavsekvens.`,
    "icon": TopicIcon.Bok3,
    "published": "TRUE"
  },
  {
    "level": ">>>",
    "categoryId": "122440266",
    "parentId": "122440265",
    "title": "Diftonger",
    "guidance": "",
    "icon": "",
    "published": "TRUE"
  },
  {
    "level": ">>>",
    "categoryId": "122440267",
    "parentId": "122440265",
    "title": "Høyfrekvente begynnelser",
    "guidance": "",
    "icon": "",
    "published": "TRUE"
  },
  {
    "level": ">>>",
    "categoryId": "122440268",
    "parentId": "122440265",
    "title": "Høyfrekvente endelser",
    "guidance": "",
    "icon": "",
    "published": "TRUE"
  },
  {
    "level": ">>>",
    "categoryId": "122440269",
    "parentId": "122440265",
    "title": "Sammensatte grafemer",
    "guidance": "",
    "icon": "",
    "published": "TRUE"
  },
  {
    "level": ">>",
    "categoryId": "122440291",
    "parentId": "122439762",
    "title": "Stavelse",
    "guidance": `
Her kan elevene arbeide med stavelser. De kan selv lese stavelsene i ordene, eller lytte dem ut. Oppgavene gir trening i å lese stavelse for stavelse, og på den måten øke lesehastigheten. Stavelseslesing avlaster også minnet fordi det blir færre enheter som skal trekkes sammen. Oppgavene varierer fra enkle ord med to stavelser til ord med flere stavelser.

Denne delen kan arbeides med parallelt med delen Bokstavsekvens, da noen oppgaver tar for seg enkle ord, stavelser og lyder, og andre mer sammensatte. Tildel oppgaver tilpasset den enkelte elevs lese- og skriveutvikling.`,
    "icon": TopicIcon.Bok4,
    "published": "TRUE"
  },
  {
    "level": ">",
    "categoryId": "122439779",
    "parentId": "122439758",
    "title": "Forståelse",
    "guidance": `
Parallelt som elevene trener ordavkoding, er det viktig å jobbe med forståelse.

For elever som kan mange begreper, vil veien til forståelse naturlig nok være kortere. På Alfabetplaneten har vi laget mange aktiviteter, tekster og oppgaver med spesielt fokus på språkutvikling. Aktivitetene finner du på lærerens forside under *Aktiviteter*-knappen.  

Alle aktivitetene er laget med utgangspunkt i interessene og personlighetene til innbyggerne på Alfabetplaneten. Dem blir dere kjent med gjennom alle disse fortellingene. Historiene er visualisert og innlest med ulike stemmer og dialekter.

Til slutt i hver fortelling finnes en side med spørsmål til samtale og refleksjon. Du velger hvordan du vil bruke disse med elevene dine, enten dere tar dem i helklasse, lar elevene jobbe parvis eller individuelt. Alle fortellingene er laget med innhold fra Alfabetplaneten i Cartoonist, så dette innholdet finner elevene igjen inne i verktøyet.  

Hver fortelling har tre tilhørende oppgaver: 
- Fortellingen i tekstutgave 
- En oppgave som jobber konkret med noe i fortellingen 
- En oppgave som tar budskapet eller temaet fra fortellingen inn i elevenes eget liv  

Alle oppgavene har en oppgavetekst som forteller hvordan oppgaven er tenkt å brukes.`,
    "icon": TopicIcon.Bravo,
    "published": "TRUE",
    themeName: "bravo",
  },
  {
    "level": ">>",
    "categoryId": "122440443",
    "parentId": "122439779",
    "title": "Fortellinger",
    "guidance": "",
    "icon": "",
    "published": "TRUE",
    themeName: "bravo",
  },
  {
    "level": ">",
    "categoryId": "122439780",
    "parentId": "122439758",
    "title": "Systemer i språket",
    "guidance": `
I denne delen er det fokus på regler og systemer i språket. Gjennom presentasjoner og samtaler kan elevene bevisstgjøres om regler for rettskriving, grammatikk, tegnsetting samt bruk av tall. Gjennom aktiviteter og oppgaver kan de øve inn reglene.

Trykk på kategoriene under for tips til hvordan du og elevene dine kan arbeide med systemer i språket.`,
    "icon": TopicIcon.Charlie,
    "published": "TRUE",
    themeName: "charlie",
  },
  {
    "level": ">>",
    "categoryId": "122440300",
    "parentId": "122439780",
    "title": "Rett\u00ADskriving",
    "guidance": "I denne delen arbeider elevene både med rettskrivingsregler (som o-lyd og å-lyd, kj-lyd og sj-lyd) og morfemer. Selv om ortografisk skriving ofte først og fremst læres ved at elever får mye erfaring med korrekt skriftspråk gjennom lesing og skriving, er det nyttig å bevisstgjøre elevene om visse rettskrivningsregler. Å arbeide med morfemer, rotmorfemer og meningsbærende morfemer støtter både skrive- og leseutvikling hos elevene. Norsk har mange sammensatte ord, og øvelse av strategier som gjør elever i stand til å dele opp lange ord i mindre deler, gjør at de raskere kan forstå betydningen av ordene.",
    "icon": TopicIcon.Rettskriving,
    "published": "TRUE"
  },
  {
    "level": ">>>",
    "categoryId": "122440301",
    "parentId": "122440300",
    "title": "O, U og Å",
    "guidance": "",
    "icon": "",
    "published": "TRUE"
  },
  {
    "level": ">>>",
    "categoryId": "122440304",
    "parentId": "122440300",
    "title": "Sammensatte grafemer",
    "guidance": "",
    "icon": "",
    "published": "TRUE"
  },
  {
    "level": ">>>",
    "categoryId": "122440306",
    "parentId": "122440300",
    "title": "Sammensatte ord",
    "guidance": "",
    "icon": "",
    "published": "TRUE"
  },
  {
    "level": ">>",
    "categoryId": "122440307",
    "parentId": "122439780",
    "title": "Grammatikk",
    "guidance": "Her finner du presentasjoner og oppgaver knyttet til grammatiske systemer og regler. Å bli bevisst på meningsbærende morfemer og bøyningsmorfemer, er viktig. Etter hvert som flere morfemer gjenkjennes av elevene, øker lesehastigheten og leseforståelsen. Reflekter gjerne sammen over bøyningsmønstre. Små endringer av ord kan forandre ordenes betydning, og endringene er viktige for lesing, skriving og tekstforståelse.",
    "icon": TopicIcon.Grammatikk,
    "published": "TRUE"
  },
  {
    "level": ">>>",
    "categoryId": "122440310",
    "parentId": "122440307",
    "title": "Verb",
    "guidance": "",
    "icon": "",
    "published": "TRUE"
  },
  {
    "level": ">>>",
    "categoryId": "122440311",
    "parentId": "122440307",
    "title": "Substantiv",
    "guidance": "",
    "icon": "",
    "published": "TRUE"
  },
  {
    "level": ">>>",
    "categoryId": "122440312",
    "parentId": "122440307",
    "title": "Adjektiv",
    "guidance": "",
    "icon": "",
    "published": "TRUE"
  },
  {
    "level": ">>>",
    "categoryId": "122440313",
    "parentId": "122440307",
    "title": "Preposisjoner",
    "guidance": "",
    "icon": "",
    "published": "TRUE"
  },
  {
    "level": ">>",
    "categoryId": "122440379",
    "parentId": "122439780",
    "title": "Tegnsetting",
    "guidance": "",
    "icon": TopicIcon.Tegnsetting,
    "published": "FALSE"
  },
  {
    "level": ">>>",
    "categoryId": "122440381",
    "parentId": "122440379",
    "title": "Punktum",
    "guidance": "",
    "icon": "",
    "published": "TRUE"
  },
  {
    "level": ">>>",
    "categoryId": "122440382",
    "parentId": "122440379",
    "title": "Komma",
    "guidance": "",
    "icon": "",
    "published": "TRUE"
  },
  {
    "level": ">>>",
    "categoryId": "122440386",
    "parentId": "122440379",
    "title": "Spørsmålstegn",
    "guidance": "",
    "icon": "",
    "published": "TRUE"
  },
  {
    "level": ">>>",
    "categoryId": "122440410",
    "parentId": "122440379",
    "title": "Utropstegn",
    "guidance": "",
    "icon": "",
    "published": "TRUE"
  },
  {
    "level": ">>>",
    "categoryId": "122440411",
    "parentId": "122440379",
    "title": "Kolon",
    "guidance": "",
    "icon": "",
    "published": "TRUE"
  },
  {
    "level": ">>",
    "categoryId": "122440412",
    "parentId": "122439780",
    "title": "Tall og telling",
    "guidance": "I denne delen kan elevene arbeide med tall og grunnleggende telling. Gjennom oppgaver, tallbøker og *Dagens tall* kan elevene arbeide med grunntall. I tallbøkene presenteres tallene 1-10 og 11-20 i ulike formater og med billedlige representasjoner, og i *Dagens tall* utforsker elevene tallene 1-10. Gjennom oppgaver kan elevene bruke ordenstall.",
    "icon": TopicIcon.Tall_og_telling,
    "published": "TRUE"
  },
  {
    "level": ">>>",
    "categoryId": "122440414",
    "parentId": "122440412",
    "title": "Grunntall",
    "guidance": "",
    "icon": "",
    "published": "TRUE"
  },
  {
    "level": ">>>",
    "categoryId": "122440415",
    "parentId": "122440412",
    "title": "Ordenstall",
    "guidance": "",
    "icon": "",
    "published": "TRUE"
  },
  {
    "level": ">",
    "categoryId": "122439782",
    "parentId": "122439758",
    "title": "Språket i bruk",
    "guidance": `
I denne delen kan elevene ta muntlig og skriftlig språk i bruk. Her kan alle finne mulighet til å uttrykke seg og formidle sine tanker, ideer, meninger og fantasi gjennom ulike aktiviteter, oppgaver og undervisningsopplegg. Delen fordyper seg også i språkutvikling gjennom tekster knyttet til tverrfaglige temaer og oppgaver som utforsker språket.

Trykk på kategoriene under for tips til hvordan du og elevene kan arbeide med språkutvikling og formidling.`,
    "icon": TopicIcon.Delta,
    "published": "TRUE",
    themeName: "delta",
  },
  {
    "level": ">>",
    "categoryId": "122440416",
    "parentId": "122439782",
    "title": "Reflekter og forklar",
    "guidance": "Her finner du oppgaver der elevene kan øve seg på å forklare egne meninger, perspektiver og forståelse gjennom fantasifulle aktiviteter. Svarene er ikke alltid gitt, for målet med øvelsene er ofte å øve seg på å uttrykke meninger og egen forståelse. At elevene får ulike svar, åpner for samtale om at man kan ha ulik forståelse eller perspektiver, og refleksjon over hvordan dette kan møtes på en god og respektfull måte. Under Aktiviteter finnes flere leker hvor ulike perspektiver vil komme frem og åpne for samtale.",
    "icon": TopicIcon.Reflekter_og_forklar,
    "published": "TRUE"
  },
  {
    "level": ">>",
    "categoryId": "122440418",
    "parentId": "122439782",
    "title": "Bruk fantasien",
    "guidance": "Her finner du en rekke åpne oppgaver hvor elevene kan utfolde seg muntlig, skriftlig og visuelt, og på den måten fortelle historier, forklare oppfatninger og uttrykke seg. Oppgavene kan ofte løses på ulikt vis, og svarene formidles gjennom lyd, tekst og/eller bilder – alt etter hva som er hensiktsmessig for den enkelte elev. Åpne oppgaver uten fasitsvar, og som kan løses gjennom ulike formidlingsmåter, lar flere elever oppleve mestring.",
    "icon": TopicIcon.Bruk_fantasien,
    "published": "TRUE"
  },
  {
    "level": ">>",
    "categoryId": "122440419",
    "parentId": "122439782",
    "title": "Lek med ord",
    "guidance": "Her finner du oppgaver der elevene leker med sammensatte ord, lydrette ord og ikke-lydrette ord. Noen av oppgavene inneholder tulleord. Å leke med ord lar elevene utforske ulike deler av språket. Under Aktiviteter finner du flere opplegg for å leke med ord.",
    "icon": TopicIcon.Lek_med_ord,
    "published": "TRUE"
  },
  {
    "level": ">>",
    "categoryId": "122440420",
    "parentId": "122439782",
    "title": "Les og lær",
    "guidance": "Her finner du tekster der elevene presenteres for ulike tverrfaglige temaer, eller temaer knyttet til andre fag – som samfunnsfag og naturfag. Gjennom tekstene blir elevene presentert for en rekke relevante begreper, uttrykk og ord forbundet med temaene. Les tekstene sammen med elevene og reflekter over spørsmålene underveis. Snakk om begrepene og la elevene ta dem i bruk selv. Et godt ordforråd er en forutsetning for å forstå og nyttiggjøre seg fagstoff, samt bruke det selvstendig i nye sammenhenger.",
    "icon": TopicIcon.Les_og_laer,
    "published": "TRUE"
  },
  {
    "level": ">>",
    "categoryId": "122440421",
    "parentId": "122439782",
    "title": "Skrive\u00ADrammer",
    "guidance": "Å uttrykke seg skriftlig er en grunnleggende ferdighet elevene utvikler under veiledning og støtte. Her finnes skriverammer for stadig mer komplekse tekster, som støtter elevene i å planlegge, skrive og bearbeide tekster. Gå gjerne gjennom presentasjonene felles i klassen, og snakk om det som blir presentert. La deretter elevene prøve selv. Skriverammer kan gjøre det lettere å komme i gang med skrivingen og være til hjelp i planleggingsfasen av teksten.",
    "icon": TopicIcon.Skriverammer,
    "published": "TRUE"
  },
]

const topics__no____3__: Topic[] = [
  {
    "level": ">",
    "categoryId": "122439762",
    "parentId": "122439758",
    "title": "Avkoding",
    "guidance": `
    I denne delen er det fokus på grunnleggjande skrive- og leseopplæring, og oppgåvene står i stigande vanskegrad. Her utforskar elevane lydar for å utvikle fonologisk og fonemisk medvit. Dei arbeidar med formell og funksjonell bokstavkompetanse og utviklar fonologiske strategiar. 

    Trykk på kategoriane under for tips til korleis du og elevane dine kan jobbe dykk fram mot fonologisk avkoding.`,
    "icon": TopicIcon.Alfa,
    "published": "TRUE",
    themeName: "alfa",
  },
  {
    "level": ">>",
    "categoryId": "122439773",
    "parentId": "122439762",
    "title": "Rim",
    "guidance": `
    Start gjerne med rim og rimord for å la elevane leike med lyd og rytme i språket. Les høgt for elevane, og lytt ut lydar saman med dei. Elevane kan og pare rimord gjennom oppgåvene. Rimmedvit er viktig for å utvikle fonologisk medvit.

*Tips: Du finn fleire rim, regler, songar og leikar knytta til dette på «Biblioteket» og «Scena», og under «Aktivitetar».*`,
    "icon": TopicIcon.Bok1,
    "published": "TRUE"
  },
  {
    "level": ">>>",
    "categoryId": "122439774",
    "parentId": "122439773",
    "title": "Rim",
    "guidance": "",
    "icon": "",
    "published": "TRUE"
  },
  {
    "level": ">>>",
    "categoryId": "122439777",
    "parentId": "122439773",
    "title": "Rimord",
    "guidance": ``,
    "icon": "",
    "published": "TRUE"
  },
  {
    "level": ">>",
    "categoryId": "122439783",
    "parentId": "122439762",
    "title": "Bokstavlyd",
    "guidance": `
    Å lytte ut lydar, samt å finne førstelyd og sistelyd, hjelper elevane å utvikle fonemisk medvit. Her finn du leikne oppgåver og myldrebilete med fokus på lydar og korleis dei koblast til bokstavar. Gjennom dei 29 bokstavbøkene utviklast formell og funksjonell bokstavkompetanse, i tillegg til fonemisk medvit.
        
    Lat og elevane utforske og oppdage samanhengen mellom lyd og bokstav gjennom oppdagande skriving, som det finst fleire opplegg for under *Aktivitetar*. Gjennom slike opplegg utviklar elevane funksjonell bokstavkompetanse. Ved å arbeide med fleire ulike metodar, kan elevane utvikle automatisert lyd-bokstav-kopling.`,
    "icon": TopicIcon.Bok2,
    "published": "TRUE"
  },
  {
    "level": ">>>",
    "categoryId": "122440260",
    "parentId": "122439783",
    "title": "Bokstavbøker",
    "guidance": "",
    "icon": "",
    "published": "TRUE"
  },
  {
    "level": ">>>",
    "categoryId": "122440261",
    "parentId": "122439783",
    "title": "Myldrebilete",
    "guidance": "",
    "icon": "",
    "published": "TRUE"
  },
  {
    "level": ">>>",
    "categoryId": "122440262",
    "parentId": "122439783",
    "title": "Første lyd",
    "guidance": "",
    "icon": "",
    "published": "TRUE"
  },
  {
    "level": ">>>",
    "categoryId": "122440263",
    "parentId": "122439783",
    "title": "Siste lyd",
    "guidance": "",
    "icon": "",
    "published": "TRUE"
  },
  {
    "level": ">>",
    "categoryId": "122440265",
    "parentId": "122439762",
    "title": "Bokstav\u00ADsekvens",
    "guidance": `På vegen mot fonologisk avkoding vil elevane etter å ha fokusert på enkeltlydar, ha behov for å utforske samansette lydar. Her finst det korte og morosame tekstar og rim med tilhøyrande oppgåver der elevane arbeidar med høgfrekvente bokstavsekvensar, som endingar og byrjingar i ord, samt diftongar, sj-lyd, kj-lyd og ng-lyd. Les gjerne tekstane for eller saman med elevane og reflekter saman om samanhengen mellom lyd og bokstavsekvens.`,
    "icon": TopicIcon.Bok3,
    "published": "TRUE"
  },
  {
    "level": ">>>",
    "categoryId": "122440266",
    "parentId": "122440265",
    "title": "Diftongar",
    "guidance": "",
    "icon": "",
    "published": "TRUE"
  },
  {
    "level": ">>>",
    "categoryId": "122440267",
    "parentId": "122440265",
    "title": "Høgfrekvente byrjingar ",
    "guidance": "",
    "icon": "",
    "published": "TRUE"
  },
  {
    "level": ">>>",
    "categoryId": "122440268",
    "parentId": "122440265",
    "title": "Høgfrekvente endingar",
    "guidance": "",
    "icon": "",
    "published": "TRUE"
  },
  {
    "level": ">>>",
    "categoryId": "122440269",
    "parentId": "122440265",
    "title": "Samansette grafem",
    "guidance": "",
    "icon": "",
    "published": "TRUE"
  },
  {
    "level": ">>",
    "categoryId": "122440291",
    "parentId": "122439762",
    "title": "Staving",
    "guidance": `
Her kan elevane arbeide med stavingar. Dei kan sjølv lese stavingane i orda, eller lytte dei ut. Oppgåvene gjev trening i å lese staving for staving, og på den måten auke lesehastigheita. Lesing av stavingar avlastar og minnet fordi det blir færre einingar som skal trekkjast saman. Oppgåvene varierar frå enkle ord med to stavingar til ord med fleire stavingar.

Denne delen kan arbeidast med parallelt med delen Bokstavsekvens, då nokre oppgåver tek for seg enkle ord, stavingar og lydar, og andre meir samansette. Tildel oppgåver tilpassa den enkelte elev si lese- og skriveutvikling.`,
    "icon": TopicIcon.Bok4,
    "published": "TRUE"
  },
  {
    "level": ">",
    "categoryId": "122439779",
    "parentId": "122439758",
    "title": "Forståing",
    "guidance": `
Parallelt som elevane trenar ordavkoding, er det viktig å jobbe med forståing.

For elevar som kan mange omgrep, vil vegen til forståing naturleg nok vere kortare. På Alfabetplaneten har vi laga mange aktivitetar, tekstar og oppgåver med spesielt fokus på språkutvikling. Aktivitetane finn du på læraren si forside under *Aktivitetar*-knappen.

Alle aktivitetane er laga med utgangspunkt i interessene og personlegdomane til innbyggarane på Alfabetplaneten. Dei blir de kjend med gjennom alle disse forteljingane. Historiene er visualisert og innlesen med ulike stemmer og dialektar. Til slutt i kvar forteljing finst ei side med spørsmål til samtale og refleksjon. Du vel korleis du vil bruke disse med elevane dine, enten de tek dei i heil klasse, lar elevane jobbe parvis eller individuelt. Alle forteljingane er laga med innhald frå Alfabetplaneten i Cartoonist, så dette innhaldet finn elevane igjen inne i verktøyet.

Kvar forteljing har tre tilhøyrande oppgåver:
- Forteljinga i tekstutgåve
- Ei oppgåve som jobbar konkret med noko i forteljinga 
- Ei oppgåve som tek bodskapen eller temaet frå forteljinga inn i elevane sitt eige liv. 

Alle oppgåvene har ein oppgåvetekst som fortel korleis oppgåva er tenkt å brukast.`,
    "icon": TopicIcon.Bravo,
    "published": "TRUE",
    themeName: "bravo",
  },
  {
    "level": ">>",
    "categoryId": "122440443",
    "parentId": "122439779",
    "title": "Forteljingar",
    "guidance": "",
    "icon": "",
    "published": "TRUE",
  },
  {
    "level": ">",
    "categoryId": "122439780",
    "parentId": "122439758",
    "title": "System i språket",
    "guidance": `
    I denne delen er det fokus på reglar og system i språket. Gjennom presentasjonar og samtalar kan elevane gjerast medviten om reglar for rettskriving, grammatikk, teiknsetting samt bruk av tal. Gjennom aktivitetar og oppgåver kan dei øve inn reglane.

Trykk på kategoriane under for tips til korleis du og elevane dine kan arbeide med system i språket.`,
    "icon": TopicIcon.Charlie,
    "published": "TRUE",
    themeName: "charlie"
  },
  {
    "level": ">>",
    "categoryId": "122440300",
    "parentId": "122439780",
    "title": "Rett\u00ADskriving",
    "guidance": "I denne delen arbeidar elevane både med rettskrivingsreglar (som o-lyd og å-lyd, kj-lyd og sj-lyd) og morfem. Sjølv om ortografisk skriving ofte først og fremst lærast ved at elevar får mykje erfaring med korrekt skriftspråk gjennom lesing og skriving, er det nyttig å gjere elevane medvitne om visse rettskrivingsreglar. Å arbeide med morfem, rotmorfem og meiningsberande morfem støttar både skrive- og leseutvikling hos elevane. Norsk har mange samansette ord, og øving av strategiar som gjer elevar i stand til å dele opp lange ord i mindre delar, gjer at dei raskare kan forstå tydinga av orda.",
    "icon": TopicIcon.Rettskriving,
    "published": "TRUE"
  },
  {
    "level": ">>>",
    "categoryId": "122440301",
    "parentId": "122440300",
    "title": "O, U og Å",
    "guidance": "",
    "icon": "",
    "published": "TRUE"
  },
  {
    "level": ">>>",
    "categoryId": "122440304",
    "parentId": "122440300",
    "title": "Samansette grafem",
    "guidance": "",
    "icon": "",
    "published": "TRUE"
  },
  {
    "level": ">>>",
    "categoryId": "122440306",
    "parentId": "122440300",
    "title": "Samansette ord",
    "guidance": "",
    "icon": "",
    "published": "TRUE"
  },
  {
    "level": ">>",
    "categoryId": "122440307",
    "parentId": "122439780",
    "title": "Grammatikk",
    "guidance": "Her finn du presentasjonar og oppgåver knytt til grammatiske system og reglar. Å bli medviten på meiningsberande morfem og bøyingsmorfem, er viktig. Etter kvart som fleire morfem kjennast att av elevane, aukar lesehastigheita og leseforståinga. Reflekter gjerne saman over bøyingsmønster. Små endringar av ord kan forandre orda si tyding, og endringane er viktige for lesing, skriving og tekstforståing.",
    "icon": TopicIcon.Grammatikk,
    "published": "TRUE"
  },
  {
    "level": ">>>",
    "categoryId": "122440310",
    "parentId": "122440307",
    "title": "Verb",
    "guidance": "",
    "icon": "",
    "published": "TRUE"
  },
  {
    "level": ">>>",
    "categoryId": "122440311",
    "parentId": "122440307",
    "title": "Substantiv",
    "guidance": "",
    "icon": "",
    "published": "TRUE"
  },
  {
    "level": ">>>",
    "categoryId": "122440312",
    "parentId": "122440307",
    "title": "Adjektiv",
    "guidance": "",
    "icon": "",
    "published": "TRUE"
  },
  {
    "level": ">>>",
    "categoryId": "122440313",
    "parentId": "122440307",
    "title": "Preposisjonar",
    "guidance": "",
    "icon": "",
    "published": "TRUE"
  },
  {
    "level": ">>",
    "categoryId": "122440412",
    "parentId": "122439780",
    "title": "Tal og teljing",
    "guidance": "I denne delen kan elevane arbeide med tal og grunnleggjande teljing. Gjennom oppgåver, talbøker og *Dagens tal* kan elevane arbeide med grunntal. I talbøkene blir tala 1-10 og 11-20 presentert i ulike format og med biletlege representasjonar, og i *Dagens tal* utforskar elevane tala 1-10. Gjennom oppgåver kan elevane bruke ordenstal.",
    "icon": TopicIcon.Tall_og_telling,
    "published": "TRUE"
  },
  {
    "level": ">>>",
    "categoryId": "122440414",
    "parentId": "122440412",
    "title": "Grunntal",
    "guidance": "",
    "icon": "",
    "published": "TRUE"
  },
  {
    "level": ">>>",
    "categoryId": "122440415",
    "parentId": "122440412",
    "title": "Ordenstal",
    "guidance": "",
    "icon": "",
    "published": "TRUE"
  },
  {
    "level": ">",
    "categoryId": "122439782",
    "parentId": "122439758",
    "title": "Språket i bruk",
    "guidance": `
I denne delen kan elevane ta munnleg og skriftleg språk i bruk. Her kan alle finne moglegheita til å uttrykke seg og formidle sine tankar, idear, meiningar og fantasi gjennom ulike aktivitetar, oppgåver og undervisningsopplegg. Delen fordjuper seg og i språkutvikling gjennom tekstar knytta til tverrfaglege tema og oppgåver som utforskar språket.

Trykk på kategoriane under for tips til korleis du og elevane kan arbeide med språkutvikling og formidling.`,
    "icon": TopicIcon.Delta,
    "published": "TRUE",
    themeName: "delta"
  },
  {
    "level": ">>",
    "categoryId": "122440416",
    "parentId": "122439782",
    "title": "Reflekter og forklar",
    "guidance": "Her finn du oppgåver der elevane kan øve seg på å forklare eigne meiningar, perspektiv og forståing gjennom fantasifulle aktivitetar. Svara er ikkje alltid gitt, for målet med øvingane er ofte å øve seg på å uttrykke meiningar og eiga forståing. At elevane får ulike svar, opnar for samtale om at ein kan ha ulik forståing eller perspektiv, og refleksjon over korleis dette kan møtast på ein god og respektfull måte. Under Aktivitetar finst fleire leikar kor ulike perspektiv vil kome fram og opne for samtale.",
    "icon": TopicIcon.Reflekter_og_forklar,
    "published": "TRUE"
  },
  {
    "level": ">>",
    "categoryId": "122440418",
    "parentId": "122439782",
    "title": "Bruk fantasien",
    "guidance": "Her finn du ei rekke opne oppgåver kor elevane kan utfalde seg munnleg, skriftleg og visuelt, og på den måten fortelje historier, forklare oppfatningar og uttrykke seg. Oppgåvene kan ofte løysast på ulikt vis, og svara formidlast gjennom lyd, tekst og/eller bilete – alt etter kva som er hensiktsmessig for den enkelte elev. Opne oppgåver utan fasitsvar, og som kan løysast gjennom ulike formidlingsmåtar, lar fleire elevar oppleve meistring.",
    "icon": TopicIcon.Bruk_fantasien,
    "published": "TRUE"
  },
  {
    "level": ">>",
    "categoryId": "122440419",
    "parentId": "122439782",
    "title": "Leik med ord",
    "guidance": "Her finn du oppgåver der elevane leiker med samansette ord, lydrette ord og ikkje-lydrette ord. Nokre av oppgåvene inneheld tulleord. Å leike med ord lar elevane utforske ulike delar av språket. Under Aktivitetar finn du fleire opplegg for å leike med ord.",
    "icon": TopicIcon.Lek_med_ord,
    "published": "TRUE"
  },
  {
    "level": ">>",
    "categoryId": "122440420",
    "parentId": "122439782",
    "title": "Les og lær",
    "guidance": "Her finn du tekstar der elevane blir presentert for ulike tverrfaglege tema, eller tema knytta til andre fag – som samfunnsfag og naturfag. Gjennom tekstane blir elevane presentert for ei rekke relevante omgrep, uttrykk og ord bunden til temaa. Les tekstane saman med elevane og reflekter over spørsmåla undervegs. Snakk om omgrepa og la elevane ta dei i bruk sjølv. Eit godt ordforråd er ein føresetnad for å forstå og nyttiggjere seg fagstoff, samt bruke det sjølvstendig i nye samanhenger.",
    "icon": TopicIcon.Les_og_laer,
    "published": "TRUE"
  },
  {
    "level": ">>",
    "categoryId": "122440421",
    "parentId": "122439782",
    "title": "Skrive\u00ADrammer",
    "guidance": "Å uttrykke seg skriftleg er ei grunnleggjande ferdigheit elevane utviklar under rettleiing og støtte. Her finst skriverammer for stadig meir komplekse tekstar, som støttar elevane i å planleggje, skrive og omarbeide tekstar. Gå gjerne gjennom presentasjonane felles i klassen, og snakk om det som blir presentert. Lat deretter elevane prøve sjølv. Skriverammer kan gjere det lettare å kome i gong med skrivinga og vere til hjelp i planleggingsfasen av teksten.",
    "icon": TopicIcon.Skriverammer,
    "published": "TRUE"
  },
]

const topics__se____8__: Topic[] = [
  {
    "level": ">",
    "categoryId": "129306004",
    "parentId": "129305286",
    "title": "Avkodning",
    "guidance": `
    Den här delen fokuserar på grundläggande läs- och skrivinlärning, uppgifterna finns i stigande svårighetsgrad. Eleverna utforskar ljud för att utveckla fonologisk och fonemisk medvetenhet. Uppdragen fokuserar på formell och funktionell bokstavskunskap och utvecklar fonologiska strategier.

    Klicka på kategorierna nedan för tips om hur du och dina elever kan arbeta mot fonologisk avkodning.`,
    "icon": TopicIcon.Alfa,
    "published": "TRUE",
    themeName: "alfa",
  },
  {
    "level": ">>",
    "categoryId": "129309668",
    "parentId": "129306004",
    "title": "Rim",
    "guidance": `
    Börja gärna med rim och rimord för att bjuda in till lek med ljuden och rytmen i språket. Läs högt för klassen och lyssna på ljud tillsammans. Eleverna kan även para ihop rimord i uppgifterna. Att kunna identifiera ord som rimmar är viktigt för att utveckla fonologisk medvetenhet.

*Tips: Du hittar fler rim, ramsor, sånger och lekar relaterade till detta på **Biblioteket** och **Scenen**, och under **Aktiviteter**.*`,
    "icon": TopicIcon.Bok1,
    "published": "TRUE",
    themeName: "alfa",
  },
  {
    "level": ">>>",
    "categoryId": "129310540",
    "parentId": "129309668",
    "title": "Rim",
    "guidance": "",
    "icon": "",
    "published": "TRUE"
  },
  {
    "level": ">>>",
    "categoryId": "129310702",
    "parentId": "129309668",
    "title": "Rimord",
    "guidance": ``,
    "icon": "",
    "published": "TRUE"
  },
  {
    "level": ">>",
    "categoryId": "129310783",
    "parentId": "129306004",
    "title": "Bokstavsljud",
    "guidance": `
    Att lyssna på ljud, och bland annat genom aktiviteter som att hitta första och sista ljudet, hjälper eleverna att utveckla fonemisk medvetenhet. Här hittar du många lekfulla uppgifter och myllerbilder med fokus på ljud och hur de kopplas till bokstäver. Genom de 29 bokstavsböckerna utvecklas både formell och funktionell bokstavskunskap samt fonemisk medvetenhet.
        
    Låt också eleverna utforska och upptäcka sambandet mellan ljud och bokstav genom skrivandet, det finns flera olika möjligheter för det under Aktiviteter. Eleverna får förutsättningar att utveckla funktionell bokstavskunskap. Genom kontinuerlig ljud- bokstavsträning automatiseras slutligen avkodningen.`,
    "icon": TopicIcon.Bok2,
    "published": "TRUE"
  },
  {
    "level": ">>>",
    "categoryId": "129310835",
    "parentId": "129310783",
    "title": "Bokstavsböcker",
    "guidance": "",
    "icon": "",
    "published": "TRUE"
  },
  {
    "level": ">>>",
    "categoryId": "129310850",
    "parentId": "129310783",
    "title": "Myllerbilder",
    "guidance": "",
    "icon": "",
    "published": "TRUE"
  },
  {
    "level": ">>>",
    "categoryId": "129310882",
    "parentId": "129310783",
    "title": "Första ljud",
    "guidance": "",
    "icon": "",
    "published": "TRUE"
  },
  {
    "level": ">>>",
    "categoryId": "129310902",
    "parentId": "129310783",
    "title": "Sista ljud",
    "guidance": "",
    "icon": "",
    "published": "TRUE"
  },
  {
    "level": ">>",
    "categoryId": "129311450",
    "parentId": "129306004",
    "title": "Bokstavs\u00ADsekvenser",
    "guidance": `På vägen mot fonologisk avkodning kommer eleverna, efter att ha fokuserat på enskilda ljud, gå vidare och träna på flera ljudkombinationer. Här finns korta och roliga texter och rim med tillhörande uppgifter där eleverna arbetar med bokstavssekvenser. Läs gärna texterna för eleverna och reflektera tillsammans om sambandet mellan ljud och bokstavssekvens.`,
    "icon": TopicIcon.Bok3,
    "published": "TRUE"
  },
  {
    "level": ">>>",
    "categoryId": "129318649",
    "parentId": "129311450",
    "title": "Kluster",
    "guidance": "",
    "icon": "",
    "published": "TRUE"
  },
  {
    "level": ">>>",
    "categoryId": "129455137",
    "parentId": "129311450",
    "title": "Sammansatta grafem",
    "guidance": "",
    "icon": "",
    "published": "TRUE"
  },
  {
    "level": ">>",
    "categoryId": "129311834",
    "parentId": "129306004",
    "title": "Stavelser",
    "guidance": `
    Här kan eleverna arbeta med stavelser. De kan själva läsa stavelse för stavelse i orden eller lyssna på dem. Uppgifterna ger träning i att läsa stavelse för stavelse och på så sätt öka läshastigheten. Att lära sig känna igen stavelser avlastar också minnet eftersom det blir färre enheter att bearbeta. 

Den här delen går utmärkt att arbeta med parallellt tillsammans med Bokstavssekvenser, då vissa uppgifter behandlar enkla ord, stavelser och ljud, medan andra är mer sammansatta. Tilldela den enskilda eleven uppgifter som är anpassade till dess läs- och skrivutvecklingsnivå.
    `,
    "icon": TopicIcon.Bok4,
    "published": "TRUE"
  },
  {
    "level": ">",
    "categoryId": "129319026",
    "parentId": "129305286",
    "title": "Förståelse",
    "guidance": `
 När eleverna tränar avkodning, är det viktigt att samtidigt arbeta med förståelsen. För elever som har ett stort ordförråd kommer vägen till förståelse naturligtvis vara kortare. På Alfabetsplaneten har vi skapat många olika aktiviteter, texter och uppgifter med särskilt fokus på språkutveckling. Aktiviteterna hittar du på lärarens startsida under ikonen Aktiviteter.
 Alla aktiviteter är utformade med hänsyn till intresse och personlighet hos invånarna på Alfabetsplaneten. Dessa lär ni känna genom berättelserna. Historierna är illustrerade och inspelade med olika röster och dialekter. Varje berättelse avslutas med frågor för samtal och reflektion. Du väljer hur du vill använda frågorna med dina elever, antingen diskuterar ni dem i helklass, låter eleverna arbeta parvis eller individuellt. Alla berättelser är skapade med innehåll från Alfabetsplaneten i Cartoonist.
    
Varje berättelse har tre tillhörande uppgifter:    
- Berättelsen i textutgåva.
- En uppgift som konkret arbetar med innehållet i berättelsen. 
- En uppgift som använder budskapet eller temat från berättelsen och kopplar det till elevernas eget liv. 

Alla uppgifter har en instruktionstext som berättar hur uppgiften är tänkt att genomföras.
`,
    "icon": TopicIcon.Bravo,
    "published": "TRUE",
    themeName: "bravo"
  },
  {
    "level": ">",
    "categoryId": "129319128",
    "parentId": "129305286",
    "title": "Språklig struktur",
    "guidance": `
    Den här delen fokuserar på språkliga regler och system. Genom presentationer och samtal kan eleverna bli medvetna om stavning, grammatik och interpunktion. 

    Klicka på kategorierna nedan för tips om hur du och dina elever kan arbeta med språklig struktur.
    `,
    "icon": TopicIcon.Charlie,
    "published": "TRUE",
    themeName: "charlie"
  },
  {
    "level": ">>",
    "categoryId": "129319248",
    "parentId": "129319128",
    "title": "Rättstavning",
    "guidance": "Här arbetar eleverna med sammansatta ord. Eftersom ortografisk skrivning lärs främst genom att eleverna får erfarenhet av skriftspråket genom läsning och skrivande, är det bra att medvetandegöra eleverna om vissa stavningsregler. Att arbeta med morfem, rotmorfem och meningsbärande morfem stödjer både läs- och skrivutvecklingen hos eleverna. Svenskan har många sammansatta ord, och övning av strategier som gör eleverna kapabla att dela upp långa ord i mindre delar, underlättar för dem.",
    "icon": TopicIcon.Rettskriving,
    "published": "TRUE"
  },
  {
    "level": ">>",
    "categoryId": "129319320",
    "parentId": "129319128",
    "title": "Grammatik",
    "guidance": "Här hittar du presentationer och uppgifter relaterade till grammatiska system och regler. Att vara medveten om meningsbärande morfem och böjningsmorfem är viktigt. När eleverna känner igen fler morfem ökar läshastigheten och läsförståelsen. Reflektera gärna tillsammans över böjningsmönster. Små förändringar av ord kan ändra ordens betydelse, och dessa förändringar är viktiga för läsning, skrivande och läsförståelse.",
    "icon": TopicIcon.Grammatikk,
    "published": "TRUE"
  },
  {
    "level": ">",
    "categoryId": "129319497",
    "parentId": "129305286",
    "title": "Språkbruk",
    "guidance": `
    I den här delen kan eleverna använda språket, både muntligt och skriftligt. Alla kan hitta möjligheter att uttrycka sig och förmedla sina tankar, idéer och åsikter genom olika aktiviteter, uppgifter och undervisningsupplägg. Delen fördjupar sig också i språkutveckling genom texter kopplade till ämnesövergripande teman och uppgifter som utforskar språket.

    Klicka på kategorierna nedan för tips om hur du och dina elever kan arbeta språkutvecklande.
    `,
    "icon": TopicIcon.Delta,
    "published": "TRUE",
    themeName: "delta"
  },
  {
    "level": ">>",
    "categoryId": "129319604",
    "parentId": "129319497",
    "title": "Reflektera och förklara",
    "guidance": "Här hittar du uppgifter där eleverna kan öva på att förklara sina åsikter, perspektiv och sin förståelse genom fantasifulla aktiviteter. Svaren ges inte alltid, eftersom målet med övningarna är att öva på att uttrycka åsikter och förståelse. Att eleverna får olika svar öppnar upp för samtal om att det är okej att ha olika åsikter och perspektiv. Det ger även möjlighet till att reflektera kring hur olika åsikter kan hanteras respektfullt. Under Aktiviteter finns fler lekar där olika perspektiv kommer fram och öppnar upp för samtal.",
    "icon": TopicIcon.Reflekter_og_forklar,
    "published": "TRUE"
  },
  {
    "level": ">>",
    "categoryId": "129319957",
    "parentId": "129319497",
    "title": "Använd fantasin",
    "guidance": "Här finns flera öppna uppgifter där eleverna kan uttrycka sig muntligt, skriftligt och visuellt, och på så sätt berätta historier, förklara sina åsikter m.m. Uppgifterna kan lösas på olika sätt, svaren kan kommuniceras via ljud, text och/eller bilder – allt beroende på vad som är mest lämpligt för var och en av eleverna. Öppna uppgifter utan givna svar, som kan lösas genom olika kommunikationsmetoder, ger fler elever chansen att lyckas.",
    "icon": TopicIcon.Bruk_fantasien,
    "published": "TRUE"
  },
  {
    "level": ">>",
    "categoryId": "129320085",
    "parentId": "129319497",
    "title": "Lek med ord",
    "guidance": "Här hittar du övningar där eleverna leker med sammansatta ord, ljudenligt stavade ord och ljudstridigt stavade ord. Vissa av övningarna innehåller innehållsneutrala ord. Att leka med ord låter eleverna utforska olika delar av språket. Under *Aktiviteter* finns fler upplägg för att leka med ord.",
    "icon": TopicIcon.Lek_med_ord,
    "published": "TRUE"
  },
  {
    "level": ">>",
    "categoryId": "129320204",
    "parentId": "129319497",
    "title": "Läs och lär",
    "guidance": "Här hittar du texter där eleverna presenteras för olika ämnesövergripande teman eller teman relaterade till andra ämnen såsom samhällsvetenskap och naturvetenskap. Genom texterna introduceras en mängd relevanta begrepp, uttryck och ord kopplade till teman. Läs texterna tillsammans med eleverna och reflektera över frågorna längs vägen. Diskutera begreppen och låt eleverna använda dem själva. Ett bra ordförråd är en förutsättning för att förstå och tillämpa ämnesrelaterat material samt använda det självständigt i nya sammanhang.",
    "icon": TopicIcon.Les_og_laer,
    "published": "TRUE"
  },
  {
    "level": ">>",
    "categoryId": "129320469",
    "parentId": "129319497",
    "title": "Texttyper",
    "guidance": "Att uttrycka sig skriftligt är en grundläggande färdighet som eleverna utvecklar med handledning och stöd. Här finns skrivmallar för allt mer komplexa texter, som stödjer eleverna i att planera, skriva och bearbeta texter. Gå gärna igenom presentationerna gemensamt i klassen och diskutera det som presenterats. Låt sedan eleverna själva prova på. Skrivmallar kan underlätta att komma igång med skrivandet och vara till hjälp under planeringsfasen.",
    "icon": TopicIcon.Skriverammer,
    "published": "TRUE"
  },
]

const socialCompetenceTopics__no____1__: Topic[] = [
  {
    "level": ">>",
    "categoryId": "sosial_kompetanse",
    "parentId": "",
    "title": "Sosial kompetanse",
    "icon": TopicIcon.Sosial_kompetanse,
    "published": "TRUE",
    guidance: `I læreplanen står det at skolen skal støtte og bidra til elevenes sosiale læring og utvikling. Som et ledd på veien mot sosial kompetanse, har vi valgt å fokusere litt ekstra på noen sosiale ferdigheter og knytte disse opp mot innholdet som finnes på Alfabetplaneten.

Her er det sortert slik at det skal være lett å finne fram til den sosiale ferdigheten du tenker er relevant for klassen din å jobbe med. Gjennom fortellingene, oppgavene til fortellingene og aktivitetene det er lagt opp til, møter elevene mange problemstillinger som på ulike vis kan relatere til egne liv. Sammen kan dere finne løsninger som har overføringsverdi til elevenes virkelige verden, enten på skolen eller fritiden.

På [Udirs nettsider](https://www.udir.no/laring-og-trivsel/skolemiljo/sosial-laring-gjennom-arbeid-med-fag/#a153827) finner du mer informasjon om sosial læring og utvikling, samt lenker til nyttige videosnutter. Ta gjerne en titt her, for å se hva denne delen av Alfabetplaneten har tatt utgangspunkt i.
`,
    themeName: "socialCompetence",
  },
  {
    "level": ">>>",
    "categoryId": "122614230",
    "parentId": "sosial_kompetanse",
    "title": "Empati",
    "icon": "",
    "published": "TRUE",
    guidance: `Empati danner grunnlaget for gode vennskap og kan motarbeide mobbing og utenforskap. I disse fortellingene, oppgavene og aktivitetene finner dere innhold som kan gi gode klassesamtaler, oppmuntre til refleksjon og gi elevene mulighet til å trene på å ta andre perspektiver enn sine egne.`,
  },
  {
    "level": ">>>",
    "categoryId": "122614231",
    "parentId": "sosial_kompetanse",
    "title": "Samarbeid",
    "icon": "",
    "published": "TRUE",
    guidance: `Læreplanen er helt tydelig på at alle skal lære å samarbeide og fungere godt sammen. I disse fortellingene, oppgavene og aktivitetene finner dere innhold som kan gi elevene både teoretisk og praktisk trening i samarbeid.`,
  },
  {
    "level": ">>>",
    "categoryId": "122631512",
    "parentId": "sosial_kompetanse",
    "title": "Selvhevdelse",
    "icon": "",
    "published": "TRUE",
    guidance: `Å kunne be om hjelp, å si fra på egne eller andres vegne, ta initiativ eller si meningen sin er viktige sider av selvhevdelse. I disse fortellingene, oppgavene og aktivitetene møter dere innhold som kan hjelpe elevene å utvikle mot og trygghet til å stå opp for seg selv og andre.`,
  },
  {
    "level": ">>>",
    "categoryId": "122631534",
    "parentId": "sosial_kompetanse",
    "title": "Selvkontroll",
    "icon": "",
    "published": "TRUE",
    guidance: `Å forstå andres reaksjoner og handlinger, kunne tilpasse seg et fellesskap, regulere seg selv og å være hensynsfull handler mye om å kjenne igjen og forstå egne og andres følelser. I disse fortellingene, oppgavene og aktivitetene møter dere innhold som kan hjelpe elevene å trene på å kjenne igjen situasjoner som kan bli vanskelige, og å bli mer bevisste på utfall av egne handlinger.`,
  },
  {
    "level": ">>>",
    "categoryId": "122631551",
    "parentId": "sosial_kompetanse",
    "title": "Ansvarlighet",
    "icon": "",
    "published": "TRUE",
    guidance: `For å utvikle ansvarlighet er det helt essensielt med tillit, samtidig som tillit ofte er noe man får av å vise ansvarlighet. For å få et godt klassemiljø er det viktig med respekt for andres ting, og at alle bidrar til fellesskapet det man kan. I disse fortellingene, oppgavene og aktivitetene finner dere innhold som kan gi gode refleksjoner og samtaler rundt ansvarlighet.`,
  },
  {
    "level": ">>>",
    "categoryId": "122631568",
    "parentId": "sosial_kompetanse",
    "title": "Selvfølelse",
    "icon": "",
    "published": "TRUE",
    guidance: `Å føle at man er verdifull, akseptert og har gode tanker om seg selv er viktig for å utvikle en positiv selvfølelse. I disse fortellingene, oppgavene og aktivitetene finner dere innhold som kan brukes for å bli mer bevisst på sine sterke sider og få mer tro på seg selv.`,
  },
]

const socialCompetenceTopics__no____3__: Topic[] = [
  {
    "level": ">>",
    "categoryId": "sosial_kompetanse",
    "parentId": "",
    "title": "Sosial kompetanse",
    "icon": TopicIcon.Sosial_kompetanse,
    "published": "TRUE",
    guidance: `I læreplanen står det at skulen skal støtte og bidra til elevane si sosiale læring og utvikling. Som eit ledd på vegen mot sosial kompetanse, har vi vald å fokusere litt ekstra på nokre sosiale ferdigheiter og knytte disse opp mot innhaldet som finst på Alfabetplaneten.

Her er det sortert slik at det skal vere lett å finne fram til den sosiale ferdigheita du tenkjer er relevant for klassen din å jobbe med. Gjennom forteljingane, oppgåvene til forteljingane og aktivitetane det er lagt opp til, møter elevane mange problemstillingar som på ulike vis kan relatere til eigne liv. Saman kan de finne løysingar som har overføringsverdi til elevane si verkelege verd, anten på skulen eller fritida.

På [Udirs nettsider](https://www.udir.no/laring-og-trivsel/skolemiljo/sosial-laring-gjennom-arbeid-med-fag/#a153827) finn du meir informasjon om sosial læring og utvikling, samt lenkjer til nyttige videosnuttar. Ta gjerne ein titt her, for å sjå kva denne delen av Alfabetplaneten har teke utgangspunkt i.
`,
    themeName: "socialCompetence",
  },
  {
    "level": ">>>",
    "categoryId": "122614230",
    "parentId": "sosial_kompetanse",
    "title": "Empati",
    "icon": "",
    "published": "TRUE",
    guidance: `Empati dannar grunnlaget for gode venskap og kan motarbeide mobbing og utanforskap. I disse forteljingane, oppgåvene og aktivitetane finn de innhald som kan gje gode klassesamtalar, oppmuntre til refleksjon og gje elevane moglegheit til å trene på å ta andre perspektiv enn sine eigne.`,
  },
  {
    "level": ">>>",
    "categoryId": "122614231",
    "parentId": "sosial_kompetanse",
    "title": "Samarbeid",
    "icon": "",
    "published": "TRUE",
    guidance: `Læreplanen er heilt tydeleg på at alle skal lære å samarbeide og fungere godt saman. I disse forteljingane, oppgåvene og aktivitetane finn de innhald som kan gje elevae både teoretisk og praktisk trening i samarbeid.`,
  },
  {
    "level": ">>>",
    "categoryId": "122631512",
    "parentId": "sosial_kompetanse",
    "title": "Sjølvhevding",
    "icon": "",
    "published": "TRUE",
    guidance: `Å kunne be om hjelp, å seie frå på eigne eller andre sine vegner, ta initiativ eller seie meininga si er viktige sider av sjølvhevding. I disse forteljingane, oppgåvene og aktivitetane møter de innhald som kan hjelpe elevane å utvikle mot og tryggleik til å stå opp for seg sjølv og andre.`,
  },
  {
    "level": ">>>",
    "categoryId": "122631534",
    "parentId": "sosial_kompetanse",
    "title": "Sjølvkontroll",
    "icon": "",
    "published": "TRUE",
    guidance: `Å forstå andre sine reaksjonar og handlinger, kunne tilpasse seg eit fellesskap, regulere seg sjølv og å vere omsynsfull handlar mykje om å kjenne igjen og forstå eigne og andre sine kjensler. I disse forteljingane, oppgåvene og aktivitetane møter de innhald som kan hjelpe elevane å trene på å kjenne igjen situasjonar som kan bli vanskelege, og å bli meir medvetne på utfall av eigne handlinger.`,
  },
  {
    "level": ">>>",
    "categoryId": "122631551",
    "parentId": "sosial_kompetanse",
    "title": "Ansvarskjensle",
    "icon": "",
    "published": "TRUE",
    guidance: `For å utvikle ansvarskjensle er det heilt essensielt med tillit, samtidig som tillit ofte er noko ein får av å vise ansvarskjensle. For å få eit godt klassemiljø er det viktig med respekt for andre sine ting, og at alle bidreg til fellesskapet det ein kan. I disse forteljingane, oppgåvene og aktivitetane finn de innhald som kan gje gode refleksjonar og samtalar rundt ansvarskjensle.`,
  },
  {
    "level": ">>>",
    "categoryId": "122631568",
    "parentId": "sosial_kompetanse",
    "title": "Sjølvkjensle",
    "icon": "",
    "published": "TRUE",
    guidance: `Å føle at ein er verdifull, akseptert og har gode tankar om seg sjølv er viktig for å utvikle ei positiv sjølvkjensle. I disse forteljingane, oppgåvene og aktivitetane finn de innhald som kan brukast for å bli meir medviten på sine sterke sider og få meir tru på seg sjølv.`,
  },
]
const socialCompetenceTopics__se____8__: Topic[] = [
  {
    "level": ">>",
    "categoryId": "sosial_kompetanse",
    "parentId": "",
    "title": "Social kompetens",
    "icon": TopicIcon.Sosial_kompetanse,
    "published": "TRUE",
    guidance: `I LGR 22 står det att “skolan ska sträva efter att vara en levande social gemenskap som ger trygghet och vilja och lust att lära". Som en del av vägen mot social kompetens har vi valt att fokusera lite extra på vissa sociala färdigheter och koppla dessa till innehållet på Alfabetsplanetens.

    Här är det sorterat för att det ska vara lätt att hitta den sociala kompetens du anser är relevant för din klass att arbeta med. Genom berättelserna, uppgifterna till berättelserna och de aktiviteter som planeras, möter eleverna många situationer som på olika sätt kan relatera till deras egna liv. 
    `,
    themeName: "socialCompetence",
  },
  {
    "level": ">>>",
    "categoryId": "129507332",
    "parentId": "sosial_kompetanse",
    "title": "Empati",
    "icon": "",
    "published": "TRUE",
    guidance: `Empati är grunden för goda relationer och kan motverka mobbning och utanförskap. I dessa berättelser, uppgifter och aktiviteter hittar ni innehåll som kan ge bra klassdiskussioner, uppmuntra till reflektion och ge eleverna möjlighet att träna på att se saker utifrån andra perspektiv än sina egna.`,
  },
  {
    "level": ">>>",
    "categoryId": "129507333",
    "parentId": "sosial_kompetanse",
    "title": "Samarbete",
    "icon": "",
    "published": "TRUE",
    guidance: `I LGR 22 står det att “Eleverna ska ges förutsättningar att utveckla sin förmåga att skapa och upprätthålla goda relationer samt samarbeta utifrån ett demokratiskt och empatiskt förhållningssätt". I dessa berättelser, uppgifter och aktiviteter hittar ni innehåll som kan ge eleverna både teoretisk och praktisk träning i att samarbeta.`,
  },
  {
    "level": ">>>",
    "categoryId": "129507335",
    "parentId": "sosial_kompetanse",
    "title": "Stå upp för sig själv och andra",
    "icon": "",
    "published": "TRUE",
    guidance: `Att kunna be om hjälp, stå upp för sig själv eller för andra, ta initiativ eller uttrycka sin åsikt, är viktiga aspekter av att vara trygg i sig själv. I dessa berättelser, uppgifter och aktiviteter möter ni innehåll som kan hjälpa eleverna att utveckla mod och trygghet för att stå upp för sig själva och andra.`,
  },
  {
    "level": ">>>",
    "categoryId": "129507353",
    "parentId": "sosial_kompetanse",
    "title": "Affektreglering",
    "icon": "",
    "published": "TRUE",
    guidance: `Att förstå andras reaktioner och handlingar, kunna anpassa sig till en gemenskap, reglera sig själv och vara hänsynsfull, handlar mycket om att känna igen och förstå ens egna och andras känslor. I dessa berättelser, uppgifter och aktiviteter möter ni innehåll som kan hjälpa eleverna att träna på att känna igen situationer som skulle kunna bli utmanande för dem.`,
  },
  {
    "level": ">>>",
    "categoryId": "129519692",
    "parentId": "sosial_kompetanse",
    "title": "Ansvar",
    "icon": "",
    "published": "TRUE",
    guidance: `För att utveckla ansvar är det mycket viktigt med förtroende, samtidigt som förtroende ofta är något man får genom att visa ansvar. I arbetet med klassrumsmiljön är det viktigt med respekt för andras ägodelar och att alla bidrar till gemenskapen så mycket som möjligt. I dessa berättelser, uppgifter och aktiviteter finns innehåll som kan generera goda reflektioner och samtal kring hur man beter sig ansvarsfullt.`,
  },
  {
    "level": ">>>",
    "categoryId": "129519698",
    "parentId": "sosial_kompetanse",
    "title": "Självkänsla",
    "icon": "",
    "published": "TRUE",
    guidance: `Att känna sig värdefull, accepterad och ha positiva tankar om sig själv är viktigt för att utveckla en god självkänsla. I dessa berättelser, uppgifter och aktiviteter hittar ni innehåll som kan användas för att göra eleverna mer medvetna om sina styrkor och få mer tro på sig och sina förmågor.`,
  }
];

export const sangerInfoNO: string = "Her finnes mange sanger knyttet til karakterenes personlighet og interesser. Noen låter hjelper dere med hverdagsrutinene i klasserommet, noen leker med språket, og noen sanger er rett og slett mest til fornøyelse og underholdning. Bruk dem når det passer dere best – som en del av undervisningen, til ulike overganger, i samlingsstund eller til å danse og riste litt løs. Sangene finnes også på elevsidene, så de er lett tilgjengelige for elever som vil høre dem igjen og igjen og igjen.";

export const rimOgReglerInfoNO: string = "Som en delferdighet på vei mot fonologisk bevissthet trenger elevene å bli rimbevisste. Det er denne bevisstheten som gjør at elever sier at “hest” og “fest” rimer, framfor “hest” og “esel”. Rimbevissthet gjør elevene oppmerksomme på lydene i ord framfor ordets betydning, og denne bevisstheten trenger de for å lære å lese og skrive. \n" +
    "\n" +
    "I tillegg kan riming være ganske morsomt – bare ta en titt på alt det fine innholdet som er her! Gjennom Alfabetplanetens mange rim og regler kan dere leke med rare ord og vendinger gjennom søte, underholdende, morsomme og underfundige små fortellinger.";

export const sangerInfoSV: string = "Här finns flera sånger kopplade till karaktärernas personligheter och intressen. Vissa sånger hjälper er med vardagsrutiner i klassrummet, andra leker med språket, och vissa sånger är helt enkelt till för nöje och underhållning. Använd dem när det passar er bäst – som en del av undervisningen, under olika övergångar, under samlingar eller för att dansa och ha det roligt. Sångerna finns också på elevsidorna så att de är lättillgängliga för elever som vill lyssna på dem om och om igen.";

export const rimOgReglerInfoSV: string = "På väg mot fonologisk medvetenhet behöver eleverna bli medvetna om rim och ramsor. Det är denna medvetenhet som gör att eleverna säger att \"häst\" och \"fest\" rimmar, istället för \"häst\" och \"åsna\". Förmågan att rimma gör eleverna medvetna om ljuden i ord istället för ordets betydelse, och denna medvetenhet behöver de för att lära sig läsa och skriva.\n" +
    "\n" +
    "Dessutom kan rim vara roligt - ta en titt på allt det fina innehållet som finns här! Med hjälp av Alfabetsplanetens många rim och ramsor kan ni leka med konstiga ord och fraser genom underhållande, roliga och listiga små berättelser.";