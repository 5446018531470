import { FaWindowClose } from 'react-icons/fa';
import styles from './PrintDetailWindow.module.css';
import DOMPurify from 'dompurify';
import { ButtonStyle } from '../ButtonStyle/ButtonStyle';
import { PrintElement } from "../../../utils/printUtils";

interface ExerciseDetailWindowProps {
    closeWindow: () => void;
    printElement: PrintElement;
    themeColor?: string;
}

export const PrintDetailWindow = ({ closeWindow, printElement, themeColor }: ExerciseDetailWindowProps) => {
    return (
        <>
            <div className={styles['information-window']} style={{ ["--theme-color" as any]: themeColor }} >
                <div style={{ float: 'right' }}>
                    <button aria-label={"Lukk oppgaven " + printElement.title} onClick={closeWindow} style={{ fontSize: "1.25rem" }} ><FaWindowClose /></button> {/* TODO: get aira-label based on language */}
                </div>
                <div className={styles['information-window__content']} >
                    <img src={printElement.thumbnail} alt="" />
                    <div className={styles['information-window__name-and-description']}>
                        <h2 aria-live='polite' className={styles['information-window__name']}>{printElement.title}</h2>
                        <div className={styles['information-window__desc']}>
                            {(printElement && printElement.description) && printElement.description.split("\n").join(";;;").split("<br>").join(";;;").split("<br/>").join(";;;").split("<br />").join(";;;").split(";;;").map(paragraph => { // Authors love to use <br>. Turn them into pharagraphs.
                                return (
                                    paragraph.length > 0 && <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(paragraph, { ADD_ATTR: ['target'] }) }} ></p>
                                );
                            })
                            }
                        </div>
                        <div className={styles.buttons}>
                            <ButtonStyle color={themeColor || "var(--white)"}>
                                <a href={printElement.pdf} target="_blank">
                                    {'Skriv ut pdf'}
                                </a>
                            </ButtonStyle>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}