import React, {useEffect, useState} from 'react';
import PageGeneralMain from '../../PageGeneralMain';
import { Video } from '../../UIComponents/Video/Video';
import styles from './StageVideoPage.module.css';
import { BackButtonLink } from '../../UIComponents/BackButtonLink/BackButtonLink';
import Button from "../../UIComponents/Button/Button";
import {useMGMTTranslations} from "../../../utils/TranslationHooks";

interface StageVideoPageProps {
    title: string;
    src: string;
    karaoke?: string | undefined;
    lyrics?: string | undefined;
    /* breadcrumbs: Breadcrumb[]; */
}

export const StageVideoPage = ({ title, src, karaoke, lyrics /* breadcrumbs */ }: StageVideoPageProps) => {
    const [isKaraoke, setIsKaraoke] = useState(false);
    let showButtons = karaoke !== undefined;
    const toggle = () => setIsKaraoke(!isKaraoke);
    const mgmtTranslate = useMGMTTranslations();

    useEffect(() => {
         let currentSrc = isKaraoke && karaoke !== undefined ? karaoke : src;
         let video = document.getElementsByClassName('videoElement')[0];

         if (video)
             video.setAttribute("src", currentSrc);
    }, [isKaraoke]);

    function downloadLyrics() {
        window.open(lyrics);
    }

    return (
        <div>
            <img alt="" className={styles['background-image']} src='https://alphabet-planet-ui.s3.eu-west-1.amazonaws.com/images-ui/alphabet_planet_ui_page_stage_video.svg' />
            {/* <Breadcrumbs breadcrumbs={breadcrumbs} currentPageTitle={title} pushContentDown /> */}
            <PageGeneralMain>
                <BackButtonLink title={"Gå tilbake"} />
                <div className={styles['video-wrapper']}>
                    <h1 className={styles['page-title']}>{title}</h1>
                    <Video src={src} />

                    {showButtons ? <div className={styles['buttons-wrapper']}>
                        <Button onClick={toggle}>{isKaraoke ? mgmtTranslate("original_button") : mgmtTranslate("karaoke_button")}</Button>
                        <Button onClick={() => downloadLyrics()} ariaLabel={mgmtTranslate("lyrics_button_ariaLabel")}>{mgmtTranslate("lyrics_button")}</Button>
                    </div> : null}
                </div>
            </PageGeneralMain>
        </div>
    );
};