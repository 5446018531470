import React, { Children, useContext, useEffect, useRef, useState } from 'react';
import { FaExternalLinkAlt, FaWindowClose } from 'react-icons/fa';
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';
import { GiSpeaker } from 'react-icons/gi';
import { CategoryTreeNode } from "../../../utils/CategoryTreeNode";
import { Exercise, ObjectType } from '../../../utils/exerciseService';
import ExercisesQueryParameters from '../../../utils/ExercisesQueryParameters';
import { getCartoonistUrl } from '../../../utils/getCartoonistUrl';
import { getExerciseThumbnail } from '../../../utils/getExerciseThumbnail';
import { getPreviewUrl } from '../../../utils/getPreviewUrl';
import { SessionInfoNode } from '../../../utils/SessionService/SessionInfoNode';
import useFetchExercises from '../../../utils/useFetchExercises';
import CharacterWithAudio from '../../UIComponents/CharacterWithAudio';
import ExercisePresenter from '../../UIComponents/ExercisePresenter/ExercisePresenter';
import { StoryPreview } from '../../UIComponents/StoryPreview/StoryPreview';

import styles from './LetterPage.module.css';
import { BasicSessionInfoContextType, basicSessionInfoContext } from '../../../utils/BasicSessionContext';
import { PlanetContextType, planetContext } from '../../../utils/PlanetContext';
import { parseToURL } from '../../../utils/parseToURL';
import { LanguageISO639_1, getLanguage, useUserLanguage } from '../../../utils/languages';
import { BiographyLanguageContextType, biographyLanguageContext } from '../../../utils/BiographyLanguageContext';
import { useMGMTTranslations } from '../../../utils/TranslationHooks';
import { BiographyLanguageSelect } from '../../UIComponents/BiographyLanguageSelect/BiographyLanguageSelect';

interface LetterPageProps {
    selectedMapItem: CategoryTreeNode;
    handlePreviousLetter: React.MouseEventHandler | null;
    handleNextLetter: React.MouseEventHandler | null;
    closeWindow: React.ReactEventHandler;
    /*     assignExercise?: Function;
        userSession?: SessionInfoNode; */
    storiesPageTitle: string;
    storyExercises?: Exercise[];
    swedishVisitExercise?: Exercise;
    inhabitants: CategoryTreeNode[];
    norwegianVisitMeId?: string;
    norwegianStoriesId?: string;
    categories: CategoryTreeNode[];
    planetIdNode: CategoryTreeNode;
    letterPagePageNode: CategoryTreeNode;
}

const LetterPage = ({ selectedMapItem, handlePreviousLetter, handleNextLetter, closeWindow, swedishVisitExercise, storyExercises, inhabitants, storiesPageTitle, norwegianVisitMeId, norwegianStoriesId, categories, planetIdNode, letterPagePageNode }: LetterPageProps) => {
    /** Add hash to url for data gathering */
    useEffect(() => {
        window.location.href = "#" + selectedMapItem.name;
    }, [selectedMapItem]);

    const { cmsPlanetId } = useContext(planetContext) as PlanetContextType;
    const { cmsLangId } = useContext(basicSessionInfoContext) as BasicSessionInfoContextType;

    const storiesCategoryNode = categories.find(node => node.name.includes("Stories") && node.name.includes(cmsPlanetId!));

    const audio = useRef<null | HTMLAudioElement>(null);
    const letterAudioSrc = //'https://s3-eu-west-1.amazonaws.com/creaza-cartoonist-svg/alphabetplanet-ui/letter-audio/' + letter + ".mp3";
        cmsPlanetId && selectedMapItem.children.find(n => n.name === "LetterAudio")?.children.find(n => n.name.includes(cmsPlanetId))?.description;
    console.assert(letterAudioSrc, "CMS value of letterAudioSrc is missing");

    /* 
    In the array which is category.children:
    index: category name
    0: Besøk meg
    1: Fortellinger
    2: Oppgaver
    3: Leker
    4: Sang/rim/regle
    */

    /*     const visitMeId = selectedMapItem.children[0].id; // index 0: Besøk meg (Visit me)
        const storiesId = selectedMapItem.children[1].id; // index 1: Fortellinger (Stories) */
    /*const visitMeId = norwegianVisitMeId;
    const storiesId = norwegianStoriesId;
    const norwegianSelectedMapItem = categories.find(n => n.name === selectedMapItem.name[0])
     const fetchingParams: ExercisesQueryParameters = url.endsWith("no") ? { categoryId: norwegianSelectedMapItem!.id } : {};
    const { data, error, isPending } = useFetchExercises(fetchingParams, [], url.endsWith("se") ? true : false); */
    const visitMeExercise = swedishVisitExercise;

    const storiesHMTL = storiesCategoryNode?.children.map(storyNode => {
        const correspondingExercise = storyExercises?.find(ex => ex.categories.includes(storyNode.id));

        return (
            correspondingExercise &&
            <StoryPreview
                key={storyNode.id}
                /* alt={"Gå til fortellingen " + correspondingExercise.name} */ // TODO: Get value from CMS
                imgSrc={getExerciseThumbnail(correspondingExercise)}
                headline={correspondingExercise.name}
                href={storyNode.id.toString()}
            />
        )
    }).filter(el => el !== undefined);

    const firstFocus = useRef<HTMLDivElement>(null) // We use ref along with tabindex=1 on the element because we want it to be the next tabbable element when a letter is clicked (asideElement?.current?.focus();)
    firstFocus.current?.focus(); // make this element the next tabbable element

    function getLetterImgSrc(letterNode: CategoryTreeNode) {
        const baseUrl = "https://s3-eu-west-1.amazonaws.com/alphabet-planet-ui/images-letters/";
        return baseUrl + letterNode.name + ".svg";
    }

    function getLetterColor() {
        switch (selectedMapItem.name[0]) {
            case "A":
                return "#d6f190";

            case "B":
                return "#c9d3b0";

            case "C":
                return "#e2f7ae";

            case "D":
                return "#ffdca2";

            case "E":
                return "#c8dbf9";

            case "F":
                return "#ffdca2";

            case "G":
                return "#c1f3c9";

            case "H":
                return "#d6d5e7";

            case "I":
                return "#b8e9ff";

            case "J":
                return "#b9ebc5";

            case "K":
                return "#ffe0bb";

            case "L":
                return "#d6f190";

            case "M":
                return "#c8dbf9";

            case "N":
                return "#c1f3c9";

            case "O":
                return "rgb(255 244 181)";

            case "P":
                return "#e7d392";

            case "Q":
                return "#c8dbf9";

            case "R":
                return "#ceebf1";

            case "S":
                return "#b8e9ff";

            case "T":
                return "#b9ebc5";

            case "U":
                return "#c8dbf9";

            case "V":
                return "#ade2ff";

            case "W":
                return "rgb(217 217 217)";

            case "X":
                return "rgb(217 217 217)";

            case "Y":
                return "#ceebf1";

            case "Z":
                return "#d3d391";

            case "Æ":
                return "#e2f7ae";

            case "Ä":
                return "#e2f7ae";

            case "Ø":
                return "#c6e9d5";

            case "Ö":
                return "#c6e9d5";

            case "Å":
                return "#c8dbf9";

            default:
                return "#fff";
        }
    }

    const letterPageCMSValues = {
        headingTheLetter: cmsLangId && letterPagePageNode.children.find(n => n.name === "TheLetter<<A>>-heading")?.children.find(n => n.name.includes(cmsLangId))?.description.replace("<<A>>", selectedMapItem.name[0]),
        headingMeetInhabitants: cmsLangId && letterPagePageNode.children.find(n => n.name === "Meet<<Leo>>,<<Li>>and<<Lu>>-heading")?.children.find(n => n.name.includes(cmsLangId))?.description,
        headingStory: cmsLangId && letterPagePageNode.children.find(n => n.name === "Story-heading")?.children.find(n => n.name.includes(cmsLangId))?.description,
        headingStories: cmsLangId && letterPagePageNode.children.find(n => n.name === "Stories-heading")?.children.find(n => n.name.includes(cmsLangId))?.description,
        headingVisitInhabitants: cmsLangId && letterPagePageNode.children.find(n => n.name === "Visit<<Leo>>,<<Li>>and<<Lu>>-heading")?.children.find(n => n.name.includes(cmsLangId))?.description,
        OpenTheLetterInCartoonist: cmsLangId && letterPagePageNode.children.find(n => n.name === "OpenTheLetter<<A>>InCartoonist-label")?.children.find(n => n.name.includes(cmsLangId))?.description.replace("<<A>>", selectedMapItem.name[0]),
    }

    // Console assert CMS values
    Object.entries(letterPageCMSValues).forEach(entry => {
        console.assert(entry[1], "Missing value for " + entry[0])
    })

    /* Create a sentence with inhabitants */
    const andSeparator = letterPageCMSValues.headingMeetInhabitants?.slice(letterPageCMSValues.headingMeetInhabitants.indexOf("<<Li>>") + 6, letterPageCMSValues.headingMeetInhabitants.indexOf("<<Lu>>")) || " ";
    const commaSeparator = letterPageCMSValues.headingMeetInhabitants?.slice(letterPageCMSValues.headingMeetInhabitants.indexOf("<<Leo>>") + 7, letterPageCMSValues.headingMeetInhabitants.indexOf("<<Li>>")) || "";
    const meetVerb = (commaSeparator && andSeparator) && letterPageCMSValues.headingMeetInhabitants?.replaceAll(commaSeparator, "").replaceAll(andSeparator, "").replace("<<Leo>>", "").replace("<<Li>>", "").replace("<<Lu>>", "");
    const visitVerb = (commaSeparator && andSeparator) && letterPageCMSValues.headingVisitInhabitants?.replaceAll(commaSeparator, "").replaceAll(andSeparator, "").replace("<<Leo>>", "").replace("<<Li>>", "").replace("<<Lu>>", "");
    const inhabitantsListedAsSentence = inhabitants.map((inhabitant, index) => {
        const inhabitantName = inhabitant.children.find(n => n.name === "Name")?.children.find(n => n.name.includes(cmsPlanetId!))?.description || ""; // If the inhabitant name is undefined for some reason, then we prevent "undefined" from appearing in the sentence.
        if (index === inhabitants.length - 1) {
            return andSeparator + inhabitantName;
        }
        if (inhabitants.length > 2) {
            return inhabitantName + commaSeparator;
        }
        return inhabitantName;
    }).join("");

    letterPageCMSValues.headingMeetInhabitants = meetVerb && letterPageCMSValues.headingMeetInhabitants?.startsWith(meetVerb) ? meetVerb + inhabitantsListedAsSentence : inhabitantsListedAsSentence + meetVerb;
    // if (letterPageCMSValues.headingMeetInhabitants.includes("undefined")) letterPageCMSValues.headingMeetInhabitants = undefined;
    letterPageCMSValues.headingVisitInhabitants = visitVerb && letterPageCMSValues.headingVisitInhabitants?.startsWith(visitVerb) ? visitVerb + inhabitantsListedAsSentence : inhabitantsListedAsSentence + visitVerb;
    // if (letterPageCMSValues.headingVisitInhabitants.includes("undefined")) letterPageCMSValues.headingVisitInhabitants = undefined;
    /*-----------------------------------*/

    return (
        <div>
            <div style={{ position: "relative", width: "100%", marginBottom: "0.50rem", display: "flex", justifyContent: "center", alignItems: "center", gap: "0.75rem" }} >

                {handlePreviousLetter ?
                    <button className={styles['icon-button']} onClick={handlePreviousLetter}><IoIosArrowBack /></button>
                    :
                    <button className={styles['icon-button--disabled']} disabled><IoIosArrowBack /></button>
                }


                <h1 style={{ fontSize: "1.5rem", textAlign: "center", color: "var(--black)" }}>{selectedMapItem.name[0]}</h1>

                {handleNextLetter ?
                    <button className={styles['icon-button']} onClick={handleNextLetter}><IoIosArrowForward /></button>
                    :
                    <button className={styles['icon-button--disabled']} disabled ><IoIosArrowForward /></button>
                }

                <button onClick={closeWindow} style={{ position: "absolute", right: "0", top: "0", cursor: "pointer", display: "block", fontSize: "1.5rem" }}><FaWindowClose /></button>
            </div>
            <div ref={firstFocus} tabIndex={1} className={styles['letter-page']} >
                {/* <h2>{selectedMapItem?.name}</h2> */}
                <div className={styles['half-n-half']} >
                    <div className={styles['first-half']} style={{ backgroundColor: getLetterColor() }} >
                        <div className={styles['letter-page-sections-grid']} >
                            <div className={styles['grid-item']}>
                                <div className={styles['letter-page-sections-grid__heading']} >
                                    {letterPageCMSValues.headingTheLetter}
                                </div>
                                <img alt="" src={getLetterImgSrc(selectedMapItem)} style={{ opacity: "0.8" }} className={styles['letter-page-sections-grid__main-content']} />
                                {letterAudioSrc &&
                                    <>
                                        <span>
                                            <button className={styles['icon-button']} onClick={() => { audio.current?.load(); audio.current?.play(); }} style={{}} >
                                                <GiSpeaker />
                                            </button>
                                        </span>
                                        <audio ref={audio} className={styles.audio_player} >
                                            <source src={letterAudioSrc} type="audio/mpeg" />
                                        </audio>
                                    </>}
                            </div>
                            <div className={styles['grid-item']}>
                                <span className={styles['letter-page-sections-grid__heading']} >
                                    {letterPageCMSValues.headingMeetInhabitants}
                                </span>
                                <div style={{marginBlockEnd: "1.5rem"}}>
                                    <BiographyLanguageSelect />
                                </div>
                                <div className={styles['letter-page-sections-grid__main-content']}>
                                    <ul className={styles['letter-page-sections-grid__characters']} style={{ paddingLeft: "0" }}>
                                        {inhabitants.map(inhabitant =>
                                            <li key={inhabitant.id} style={{ listStyle: "none" }}>
                                                <CharacterWithAudio characterNode={inhabitant} planetIdNode={planetIdNode} />
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles['second-half']} >
                        <img alt="" className={styles['background-overlay']} src={`https://s3-eu-west-1.amazonaws.com/alphabet-planet-ui/images-houses-front/${selectedMapItem.name[1].replace("ä", "æ").replace("ö", "ø")}.svg`}></img>
                        <div >
                            <svg className={styles.wave} viewBox="0 0 500 78.86" height="48" preserveAspectRatio="none" style={{ width: "100%", overflow: "hidden", fill: getLetterColor(), }}>
                                <path d="M0.00,49.99 C150.00,150.00 271.49,-49.99 500.00,49.99 L500.00,0.00 L0.00,0.00 Z" style={{ stroke: "none", }}></path>
                            </svg>
                        </div>
                        <div className={styles['letter-page-sections-grid']}>
                            {(storiesHMTL && storiesHMTL.length > 0) &&
                                <div className={styles['grid-item']} /* style={{ margin: "auto", width: "fit-content" }} */>
                                    <span className={styles['letter-page-sections-grid__heading']} >{storiesHMTL.length > 1 ? letterPageCMSValues.headingStories : letterPageCMSValues.headingStory}</span>
                                    <div className={styles['letter-page-sections-grid__main-content']}>
                                        {/* {storyExercisesHTML} */}
                                        <div className={styles['stories-section__list']}>
                                            {storiesHMTL}
                                        </div>
                                    </div>
                                </div>
                            }
                            {(visitMeExercise !== undefined) &&
                                <div className={styles['grid-item']} >
                                    <span className={styles['letter-page-sections-grid__heading']} >{letterPageCMSValues.headingVisitInhabitants}</span>
                                    <div className={styles['letter-page-sections-grid__main-content']}>
                                        <div>
                                            <a className={styles.btn} href={getCartoonistUrl(visitMeExercise)} target="_top">{letterPageCMSValues.OpenTheLetterInCartoonist} <FaExternalLinkAlt fontSize={"1rem"} /></a>{/* target=_top will make sure it targets the parent of the iframe that Alfabetplaneten is currently running inside. Without this attribute, there will be a top menu bar inside the iframe as well as outside */}
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default LetterPage;