import { createContext } from "react";
import { CategoryTreeNode } from "./CategoryTreeNode";

export interface CategoryTreeContextType {
    inhabitants?: CategoryTreeNode;
    libraryInhabitants?: CategoryTreeNode;
    pages?: CategoryTreeNode;
    components?: CategoryTreeNode;
    songs?: CategoryTreeNode;
    globalTranslations?: CategoryTreeNode;
    stories?: CategoryTreeNode;
}

export const categoryTreeContext = createContext<null | CategoryTreeContextType>(null);