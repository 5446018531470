import styles from './ButtonStyle.module.css';

interface ButtonStyleProps {
    children: React.ReactNode;
    color: string;
}

/**
 * A wrapper component that renders an element with the style of a button.
 * Can be used to wrap around for example a <Link> or <button> element.
 *
 * @param {ButtonStyleProps} children - the children components to be rendered within the button style.
 * @param {string} color - the color of the button
 * @return {JSX.Element} - the component styled as a button
 */
export const ButtonStyle = ({ children, color }: ButtonStyleProps) => {

    return (
        <div className={styles['button-style']} style={{ ['--color' as any]: color }}>
            {children}
        </div>
    );
}