import React, { useContext, useEffect, useRef, useState } from 'react';
import { Checkbox } from '../Checkbox/Checkbox';
import styles from './ExerciseFilterSection.module.css';
import { CategoryTreeNode } from '../../../utils/CategoryTreeNode';
import { BasicSessionInfoContextType, basicSessionInfoContext } from '../../../utils/BasicSessionContext';

interface ExerciseFilterSectionProps {
    filterTags: CategoryTreeNode[];
    filterChecked: Function;
    filterUnchecked: Function;
}

export const ExerciseFilterSection = ({ filterTags, filterChecked, filterUnchecked }: ExerciseFilterSectionProps) => {
    const [checkedCategory, setCheckedCategory] = useState<string | null>(null)

    function handleChange(target: HTMLInputElement) {
        if (target.checked) {
            filterChecked(target.value);
            setCheckedCategory(target.value);
        }
        else {
            filterUnchecked(target.value);
            setCheckedCategory(null); // if radio
        }
    }


    const [isOpen, setIsOpen] = useState(true); // In case we want to implement ability to open/close filter section


    const {basicSessionInfo} = useContext(basicSessionInfoContext) as BasicSessionInfoContextType;
    const languageId = basicSessionInfo?.languageId;

    return (
        <fieldset className={styles['filter-section']} role="radiogroup" >
            <legend /* onClick={() => setIsOpen(!isOpen)} */ className={styles.legend}>Kategorier</legend>{/* TODO: get value from CMS */}
            {isOpen &&
                filterTags.map(tag => {
                    // ----- temporary solution for nynorsk filtertags ----
                    if (languageId === 3) {
                        switch (tag.name) {
                            case "Bokstavlyder":
                                tag.name = "Bokstavlydar";
                                break;

                            case "Bokstavsekvenser":
                                tag.name = "Bokstavsekvensar";
                                break;

                            case "Myldrebilder":
                                tag.name = "Myldrebilete";
                                break;

                            case "Stavelser":
                                tag.name = "Stavingar";
                                break;

                            case "Tall":
                                tag.name = "Tal";
                                break;

                            case "Samtaleoppgaver":
                                tag.name = "Samtaleoppgåver";
                                break;

                            // Aktiviteter
                            case "Rim og lydlek":
                                tag.name = "Rim og lydleik";
                                break;

                            case "Bevegelsesglede":
                                tag.name = "Rørsglede";
                                break;

                            case "Fortellerglede":
                                tag.name = "Fortellarglede";
                                break;

                            case "Sosial kompetanse":
                                tag.name = "Sosial dugleik";
                                break;

                            default:
                                break;
                        }
                    }
                    //---------------------------------------------------
                     
                    return (
                        <div className={styles['filter-option']} key={tag.id}>
                            <Checkbox key={tag.id.toString()} label={tag.name} handleChange={handleChange} value={tag.id.toString()} checked={tag.id.toString() === checkedCategory} />
                        </div>
                    )
                })
            }
        </fieldset>
    );
};