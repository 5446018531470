import { useContext, useEffect, useState } from "react";
import { Topic } from "../../utils/Topic";
import { SmallHeader } from "../UIComponents/Header/SmallHeader";
import styles from "./TopicTreeLeafNode.module.css";
import ExercisesQueryParameters from "../../utils/ExercisesQueryParameters";
import { Exercise, ObjectType, sortExercisesBySortIndex } from "../../utils/exerciseService";
import useFetchExercises from "../../utils/useFetchExercises";
import { StoryList } from "../StoryList/StoryList";
import { basicSessionInfoContext, BasicSessionInfoContextType } from "../../utils/BasicSessionContext";
import { parseToURL } from "../../utils/parseToURL";
import { InfoButton } from "../UIComponents/InfoButton/InfoButton";
import { useTranslatePage } from "../../utils/TranslationHooks";
import { ExerciseListing } from "../UIComponents/ExerciseListing/ExerciseListing";

interface Props {
    topic: Topic;
    themeColor?: string;
}

export const TopicTreeLeafNode = ({ topic, themeColor }: Props) => {
    const { basicSessionInfo } = useContext(basicSessionInfoContext) as BasicSessionInfoContextType;
    const [exercises, setExercises] = useState<Exercise[]>();
    const languageId = basicSessionInfo?.languageId;

    const translateStoriesPage = useTranslatePage("Stories");
    const storiesHeading = translateStoriesPage("Title");
    const translateExercisesPage = useTranslatePage("Exercises");
    const exercisesHeading = translateExercisesPage("Title");


    const params: ExercisesQueryParameters = {
        objectType: ObjectType.crz_exercise,
        categoryId: topic.categoryId,
        languageId
    }

    const { data } = useFetchExercises(params);

    useEffect(() => {
        setExercises(data?.exercises)
    }, [data]);

    if (exercises?.length === 0) {
        return null;
    }

    sortExercisesBySortIndex(exercises || []);
    const isSocialCompetence = topic.parentId === "sosial_kompetanse";

    const getExerciseListing = () => {
        return (
            exercises && <ExerciseListing exercises={exercises} themeColor={themeColor} />
        )
    }

    const getStoriesListing = () => {
        return <StoryList categoryId={topic.categoryId} />
    }

    /**Determines whether or not to use a heading like "exercises" or "stories" above the list of exercises */
    const useExerciseTypeHeading = topic.parentId !== "aktiviteter";

    return (
        <div className={styles["topic-tree-leaf-node"]}>
            {topic.title &&
                <div className={styles['exercise-section-heading']}>
                    <SmallHeader headingLevel={2} hash={parseToURL(topic.title)}>
                        {topic.title}
                    </SmallHeader>
                    {topic.guidance && <InfoButton text={topic.guidance} />}
                </div>}

            {useExerciseTypeHeading &&
                <div className={styles['list-heading']}>
                    <SmallHeader headingLevel={3}>
                        {isSocialCompetence ? storiesHeading : exercisesHeading}
                    </SmallHeader>
                </div>
            }

            <div className={styles.list}>
                {isSocialCompetence ? getStoriesListing() : getExerciseListing()}
            </div>
        </div>);
}