import React, { useContext, useState } from 'react';
import { CategoryTreeNode } from "../../utils/CategoryTreeNode";
import styles from './AlphabetNavBar.module.css';
import { PlanetContextType, planetContext } from '../../utils/PlanetContext';


const AlphabetNavBar = () => {

    window.onload = () => setScore();
    window.onscroll = () => setScore();
    const [selectedLetter, setSelectedLetter] = useState("")

    const { alphabet } = useContext(planetContext) as PlanetContextType

    function handleClick(target: HTMLButtonElement) {

        const letter = target.textContent ? target.textContent : "";
        setSelectedLetter(letter);

        // Scroll to section
        const correspondingSection = document.getElementById(letter);
        correspondingSection?.scrollIntoView({ block: 'center' });
    }

    /*     function getViewScore(elements: HTMLElement[]) {
            const elementsInView = elements.filter( el => setScore(el));
            elementsInView.forEach(el => el.style.setProperty("--viewScore", ""))
        } */

    /*     function handleScroll() {
            
        } */

    function setScore() {
        let scores: number[] = [];
        let elements = document.getElementsByClassName("letter_character") as HTMLCollectionOf<HTMLDivElement>;
        const windowCenter = window.innerHeight / 2;
        for (let i = 0; i < elements.length; i++) {
            const element = elements[i];
            const rect = element.getBoundingClientRect();
            const elemTop = rect.top;
            const elemHeight = rect.height;

            const elemCenter = elemHeight / 2 + elemTop;

            const distanceFromCenter = Math.max(elemCenter, windowCenter) - Math.min(elemCenter, windowCenter);
            let centerScore = (windowCenter - distanceFromCenter) / 600;
            if (centerScore < 0) {
                centerScore = 0;
            }

            element.style.setProperty("--centerScore", centerScore.toString());

            let button = document.getElementById("btn_" + element.getAttribute("id")!);
            button?.style.setProperty("--centerScore", centerScore.toString());

            scores.push(distanceFromCenter);
            /*             if (scores[i-1]) {
                            if (scores[-1] < scores[i]) {
                                setSelectedLetter();
                            }
            
                        } */
        }



        /* elements.(el => el.style.setProperty("--distanceFromCenter", distanceFromCenter)) */

        /*         // Only completely visible elements return true:
                var isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight);
                // Partially visible elements return true:
                //isVisible = elemTop < window.innerHeight && elemBottom >= 0;
                return isVisible; */
    }


    return (
        <div className={styles.nav} >
            <nav >
                <ul className={styles.list}>
                    {alphabet?.map(letter =>
                        <li key={letter} className={styles.list_element}>
                            <button id={"btn_" + letter[0]} className='list_btn' onClick={e => handleClick(e.currentTarget)}>
                                <span className={styles.button__text} >{letter[0]}</span>
                            </button>
                        </li>
                    )}
                </ul>
            </nav>
        </div>
    );
};

export default AlphabetNavBar;
