import React, {useContext} from 'react';
import { FaArrowAltCircleLeft } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import styles from './BackButtonLink.module.css';
import {categoryTreeContext, CategoryTreeContextType} from "../../../utils/CategoryTreeContext";
import {useTranslateGlobal} from "../../../utils/TranslationHooks";

interface BackButtonLinkProps {
    /** If linkTo property is not set, the button will link to the previous subdirectory in the url */
    linkTo?: string;
    title?: string;
}

export const BackButtonLink = ({linkTo, title }: BackButtonLinkProps) => {
    const translateGlobal = useTranslateGlobal();

    // TODO: Use the GoBackTo<<PreviousPage>> node in globalTranslationsNode.
    return (
        <div className={styles['--sticky']}>
            <Link to={linkTo ? linkTo : "./.."} className={styles.link} title={translateGlobal("Back")} >
                <FaArrowAltCircleLeft />
            </Link>
        </div>
    );
};