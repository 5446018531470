import { Exercise } from '../../../utils/exerciseService';
import { getExerciseThumbnail } from '../../../utils/getExerciseThumbnail';
import styles from './ExercisePresenter.module.css';
import { FaAngleDown } from 'react-icons/fa';
import {isInstanceOfPrintElement, PrintElement} from "../../../utils/printUtils";

interface ExercisePresenterProps {
    exercise: Exercise | PrintElement;
    highlighted?: boolean;
    themeColor?: string;
}

const ExercisePresenter = ({ exercise, highlighted, themeColor }: ExercisePresenterProps) => {

    const readMoreSVG = <FaAngleDown />;
    let name;
    let thumbnail;
    let thumbnailStyle;
    let titleBoxPrint;

    if (isInstanceOfPrintElement(exercise)) {
        name = (exercise as PrintElement).title;
        thumbnail = (exercise as PrintElement).thumbnail;
        thumbnailStyle = styles.printThumbnail;
        titleBoxPrint = styles.titleBoxPrint;
    } else {
        name = (exercise as Exercise).name;
        thumbnail = getExerciseThumbnail(exercise as Exercise);
        thumbnailStyle = styles.thumbnail;
    }

    return (
        <div style={{ ['--theme-color' as any]: themeColor }} className={highlighted ? styles.hightlighted : styles.normal}>
            <img className={thumbnailStyle} src={thumbnail} alt="" /> {/* src={exercise.thumbnailURL || 'https://www.creaza.com/file/ci/' + exercise.contentId + '/thumb/' + exercise.name}  */}
            <div className={`${styles['title-box']} ${titleBoxPrint}`}>
                <div className={styles.title} >{name}</div>
                <div className={styles['read-more']}>
                    {readMoreSVG}
                </div>
            </div>
        </div>

    );
};

export default ExercisePresenter;