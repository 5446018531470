import { SmallHeader } from "../UIComponents/Header/SmallHeader";
import styles from "./PrintLeafNode.module.css";
import { parseToURL } from "../../utils/parseToURL";
import { ExerciseListing } from "../UIComponents/ExerciseListing/ExerciseListing";
import {PrintCategory} from "../../utils/printUtils";

interface Props {
    printCategory: PrintCategory;
    themeColor?: string;
}

export const PrintLeafNode = ({ printCategory, themeColor }: Props) => {
    return (
        <div className={styles["print-leaf-node"]}>
            <div className={styles['exercise-section-heading']}>
                <SmallHeader headingLevel={2} hash={parseToURL(printCategory.name)}>
                    {printCategory.name}
                </SmallHeader>
            </div>

            <div className={styles.list}>
                <ExerciseListing exercises={[]} themeColor={themeColor} printElements={printCategory.children}/>
            </div>
        </div>);
}