import styles from './Video.module.css';

interface VideoProps {
 src: string;
 children?: JSX.Element;
}

export const Video = ({ src, children }: VideoProps) => {
    return (
        <div className={styles.video}>
            <video /* width="320" height="240" */ controls controlsList='nodownload' style={{ width: "100%", /* objectFit: 'cover' */ }} className='videoElement'>
            {children}
                <source src={src} type="video/mp4" />
                Your browser does not support HTML video.
            </video>
        </div>
    );
};
