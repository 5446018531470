import { useContext } from "react";
import { Topic } from "../../utils/Topic";
import { NodeWrapper } from "../UIComponents/NodeWrapper/NodeWrapper";
import styles from "./TopicTreeRootPage.module.css"
import { MediumHeader } from "../UIComponents/Header/MediumHeader";
import { TopicNavTile } from "../UIComponents/TopicNode/TopicNavTile";
import { TopicRootTile } from "../UIComponents/TopicNode/TopicRootTile";
import { Link } from "react-router-dom";
import { BackButtonLink } from "../UIComponents/BackButtonLink/BackButtonLink";
import {useMGMTTranslations, useTranslatePage} from "../../utils/TranslationHooks";
import { TopicIcon, topicIconBaseURL } from "../UIComponents/TopicNode/TopicIcon";
import { basicSessionInfoContext, BasicSessionInfoContextType } from "../../utils/BasicSessionContext";

interface TopicTreeRootPage {
    topics: Topic[];
    activityUrl: string;
    songUrl: string;
    abpLogo?: string;
}

export const TopicTreeRootPage = ({ topics, activityUrl, songUrl, abpLogo }: TopicTreeRootPage) => {
    const backgroundImage = "https://alphabet-planet-ui.s3.eu-west-1.amazonaws.com/topic-tree/images/himmel_bakgrunn.svg";
    const planet = "https://alphabet-planet-ui.s3.eu-west-1.amazonaws.com/topic-tree/images/planet.svg";
    const { basicSessionInfo } = useContext(basicSessionInfoContext) as BasicSessionInfoContextType;
    const languageId = basicSessionInfo?.languageId;

    const mgmtTranslate = useMGMTTranslations();

    const translatePage = useTranslatePage("TeacherFrontpage");

    return (
        <div className={styles["topic-tree-root-page"]}>
            <img src={backgroundImage} alt={""} className={styles["background-new-design"]} />
            <div
                className={styles["content-wrapper"]}
            >
                {<BackButtonLink linkTo={`../`} />}
                {abpLogo && <img className={styles.logo} src={abpLogo} alt={""} />}
                <div className={styles.container}>
                    <MediumHeader headingLevel={1}>{translatePage("Title1")}</MediumHeader>
                    <div className={styles["content-container"]}>
                        <NodeWrapper>
                            {topics.map((topic, index) => (
                                <TopicRootTile
                                    key={index}
                                    topic={topic}
                                    themeName={topic.themeName} />
                            ))}
                        </NodeWrapper>
                    </div>
                </div>
                <div className={styles.container}>
                    <MediumHeader headingLevel={1}>{translatePage("Title2")}</MediumHeader>
                    <NodeWrapper alignTop={true}>
                        <TopicNavTile
                            image={topicIconBaseURL + TopicIcon.Aktiviteter}
                            text={translatePage("Activities")}
                            link={activityUrl || ""}
                            languageId={languageId}
                        />
                        <TopicNavTile
                            image={topicIconBaseURL + TopicIcon.Stage_round}
                            text={translatePage("Songs")}
                            link={songUrl || ""}
                            languageId={languageId}
                        />
                        <TopicNavTile
                            image={topicIconBaseURL + TopicIcon.Rim_og_rytme}
                            text={translatePage("Rhymes")}
                            link={"/teacher/rhymes"}
                            languageId={languageId}
                        />
                        <TopicNavTile
                            image={topicIconBaseURL + TopicIcon.Sosial_kompetanse}
                            text={translatePage("SocialCompetence")}
                            link={"/teacher/topic/sosial_kompetanse"}
                            languageId={languageId}
                        />
                        {languageId !== 8?
                            (<TopicNavTile
                                image={topicIconBaseURL + TopicIcon.Begreper}
                                text={mgmtTranslate("topic_concepts") || ""}
                                link={
                                    window.location.href.indexOf("alphabetplanet-stage.creaza.com") > -1 ?
                                        "https://portal-stage.creaza.com/?page=concept&group=norsk" :
                                        "https://portal.creaza.com/?page=concept&group=norsk"
                                }
                                languageId={languageId}
                            />) :
                            <></>
                        }
                        {languageId !== 8?
                            (<TopicNavTile
                                image={topicIconBaseURL + TopicIcon.Print}
                                text={mgmtTranslate("print") || ""}
                                link={"/teacher/print"}
                                languageId={languageId}
                            />) :
                            <></>
                        }
                    </NodeWrapper>
                    <div className={styles["nav-button-wrapper"]}>
                        {/* TODO: This is a quickfix. Get value from CMS */}
                        <a href={languageId === 8 ? "https://web.creaza.com/no/alfabetplaneten-veiledning-0" : "https://web.creaza.com/no/alfabetplaneten-veiledning"}
                            target="_blank"
                            style={{ textDecoration: "none" }}
                        >
                            <div className={styles["nav-button"]}>
                                <div>{translatePage("TeacherGuidance")}</div>
                            </div>
                        </a>
                        <Link style={{ textDecoration: "none" }} to={'/student'}>
                            <div className={styles["nav-button"]}>
                                <img className={styles["nav-button-image"]} src={planet} alt={""} />
                                <div>{translatePage("StudentPageLinkTitle")}</div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}