import { useContext, useEffect, useState } from 'react';
import { createNewAssignment, initAssign } from '../../../../utils/AssignmentService/AssignmentService';
import { NewLinkNode } from '../../../../utils/AssignmentService/NewLinkNode';
import { CategoryTreeNode } from '../../../../utils/CategoryTreeNode';
import { SessionInfoNode } from '../../../../utils/SessionService/SessionInfoNode';
import { getSessionInfo } from '../../../../utils/SessionService/SessionService';
import useFetchExercises from '../../../../utils/useFetchExercises';
import PageGeneralMain from '../../../PageGeneralMain';
import { Breadcrumbs } from '../../../UIComponents/Breadcrumbs/Breadcrumbs';
import { Breadcrumb } from "../../../UIComponents/Breadcrumbs/Breadcrumb";
import { ExerciseConsumablePresenter } from '../../../UIComponents/ExerciseConsumablePresenter/ExerciseConsumablePresenter';
import { ExerciseListing } from '../../../UIComponents/ExerciseListing/ExerciseListing';
import Modal from '../../../UIComponents/Modal/Modal';
import AssignmentLinkAndRedirectPrompt from '../../../UIComponents/Prompts/AssignmentLinkAndRedirectPrompt';
import AssignmentPrompt from '../../../UIComponents/Prompts/AssignmentPrompt';

import styles from './Story.module.css';
import { BackButtonLink } from '../../../UIComponents/BackButtonLink/BackButtonLink';
import { parseToURL } from '../../../../utils/parseToURL';
import { BasicSessionInfoContextType, basicSessionInfoContext } from '../../../../utils/BasicSessionContext';
import { Video } from '../../../UIComponents/Video/Video';
import Button from '../../../UIComponents/Button/Button';
import { getCartoonistUrl } from '../../../../utils/getCartoonistUrl';
import { useParams } from 'react-router-dom';

interface StoryProps {
    breadcrumbs?: Breadcrumb[];
    storyPageNode: CategoryTreeNode;
    backBtnUrl?: string;
}

/**
 * Component that returns a so-called 'story page'. A 'story page' should consist of one main exercise and three child exercises.
 * The main exercise is expected have objectType: "crz_exercise_samfunn", while the three others should have objectType: "crz_exercise".
 * From the content creators perspective: Each story is first created in ICSX, so it becomes a node in the categoryTree. The authors then create the exercises that will be included in that story.
 * 
 * Thomas Bugge 2022-09-22
 */
const Story = ({ breadcrumbs, storyPageNode, backBtnUrl }: StoryProps) => {

    const { cmsLangId } = useContext(basicSessionInfoContext) as BasicSessionInfoContextType;

    const { storyId } = useParams();

    const { data, isPending, error } = useFetchExercises({ categoryId: storyId /* storyNode.id */ });
    const mainExercise = data?.exercises?.find(ex => ex.hasContent === true);
    const childExercises = data?.exercises.filter(ex => ex.hasContent === false);

    const title = mainExercise?.name;

    const editStoryLabel = storyPageNode.children.find(node => node.name === "EditStory-label")?.children.find(node => node.name.includes(cmsLangId))?.description;
    console.assert(editStoryLabel, "Missing CMS value of editStory-label.");

    /*     const editStoryAccessibilityLabel = storyPageNode.children.find(node => node.name === "EditStory-accessibilityLabel")?.children.find(node => node.name.includes(cmsLangId))?.description;
        console.assert(editStoryAccessibilityLabel, "Missing CMS value of editStory- accessibilityLabel."); */

    const headingChildExercises = storyPageNode.children.find(node => node.name === "Exercises-heading")?.children.find(node => node.name.includes(cmsLangId))?.description;

    const [newAssignmentLink, setNewAssignmentLink] = useState<NewLinkNode | null>(null);
    const [userSession, setUserSession] = useState<SessionInfoNode | null>(null);
    const [selectedExercise, setSelectedExercise] = useState(null);

    const [showAssignPrompt, setShowAssignPrompt] = useState(false);
    const [showLinkAndRedirectPrompt, setShowLinkAndRedirectPrompt] = useState(false);

    const createAssignment = async (selectedExercise: any) => {
        const response = await initAssign();
        console.log(response.data);
        setSelectedExercise(selectedExercise)
        updateSessionInfo();
        setNewAssignmentLink(response.data);
        setShowAssignPrompt(true);
    }

    const assignExercise = async (newAssignment: any) => {
        const response = await createNewAssignment(newAssignment);
        setShowAssignPrompt(false);
        setShowLinkAndRedirectPrompt(true);
    }

    const abortAssign = () => {
        setSelectedExercise(null);
        setNewAssignmentLink(null);
        setShowAssignPrompt(false);
        setShowLinkAndRedirectPrompt(false);
    }

    const updateSessionInfo = async () => {
        const response = await getSessionInfo();
        setUserSession(response.data);
    }

    useEffect(() => {
        updateSessionInfo();
    }, [])

    const videoSrc = mainExercise && "https://www.creaza.com/file/ci/" + mainExercise.exportId.toString() + "/aaa";

    return (
        <>
            <img alt="" className={styles['background-image']} src='https://alphabet-planet-ui.s3.eu-west-1.amazonaws.com/images-ui/alphabet_planet_ui_page_biographies.svg' />
            {/* <Breadcrumbs breadcrumbs={breadcrumbs} currentPageTitle={title} pushContentDown /> */}
            <PageGeneralMain>
                <BackButtonLink linkTo={backBtnUrl} title={""} />
                <h1 className={styles['story-title']}>{title}</h1>
                <div>
                    {/*                     {(userSession && mainExercise) &&
                        <ExerciseConsumablePresenter pathToPage={window.location.href} exercise={mainExercise} sessionInfoNode={userSession} editStoryLabel={editStoryLabel} editStoryAccessibilityLabel={editStoryAccessibilityLabel} />
                    } */}

                    {videoSrc &&
                        <Video src={videoSrc} />
                    }

                    {(editStoryLabel && mainExercise) &&
                        <div style={{ marginBlock: "18px" }}>
                            <a href={getCartoonistUrl(mainExercise)} target="_top" /* title={editStoryAccessibilityLabel} */>
                                <Button onClick={() => { }}>{editStoryLabel}</Button>
                            </a>
                        </div>
                    }

                    <section className={styles['exercises-section']}>
                        {(childExercises !== undefined && childExercises.length > 0) && <h2 className={styles['exercises-list-heading']}>{headingChildExercises}</h2>}
                        <div>
                            {/*                             {childExercises && childExercises.map(ex => {
                                return (
                                    <ExercisePresenter exercise={ex} assignExercise={createAssignment} />
                                )
                            })} */}
                            {childExercises && <ExerciseListing exercises={childExercises} />}
                        </div>
                    </section>
                </div>

            </PageGeneralMain>
            <Modal show={showAssignPrompt} backdropClicked={abortAssign}>
                <AssignmentPrompt
                    exercise={selectedExercise}
                    session={userSession}
                    newLinkNode={newAssignmentLink}
                    confirmClicked={assignExercise}
                    cancelClicked={abortAssign} />
            </Modal>
            <Modal show={showLinkAndRedirectPrompt}>
                <AssignmentLinkAndRedirectPrompt
                    exercise={selectedExercise}
                    session={userSession}
                    newLinkNode={newAssignmentLink}
                    cancelClicked={abortAssign} />
            </Modal>
        </>
    );
};

export default Story;