import { SessionInfoProvider } from './utils/SessionContext';
import { AlphabetPlanet } from './pages/AlphabetPlanet/AlphabetPlanet';
import { BasicSessionInfoProvider } from './utils/BasicSessionContext';
import { ScrollToAnchor } from './utils/ScrollToAnchor';
import { BiographyLanguageProvider } from './utils/BiographyLanguageContext';
import { MGMTTranslationsProvider } from './utils/MgtmTranslationsContext';

function App() {

  return (
    <>
      <ScrollToAnchor />
      <BasicSessionInfoProvider>
        <SessionInfoProvider>
          <MGMTTranslationsProvider>
            <BiographyLanguageProvider>
              <AlphabetPlanet />
            </BiographyLanguageProvider>
          </MGMTTranslationsProvider>
        </SessionInfoProvider>
      </BasicSessionInfoProvider>
    </>
  );
}

export default App;