import React, {useContext} from "react";
import styles from "./RhymesPage.module.css";
import {CategoryTreeNode} from "../../utils/CategoryTreeNode";
import ExercisesQueryParameters from "../../utils/ExercisesQueryParameters";
import {ObjectType} from "../../utils/exerciseService";
import useFetchExercises from "../../utils/useFetchExercises";
import {basicSessionInfoContext, BasicSessionInfoContextType} from "../../utils/BasicSessionContext";
import ExercisePresenterNew from "../UIComponents/ExercisePresenter/ExercisePresenterNew";
import {NodeWrapper} from "../UIComponents/NodeWrapper/NodeWrapper";
import {LargeHeader} from "../UIComponents/Header/LargeHeader";
import appStyle from "../../App.module.css";
import {BackButtonLink} from "../UIComponents/BackButtonLink/BackButtonLink";
import {InfoButton} from "../UIComponents/InfoButton/InfoButton";
import {useTranslatePage} from "../../utils/TranslationHooks";
import {rimOgReglerInfoNO, rimOgReglerInfoSV} from "../../utils/TopicStructure";

interface Props {
    rhymesExerciseNode: CategoryTreeNode;
}

export const RhymesPage = ({rhymesExerciseNode}: Props) => {
    const {basicSessionInfo} = useContext(basicSessionInfoContext) as BasicSessionInfoContextType;
    const languageId = basicSessionInfo?.languageId;
    const storyLanguageId = basicSessionInfo?.languageId;
    const backgroundImage = "https://alphabet-planet-ui.s3.eu-west-1.amazonaws.com/topic-tree/images/rim_og_rytme_bg.svg";
    const translatePage = useTranslatePage("TeacherFrontpage");
    let infoDescription = languageId === 8 ? rimOgReglerInfoSV : rimOgReglerInfoNO;

    const params: ExercisesQueryParameters = {
        objectType: ObjectType.crz_exercise,
        categoryId: rhymesExerciseNode.id,
        languageId: storyLanguageId
    };
    const { data } = useFetchExercises(params);

    return (
        <div>
            <img src={backgroundImage} className={appStyle["background-image"]} alt =""/>
            <BackButtonLink />
            <LargeHeader headingLevel={1}>{translatePage("Rhymes")}</LargeHeader>
            <div className={styles['info-btn']}>
                <InfoButton text={infoDescription} />
            </div>
            <div className={styles.list}>
                <NodeWrapper>
                    {data?.exercises?.map(exercise => {
                        return (
                            <div
                                key={exercise.id}
                                className={styles.node}

                            >
                                <ExercisePresenterNew
                                    exercise={exercise}
                                    linkTo={`${exercise.exportId}/${encodeURI(exercise.name)}`}
                                />
                            </div>
                        )
                    }) || []}
                </NodeWrapper>
            </div>
        </div>
    )
}