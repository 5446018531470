import { useContext } from "react";
import { BasicSessionInfoNode } from "./SessionService/SessionInfoNode";
import { BasicSessionInfoContextType, basicSessionInfoContext } from "./BasicSessionContext";

/* At the time of writing this, there are some language settings that are 
set as categories in the ICSX category tree (the one called "Planets-node").
It might be a good idea to get rid of these settings in the category tree,
since these nodes are not what we think of as categories.
This can cause confusion and make it harder to work with the actual categories or settings.
Moreover, in my experience, nobody actually changes these settings,
and it's not like the authors need to be able to easily edit them.
*/

export enum LanguageISO639_1 {
    Arabic = "ar",
    /**A decicion was made to use "prs" for Dari, as this is the case for the rest of Creaza. */
    Dari = "prs",
    Kurmanji = "ku",
    Norwegian = "no",
    Norwegian_Bokmål = "nb",
    Norwegian_Nynorsk = "nn",
    Pashto = "ps",
    Polish = "pl",
    Somali = "so",
    Swahili = "sw",
    Swedish = "sv",
    Tigrinya = "ti",
    Turkish = "tr",
}

interface UserLanguage {
    id: BasicSessionInfoNode["languageId"];
    iso: LanguageISO639_1;
    biographyLanguages: LanguageISO639_1[];
    defaultSpokenLanguage: LanguageISO639_1;
    /**This value should be found within any translation in the response from MGMT translation */
    locale: string;
}

/** List of supported user languages */
const supportedUserLanguages: UserLanguage[] = [
    {
        id: 1,
        iso: LanguageISO639_1.Norwegian_Bokmål,
        biographyLanguages: [
            LanguageISO639_1.Norwegian,
            LanguageISO639_1.Arabic,
            LanguageISO639_1.Dari,
            LanguageISO639_1.Kurmanji,
            LanguageISO639_1.Pashto,
            LanguageISO639_1.Polish,
            LanguageISO639_1.Somali,
            LanguageISO639_1.Swahili,
            LanguageISO639_1.Tigrinya,
            LanguageISO639_1.Turkish,
        ],
        defaultSpokenLanguage: LanguageISO639_1.Norwegian,
        locale: "nb_NO",
    },
    {
        id: 3,
        iso: LanguageISO639_1.Norwegian_Nynorsk,
        biographyLanguages: [
            LanguageISO639_1.Norwegian,
            LanguageISO639_1.Arabic,
            LanguageISO639_1.Dari,
            LanguageISO639_1.Kurmanji,
            LanguageISO639_1.Pashto,
            LanguageISO639_1.Polish,
            LanguageISO639_1.Somali,
            LanguageISO639_1.Swahili,
            LanguageISO639_1.Tigrinya,
            LanguageISO639_1.Turkish,
        ],
        defaultSpokenLanguage: LanguageISO639_1.Norwegian,
        locale: "nn_NO",
    },
    {
        id: 8,
        iso: LanguageISO639_1.Swedish,
        biographyLanguages: [
            LanguageISO639_1.Swedish,
            LanguageISO639_1.Arabic,
            LanguageISO639_1.Dari,
            LanguageISO639_1.Kurmanji,
            LanguageISO639_1.Pashto,
            LanguageISO639_1.Polish,
            LanguageISO639_1.Somali,
            LanguageISO639_1.Swahili,
            LanguageISO639_1.Tigrinya,
            LanguageISO639_1.Turkish,
        ],
        defaultSpokenLanguage: LanguageISO639_1.Swedish,
        locale: "sv_SE",
    },
];

export function getLanguage(languageId: number): UserLanguage | undefined {
    return supportedUserLanguages.find(l => l.id === languageId);
}

/**Returns the language object that corresponds to the user's session language */
export function useUserLanguage() {
    const { languageId } = useContext(basicSessionInfoContext) as BasicSessionInfoContextType;
    return getLanguage(languageId || 0); // Provide a default value of 0 if languageId is undefined
}

export function isUserLanguageSupportedByABP(id: number): boolean {
    return supportedUserLanguages.some(l => l.id === id);
}
