import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { FaWindowClose } from 'react-icons/fa';
import { GiSpeaker } from 'react-icons/gi';
import styles from './AudioOnClickButton.module.css';
import { AiOutlineLoading3Quarters } from "react-icons/ai";

interface AudioOnClickButtonProps {
    children: React.ReactNode;
    audioSrc: string;
    playerCSS?: React.CSSProperties;
}

/**
 * @deprecated Start using AudioButton and AudioPlayer instead
 */
export const AudioOnClickButton = ({ children, audioSrc, playerCSS }: AudioOnClickButtonProps) => {
    const audioRef = useRef<null | HTMLAudioElement>(null);
    const closeBtnRef = useRef<null | HTMLButtonElement>(null);

    const [audioPlayerOpened, setAudioPlayerOpened] = useState(false);
    const [audioIsPlaying, setAudioIsPlaying] = useState(false)
    const playerCssDefault: React.CSSProperties = { zIndex: 1, display: "hidden", margin: 0, };
    const playerCSScombined: React.CSSProperties = { ...playerCssDefault, ...playerCSS }

    useEffect(() => {
        // Make sure the audio loads with the new source instead of continuing to play the one from the previous letterPage
        audioRef.current?.load();

        setAudioIsPlaying(false);
        setAudioPlayerOpened(false);
        setCanPlay(false);

    }, [audioSrc])

    const [canPlay, setCanPlay] = useState(false)
    const [isLoading, setIsLoading] = useState(true);
    const handleOnCanPlay = () => {
        setIsLoading(() => false);
        setCanPlay(true);
    }

    const handleMainClick = () => {
        pasueAllOtherAudio();
        if (audioRef.current) {
            if (audioRef.current.duration > 0 && !audioRef.current.paused) { //Audio is playing
                audioRef.current.pause();
                //audioRef.current.style.opacity = "0";
                audioRef.current.tabIndex = -1;
                setAudioPlayerOpened(false);
                //closeBtnRef.current!.style.opacity = "0";
            } else { //Not playing. Paused, ended or never played.
                audioRef.current.play();
                //audioRef.current.style.opacity = "1";
                audioRef.current.tabIndex = 0;
                //closeBtnRef.current!.style.opacity = "1";
                !audioPlayerOpened && setAudioPlayerOpened(true);
                audioRef.current.focus();
            }
/*             if (audioRef.current.controls === false) {
                audioRef.current.controls = true;
            } */
        }
    }

    const handleCloseBtnClick = () => {
        /* if (audioRef.current!.controls === true) { */
            //closeBtnRef.current!.style.opacity = "0";
            audioRef.current!.pause();
            //audioRef.current!.controls = false;
            setAudioPlayerOpened(false);
            setAudioIsPlaying(false);
       /*  } */
    }

    /** Controls what happens when the audio ends */
    const handleOnEnded = () => {
        // Wait a second, and click the close button
        setTimeout(() => handleCloseBtnClick(), 1000);
    }

    const handleOnPlay = () => {
        pasueAllOtherAudio();
        setAudioIsPlaying(true);
    }

    function pasueAllOtherAudio() {
        // Pause all other audio
        const audios = document.querySelectorAll("audio");
        audios.forEach(el => {
            if (el.duration > 0 && !el.paused && el !== audioRef.current) {
                el.pause();
            }
        });
    }

    const handleOnPause = () => {
        setAudioIsPlaying(false);
    }


    return (
        <div style={{ display: "flex", /* flexDirection: 'column' */ alignItems: "center" }}>

            <div style={{ ...playerCSScombined, /* visibility: audioPlayerOpened ? "visible" : "collapse" */ }} className={(audioPlayerOpened && canPlay) ? styles['audio-player-window'] : styles['audio-player-window--closed']}>
                <button ref={closeBtnRef} onClick={handleCloseBtnClick} className={styles['close-btn']} tabIndex={audioPlayerOpened ? 0 : -1} ><FaWindowClose /> </button>
                <audio ref={audioRef} controls={true} controlsList="noplaybackrate nodownload" onLoadStart={() => setIsLoading(true)} onCanPlay={handleOnCanPlay} onEnded={handleOnEnded} onPlay={handleOnPlay} onPause={handleOnPause} onError={() => { setCanPlay(false); setIsLoading(false) }} tabIndex={audioPlayerOpened ? 0 : -1}>
                    <source src={audioSrc} type="audio/mpeg" />
                </audio>
            </div>

            <button onClick={handleMainClick} className={canPlay ? styles['trigger-btn'] : styles['trigger-btn--cannot-play']} style={{ opacity: canPlay ? "1" : "0.7" }}>
                <div style={{ fontSize: "1.25rem", }}>

                    {isLoading ?
                        <AiOutlineLoading3Quarters className={styles.loader} />
                        :
                        <div style={{ color: (audioIsPlaying && canPlay) ? "var(--dark_blue)" : (canPlay ? "var(--black)" : "lightgray") }}>
                            <GiSpeaker stroke={canPlay ? 'none' : 'var(--black)'} strokeWidth={canPlay ? 'none' : "1rem"} />
                        </div>
                    }
                </div>
                {children}
            </button>
        </div>
    );
};
