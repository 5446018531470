import ReactMarkdown from "react-markdown";
import React from "react";
import { FaExternalLinkAlt } from "react-icons/fa";
import { URLs } from "../../../utils/URLs";

interface Props {
    children: string;
}

//Make sure to keep this accessibility friendly
const allowedTags = [
    "em",
    "p",
    "ul",
    "ol",
    "li",
    "h2",
    "a",
    "strong",
]

export const Markdown = ({ children }: Props) => {
    
    /**Trims leading or trailing spaces or tabs from each line.
     * Created because we discovered that there was an issue where whole paragraphs did not get rendered by ReactMarkdown unless the tabs were removed.
    */
    function removeTabsBetweenLines(text: string): string {
        return text.split('\n').map(line => line.trim()).join('\n');
    }

    children = removeTabsBetweenLines(children);

    function LinkRenderer(props: any) {
        if (props.href.startsWith(URLs.AbpProd) || props.href.startsWith(URLs.AbpStage)) {
            return (
                <a href={props.href}>
                    {props.children}
                </a>
            );
        }
        else {
            return (
                <a href={props.href} target="_blank" rel="noopener">
                    {props.children}<FaExternalLinkAlt style={{marginInlineStart: "0.25em", fontSize: "0.75em"}} />
                </a>
            );
        }
    }

    return <ReactMarkdown allowedElements={allowedTags} components={{ a: LinkRenderer }}>{children}</ReactMarkdown>

}