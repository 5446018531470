import { useContext } from 'react'
import styles from './AssignmentPrompt.module.css'
import toast, { Toaster } from 'react-hot-toast';
import { CategoryTreeContextType, categoryTreeContext } from '../../../utils/CategoryTreeContext';
import { BasicSessionInfoContextType, basicSessionInfoContext } from '../../../utils/BasicSessionContext';
import {useMGMTTranslations, useTranslateGlobal} from '../../../utils/TranslationHooks';
const AssignmentLinkAndRedirectPrompt = (props: any) => {
    const mgmtTranslate = useMGMTTranslations();

    /* ------ Get values from CMS --------- */
    const { components } = useContext(categoryTreeContext) as CategoryTreeContextType;
    const { cmsLangId } = useContext(basicSessionInfoContext) as BasicSessionInfoContextType;

    const assignmentAssignedConfirmationNode = components?.children.find(node => node.name === "AssignmentAssignedConfirmation");
    console.assert(assignmentAssignedConfirmationNode, "Can't find node AssignmentAssignedConfirmationNode as child of " + components?.name);

    const copyLinkLabelNode = assignmentAssignedConfirmationNode?.children.find(node => node.name === "CopyLink-label");
    console.assert(copyLinkLabelNode, "Can't find node CopyLink-label as child of " + assignmentAssignedConfirmationNode?.name);
    const copyLinkLabel = copyLinkLabelNode?.children.find(node => node.name.includes(cmsLangId))?.description;
    console.assert(copyLinkLabel, "Can't find node with language id " + cmsLangId + " as child of " + copyLinkLabelNode?.name);

    const goToActivityLabelNode = assignmentAssignedConfirmationNode?.children.find(node => node.name === "GoToActivity-label");
    console.assert(goToActivityLabelNode, "Can't find node GoToActivity-label as child of " + assignmentAssignedConfirmationNode?.name);
    const goToActivityLabel = goToActivityLabelNode?.children.find(node => node.name.includes(cmsLangId))?.description;
    console.assert(goToActivityLabel, "Cannot find node with language id " + cmsLangId + " as child of " + goToActivityLabelNode?.name);

    const translateGloabal = useTranslateGlobal();
    const copiedLabel = translateGloabal("LinkCopiedToClipboard");
    /* ------------------------------------ */

    const handleCopyLinkClicked = () => {
        if (props.newLinkNode?.link) {
            navigator.clipboard.writeText(props.newLinkNode.link);
            toast(copiedLabel, {
                icon: '📋',
            });
        }
    }

    return (
        <div className={styles.prompt}>
            <Toaster />
            <h2 className={styles.header}>{mgmtTranslate("assigment_assignExercise")}</h2>

            <label>
                <div className={styles.label}>{mgmtTranslate("assignment_shareByLink")}</div>
                <div className={styles.linkWrapper}>
                    <input className={`${styles.input} ${styles.linkText}`} value={props.newLinkNode?.link} readOnly={true} />
                    <button className={styles.button} onClick={() => handleCopyLinkClicked()} >
                        {copyLinkLabel}
                    </button>
                </div>
            </label>

            <label>
                <div className={styles.label}>{mgmtTranslate("assignment_shareByCode")}</div>
                <div className={styles.codeText}>
                    {props.newLinkNode?.invitationStringId}
                </div>
            </label>

            <div className={styles.buttons}>
                <a className={styles.button} href="https://www.creaza.com/portfolio#/assignedByMe" target="_top">{goToActivityLabel}</a>
                <button onClick={props.cancelClicked} className={styles.button}>{mgmtTranslate("global_close")}</button>
            </div>
        </div>
    )
};

export default AssignmentLinkAndRedirectPrompt;