import styles from './ExerciseListing.module.css'
import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Exercise, sortExercisesBySortIndex } from '../../../utils/exerciseService';
import { ExerciseFilterSection } from '../ExerciseFilterSection/ExerciseFilterSection';
import ExercisePresenter from '../ExercisePresenter/ExercisePresenter';
import { CategoryTreeNode } from '../../../utils/CategoryTreeNode';
import { ExerciseDetailWindow } from '../ExerciseDetailWindow/ExerciseDetailWindow';
import {PrintElement} from "../../../utils/printUtils";
import {PrintDetailWindow} from "../PrintDetailWindow/PrintDetailWindow";

interface ExerciseListingProps {
    exercises: Exercise[];
    filterTags?: CategoryTreeNode[];
    themeColor?: string;
    printElements?: PrintElement[];
}

export const ExerciseListing = ({ exercises, filterTags, themeColor, printElements }: ExerciseListingProps) => {
    //const [checkedFilters, setCheckedFilters] = useState<string[]>([]);
    const isPrint = printElements && printElements.length > 0;
    const [checkedCategory, setCheckedCategory] = useState<string | null>(null);
    const [selectedExercise, setSelectedExercise] = useState<null | Exercise>(null);
    const [selectedPrintElement, setSelectedPrintElement] = useState<null | PrintElement>(null);

    useEffect(() => {
        if (isPrint)
            setSelectedPrintElement(null);
        else
            setSelectedExercise(null);

        cardClickedRef.current = null;
    }, [/* checkedFilters,  */checkedCategory])

    /**The element that has been selected. A different element will open when this is clicked */
    const cardClickedRef = useRef<null | HTMLDivElement>(null);

    if (isPrint)
        selectedPrintElement && cardClickedRef.current?.scrollIntoView();
    else
        selectedExercise && cardClickedRef.current?.scrollIntoView();

    /** Just a little helper function that returns the exercise with the given id */
    function getExercise(id: string) {
        const exerciseFound =  exercises && exercises.find(exercise => id === exercise.id);
        console.assert(exerciseFound !== undefined);
        return exerciseFound;
    }

    function getPrintElement(id: string) {
        const printFound = printElements && printElements.find(printElement => id === printElement.id);
        console.assert(printFound !== undefined);
        return printFound;
    }

    function handleCardClicked(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        if (e.currentTarget === null) return;
        const target = e.currentTarget;
        const targetId = target.getAttribute("data-key") || "";
        console.assert(targetId.length > 0, "Missing value on 'data-key' attribute");

        if (isPrint) {
            const targetPrintElement = getPrintElement(targetId);

            if (targetPrintElement)
                setSelectedPrintElement(targetPrintElement);
        } else {
            const targetExercise = getExercise(targetId);

            if (targetExercise)
                setSelectedExercise(targetExercise);
        }
    }

    const refContainer = useRef<null | HTMLDivElement>(null);
    const [containerWidth, setContainerWidth] = useState(0);
    const [translateX, setTranslateX] = useState(0);


    //sortExercises(exercises);
    useMemo(() => sortExercisesBySortIndex(exercises), [exercises]);

    const exerciseCardsHTML = exercises.map(exercise => {
        /** True if the exercise should be displayed based on the filtering which is set by the user */
        const exerciseShouldBeDisplayed = /* (checkedFilters.length === 0 || getFilters([exercise]).some(filter => checkedFilters.includes(filter))); */ // multiple can be checked
            checkedCategory === null || exercise.categories.toString().includes(checkedCategory); // works if only one category can be checked

        if (exerciseShouldBeDisplayed) {
            return (
                (exercise === selectedExercise) ? (
                    <>
                        <div data-key={exercise.id} id={exercise.id} key={exercise.id} ref={cardClickedRef} className={styles.card} >

                            {/* Exercise card that has been clicked */}
                            <ExercisePresenter exercise={exercise} highlighted themeColor={themeColor} />

                            {/* Big Exercise information window that shows up when a card is clicked. */}
                            <div style={{ width: containerWidth, transform: "translate(" + translateX + "px,0)" }}>
                                <ExerciseDetailWindow closeWindow={() => setSelectedExercise(null)} exercise={exercise} themeColor={themeColor} />
                            </div>
                        </div>
                    </>
                ) : ( /* The rest of the exercise cards */
                    <button className={`${styles.card} ${styles['card--unselected']}`} data-key={exercise.id} key={exercise.id} onClick={e => handleCardClicked(e)} >
                        <ExercisePresenter exercise={exercise} />
                    </button>
                )
            )
        }
        else {
            return <></>;
        }
    })

    const printCardsHTML = printElements && printElements.map(printElement => {
        return (
            (printElement === selectedPrintElement) ? (
                <>
                    <div data-key={printElement.id} id={printElement.id} key={'printDetailed_' + printElement.id} ref={cardClickedRef} className={styles.printCard} >

                        {/* Print card that has been clicked */}
                        <ExercisePresenter exercise={printElement} highlighted themeColor={themeColor} />

                        {/* Big print information window that shows up when a card is clicked. */}
                        <div style={{ width: containerWidth, transform: "translate(" + translateX + "px,0)" }}>
                            <PrintDetailWindow closeWindow={() => setSelectedPrintElement(null)} printElement={printElement} themeColor={themeColor} />
                        </div>
                    </div>
                </>
            ) : ( /* The rest of the exercise cards */
                <button className={`${styles.printCard} ${styles['card--unselected']}`} data-key={printElement.id} key={printElement.id} onClick={e => handleCardClicked(e)} >
                    <ExercisePresenter exercise={printElement} />
                </button>
            )
        )
    })

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        function handleResize() {
            refContainer.current && setContainerWidth(refContainer.current?.clientWidth);
        }
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, [])

    useLayoutEffect(() => {
        if (refContainer.current && cardClickedRef.current) {
            setTranslateX(refContainer.current.offsetLeft - cardClickedRef.current.offsetLeft);
        }
    }, [selectedExercise, selectedPrintElement, containerWidth]);

    return (
        <>
            {/* {(filterTags && filterTags.length > 0) && <ExerciseFilterSection filterTags={filterTags} filterChecked={(newlyChecked: string) => setCheckedFilters(() => [...checkedFilters, newlyChecked])} filterUnchecked={(newlyUnchecked: string) => setCheckedFilters(() => checkedFilters.filter(filter => filter !== newlyUnchecked))} />} */}
            {filterTags && filterTags.length > 0 &&
                <ExerciseFilterSection
                    filterTags={filterTags}
                    filterChecked={(newlyChecked: string) => setCheckedCategory(newlyChecked)}
                    filterUnchecked={() => setCheckedCategory(null)}
                />
            }

            <div ref={refContainer} className={styles.list} style={{}} >
                {isPrint ? printCardsHTML : exerciseCardsHTML}
            </div>
        </>
    );
};
