import { ThemeName, Themes } from "../../utils/Themes";
import styles from "./PrintPage.module.css";
import { BackButtonLink } from "../UIComponents/BackButtonLink/BackButtonLink";
import { TopicHeader } from "../UIComponents/TopicNode/TopicHeader";
import { Markdown } from "../UIComponents/Markdown/Markdown";
import { PageWithSidebars } from "../UIUtils/PageWithSidebars/PageWithSidebars";
import {CategoryTreeNode} from "../../utils/CategoryTreeNode";
import React from "react";
import {parsePrintTree, PrintStructure} from "../../utils/printUtils";
import {PrintLeafNode} from "./PrintLeafNode";
import {useMGMTTranslations} from "../../utils/TranslationHooks";

interface PrintPageProps {
    printNode : CategoryTreeNode;
    themeName?: ThemeName;
}

export const PrintPage = ({printNode, themeName}: PrintPageProps) => {
    const printStructure : PrintStructure = parsePrintTree(printNode);
    const theme = themeName && Themes[themeName as ThemeName];
    const themeColor = theme?.lightColor;
    const mgmtTranslate = useMGMTTranslations();

    return (
        <div className={styles["print-page"]}
             style={{
                 backgroundColor: theme ? theme.backgroundColor : "white"
             }}>

            <BackButtonLink linkTo={"./.."} />

            <PageWithSidebars
                header={<TopicHeader themeName={themeName}>{mgmtTranslate("print") || ""}</TopicHeader>}
                tableOfContent={printStructure.categoryNames} >
                <div className={styles.container}>
                    {printStructure.description &&
                        <Markdown>{printStructure.description}</Markdown>
                    }
                </div>

                {printStructure.categories.map(printCategory => (
                    <PrintLeafNode
                        printCategory={printCategory}
                        themeColor={themeColor}
                        key={printCategory.name}
                    />
                ))}
            </PageWithSidebars>
        </div>
    )
}