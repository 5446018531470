import React from "react";
import {Topic} from "../../../utils/Topic";
import {Link} from "react-router-dom";
import styles from "./TopicRootTile.module.css"
import {ThemeName, Themes} from "../../../utils/Themes";
import { topicIconBaseURL } from "./TopicIcon";

interface Props {
    topic: Topic;
    themeName?: ThemeName;
}

export const TopicRootTile = ({topic, themeName = "alfa"}: Props) => {
    const backgroundImage = topic.icon ? topicIconBaseURL + topic.icon : undefined;
    const backgroundImageCSS =
        `linear-gradient(${Themes[themeName].lightColor}, ${Themes[themeName].darkColor})`

    return (
        <Link
            className={styles.title}
            to={`${topic.categoryId}`}>
            <div
                className={styles["topic-root-tile"]}
            >
                <div
                    className={styles["image-background"]}
                    style={{
                        backgroundColor: Themes[themeName].darkColor,
                        backgroundImage: backgroundImageCSS
                    }}
                >
                    {backgroundImage && <img className={styles.image} src={backgroundImage} alt =""/>}
                </div>
                <div className={styles.text}>
                    {topic.title}
                </div>
            </div>
        </Link>
    )
}