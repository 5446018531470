import React, { useState } from 'react';
import { Exercise } from '../../../utils/exerciseService';
import { getExerciseThumbnail } from '../../../utils/getExerciseThumbnail';

import styles from './ExercisePresenterNew.module.css';
import Modal from "../Modal/Modal";
import { ExerciseDetails } from "../ExerciseDetails/ExerciseDetails";
import { Link } from "react-router-dom";
import { FaAngleDown } from 'react-icons/fa';

interface ExercisePresenterProps {
    exercise: Exercise,
    linkTo?: string
}

const ExercisePresenterNew = ({ exercise, linkTo }: ExercisePresenterProps) => {
    const [showModal, setShowModal] = useState(false);

    const readMoreSVG = <FaAngleDown />
    const getTile = () => {
        return (
            <>
                <img className={styles.thumbnail} src={getExerciseThumbnail(exercise)} alt="" />
                <div className={styles['title-box']}>
                    <div className={styles.title}>{exercise.name}</div>
                    <div className={styles['read-more']}>
                        {readMoreSVG}
                    </div>
                </div>
            </>
        )
    }

    //Render either link or details button
    if (linkTo) {
        return (
            <Link style={{ textDecoration: "none" }} to={linkTo}>
                <div className={styles.normal} onClick={() => {
                    !linkTo && setShowModal(true);
                }}>
                    <img className={styles.thumbnail} src={getExerciseThumbnail(exercise)}
                        alt="" /> {/* src={exercise.thumbnailURL || 'https://www.creaza.com/file/ci/' + exercise.contentId + '/thumb/' + exercise.name}  */}
                    <div className={styles['title-box']}>
                        <div className={styles.title}>{exercise.name}</div>
                    </div>
                </div>
            </Link>
        )
    } else {
        return (
            <>
                <Modal
                    style={{
                        backgroundColor: "#fae1ff",
                        border: "none",
                        boxShadow: "rgba(0, 0, 0, 0.25) 0 0 5px"
                    }}
                    show={showModal}
                    backdropClicked={() => setShowModal(false)}
                >
                    <ExerciseDetails closeWindow={() => setShowModal(false)} exercise={exercise} />
                </Modal>
                <button className={styles.normal} onClick={() => {
                    !linkTo && setShowModal(true);
                }}>
                    {getTile()}
                </button>
            </>
        )
    }
};

export default ExercisePresenterNew;