import styles from "./SmallHeader.module.css";

interface Props {
    headingLevel: number;
    children: any;
    /** Hash value in URL that can point to a specific point in the page.
     * This will be set as the value of the 'id' attribute of the heading tag.
     */
    hash?: string;
}

export const SmallHeader = ({ children, headingLevel, hash }: Props) => {
    const Heading = `h${headingLevel}`

    return (
        //@ts-ignore
        <Heading
            id={hash}
            className={styles['medium-header']}
        >
            {children}
        </Heading>
    )
}