import {Topic} from "../../utils/Topic";
import styles from "./TopicTreeBranchPage.module.css";
import React, {useContext} from "react";
import {ThemeName, Themes} from "../../utils/Themes";
import {BackButtonLink} from "../UIComponents/BackButtonLink/BackButtonLink";
import {TopicHeader} from "../UIComponents/TopicNode/TopicHeader";
import {CategoryTreeNode} from "../../utils/CategoryTreeNode";
import {InfoButton} from "../UIComponents/InfoButton/InfoButton";
import storiesStyles from "./StoriesBranch.module.css"
import {StoryList} from "../StoryList/StoryList";
import {basicSessionInfoContext, BasicSessionInfoContextType} from "../../utils/BasicSessionContext";
import AlphabetNavBar from "../UIComponents/AlphabetNavBar";

interface TopicTreeBranchPageProps {
    topic: Topic;
    themeName?: ThemeName;
    storiesCategoryNode: CategoryTreeNode;
    storiesPageNode: CategoryTreeNode;
}

export const StoriesBranch = (
    {
        topic,
        themeName,
        storiesCategoryNode,
        storiesPageNode
    }: TopicTreeBranchPageProps) => {

    const { cmsLangId } = useContext(basicSessionInfoContext) as BasicSessionInfoContextType;
    const storiesPageTitle = storiesPageNode?.children.find(n => n.name === "Title")?.children.find(n => n.name.includes(cmsLangId))?.description;

    return (
        <div className={styles["topic-tree-branch-page"]}
             style={{
                 backgroundColor: themeName ? Themes[themeName].backgroundColor : "white"
             }}
        >
            <BackButtonLink linkTo={`${topic.parentId}`}/>
            <div className={styles.container}>
                <TopicHeader themeName={themeName}>{topic.title}</TopicHeader>
                <div className={storiesStyles.wrapper}>
                    <div className={storiesStyles["info-wrapper"]}>
                        <InfoButton text={topic.guidance || ""}/>
                    </div>
                    <div className={storiesStyles["no-card"]}>
                    </div>
                    <div className={storiesStyles["no-card"]}>
                    </div>
                </div>

                <div className={`${styles["content"]} ${storiesStyles["story-list"]}`}>
                    <StoryList categoryId={storiesCategoryNode.id} prefixUrl={`/teacher/${(storiesPageTitle || "").toLowerCase()}/`}/>
                </div>
            </div>
            <AlphabetNavBar />
        </div>
    )
}