import axios, { AxiosError } from "axios";
import NewLinkResponse from "./NewLinkResponse";
import qs from 'qs';

const isDevelop = process.env.NODE_ENV === 'development';

export const initAssign = async () => {

    let data: NewLinkResponse | null = null;
    let error: AxiosError | null = null;
    let isPending = null;

    const url = 'https://www.creaza.com/ICSXapi/assignment/NewLink?type=assignment';

    await axios({
        method: "post",
        url: url,
        withCredentials: isDevelop ? false : true,
    }).then((response) => {
        data = response.data
    }).catch(e => error = e);

    return { data, isPending, error };
}

export const createNewAssignment = async (newAssignment: any) => {

    const url = 'https://www.creaza.com/ICSXapi/assignment/Store'

    console.log('assignExercise');
    console.log(newAssignment);
    /* 
        const formData = new FormData();
        formData.append('assignerId', newAssignment.assignerId);
        formData.append('invitationId', newAssignment.invitationId);
        formData.append('title', newAssignment.title);
        formData.append('description', newAssignment.description);
        formData.append('contentId', newAssignment.contentId);
        formData.append('exerciseId', newAssignment.exerciseId);
        formData.append('assignmentId', newAssignment.assignmentId); */


    let data: any = null;
    let error: AxiosError | null = null;
    let isPending = null;

    /* await axios.post(url, formData)
        .then(response => {
            console.log(response);
            data = response.data;
        })
        .catch(e => error = e); */

    await axios({
        method: "post",
        url: url,
        data: qs.stringify(newAssignment),
        withCredentials: isDevelop ? false : true,
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        }
    })
        .then(response => {
            console.log(response);
            data = response.data;
        })
        .catch(e => error = e);

    return { data, isPending, error };
}
