import { useEffect, useRef } from 'react';
import styles from "./AudioPlayer.module.css"

interface AudioPlayerProps {
    src?: string;
    onStart: (src: string) => void;
    onStop: () => void;
}

export const AudioPlayer = ({src, onStart, onStop}: AudioPlayerProps) => {
    const audioRef = useRef<null | HTMLAudioElement>(null);

    useEffect(() => {
        if(src) {
            audioRef.current?.pause();
            audioRef.current?.load();
            audioRef.current?.play();
        } else {
            audioRef.current?.load();
        }
    }, [src])

    return (
        <div className={`${src? "" : styles.hidden} ${styles["audio-player"]}`}>
            <audio ref={audioRef} controls={true} controlsList="noplaybackrate nodownload"
               onPause={onStop}
               onPlay={() => onStart(src || "")}
            >
                <source src={src} type="audio/mpeg" />
            </audio>
        </div>
    );
};
