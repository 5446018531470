import { createContext, useEffect, useMemo, useState } from "react";
import { LanguageISO639_1, useUserLanguage } from "./languages";

interface BiographyLanguageProviderProps {
    children: React.ReactNode;
};

export type BiographyLanguageContextType = {
    biographyLanguage: LanguageISO639_1 | null;
    setBiographyLanguage: (languageIsoCode: LanguageISO639_1) => void;
};

export const biographyLanguageContext = createContext<BiographyLanguageContextType | null>(null);

export const BiographyLanguageProvider = ({ children }: BiographyLanguageProviderProps) => {

    const userLanguage = useUserLanguage();
    const defaultSpokenLang = userLanguage?.defaultSpokenLanguage;
    const [selectedBiographyLanguage, setSelectedBiographyLanguage] = useState<LanguageISO639_1 | null>(null);

    /** Set the biography language to the default spoken language as soon as it is available */
    useEffect(() => {
        if (defaultSpokenLang) {
            setSelectedBiographyLanguage(defaultSpokenLang);
        }
    }, [defaultSpokenLang]);

    const biographyLanguage = useMemo(() => selectedBiographyLanguage, [selectedBiographyLanguage]);

    return (
        <biographyLanguageContext.Provider value={{ biographyLanguage, setBiographyLanguage: setSelectedBiographyLanguage }}>
            {children}
        </biographyLanguageContext.Provider>
    );
};