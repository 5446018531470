import axios, { AxiosError } from "axios";
import { useContext, useEffect, useState } from "react";
import AlphabetPlanetCategoryTreeResponseData from "./AlphabetPlanetCategoryResponseData";
import { CategoryTreeNode } from "./CategoryTreeNode";
import { APIEndpoints } from "./APIEndpoints";
import { BasicSessionInfoContextType, basicSessionInfoContext } from "./BasicSessionContext";

export const useFetchCategoryTree = () => {
    const [data, setData] = useState<AlphabetPlanetCategoryTreeResponseData | null>(null);
    const [error, setError] = useState<AxiosError | null>(null);
    const [isPending, setIsPending] = useState<boolean>(false);

    const url = APIEndpoints.AlphabetPlanetCategoryTree;

    // languageId is needed to fetch the correct version of the category tree.
    // Currently only The categories in <no>Activities have different versions for different languages (Bokmål and Nynorsk).
    const { basicSessionInfo } = useContext(basicSessionInfoContext) as BasicSessionInfoContextType;
    const langId = basicSessionInfo?.languageId;
    useEffect(() => {
        if (!langId) {
            return;
        }
        setIsPending(true);
        axios.post(url + `?languageId=${langId}`)
            .then(response => {
                /* setData(response.data); */

                // Parse soft hyphens and remove node names' parantheses
                const resdata: AlphabetPlanetCategoryTreeResponseData = response.data;
                resdata && purgeData(resdata);
                setData(resdata);
                // ---------
            })
            .catch(error => setError(error))
            .finally(() => {
                setIsPending(false);
            });
    }, [url, langId]);


    function purgeData(node: AlphabetPlanetCategoryTreeResponseData) {
        node.categoriesAlphabetPlanet.forEach(n => {
            removeParanthesesFromNodeName(n);
            parseSoftHyphen(n);
        })

        function removeParanthesesFromNodeName(node: CategoryTreeNode) {
            let result = "";
            let insideParanthese = false;
            for (let i = 0; i < node.name.length; i++) {
                if (node.name[i] === '(') {
                    insideParanthese = true;
                    continue;
                }
                else if (node.name[i] === ')') {
                    insideParanthese = false;
                    continue;
                }
                if (!insideParanthese) {
                    result += node.name[i];
                }
            }
            node.name = result;

            if (node.children) {
                node.children.forEach(n => removeParanthesesFromNodeName(n))
            }
        }

        /** Replaces "&shy;" with "\u00AD" in all nodes of the categoryTree.
         * This will prevent React from rendering actual "&shy;".
         * This makes it so the content creators can use "&shy;" wherever they want a soft hyphen to appear.
         */
        function parseSoftHyphen(node: CategoryTreeNode) {
            node.description = node.description.replace("&shy;", "\u00AD");
            node.name = node.name.replace("&shy;", "\u00AD");
            if (node.children) {
                node.children.forEach(child => parseSoftHyphen(child));
            }
        }
    }

    return { data, isPending, error };
}