import React from "react";
import {Librarian} from "./LibraryPage";
import styles from "./LibrarianView.module.css";
import {AudioOnClickButton} from "../UIUtils/AudioOnClickButton/AudioOnClickButton";
import {AudioButton} from "../UIUtils/AudioButton/AudioButton";

interface Props {
    librarian: Librarian;
    flipHorizontally?: boolean;
    onAudioStart: (id: string, src: string) => void;
    onAudioStop: (id: string, src: string) => void;
    isPlaying: boolean;
}

export const LibrarianView =  ({librarian, flipHorizontally, onAudioStart, onAudioStop, isPlaying}: Props ) => {
    return (
        <div className={styles["librarian-view"]} key={librarian.name}>
            <AudioButton
                src={librarian.biographyAudio}
                onStart={onAudioStart}
                onStop={onAudioStop}
                isPlaying={isPlaying}
                id={librarian.id}
            >&nbsp;
                <div  className={styles["librarian-wrapper"]}>
                    <div className={styles["librarian-name"]}>{librarian.name}</div>
                    <img
                        src={librarian.profilePic}
                        alt={""}
                        className={`${styles["librarian-image"]} ${flipHorizontally? styles.flipHorizontally: ""}`}
                    />
                </div>
            </AudioButton>
        </div>
    )
}