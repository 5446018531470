import React from 'react';
import { GiSpeaker } from 'react-icons/gi';
import {IconButton} from "../../UIComponents/IconButton/IconButton";
import styles from './AudioButton.module.css';

interface AudioButtonProps {
    id: string;
    children: React.ReactNode;
    isPlaying: boolean;
    onStart: (id: string, src: string) => void;
    onStop: (id: string, src: string) => void;
    src: string;
}

export const AudioButton = ({children, id, src, isPlaying, onStart, onStop}: AudioButtonProps) => {
    const handleButtonClick = () => {
        if(isPlaying){
            onStop(id, src);
        }
        else{
            onStart(id, src);
        }
    }

    return (
        <div style={{
            display: "flex",
            alignItems: "center",
        }}>
            <button onClick={handleButtonClick} className={styles['audio-button']}>
                {children}
                <div style={{ color: isPlaying ? "var(--dark_blue)" : ("var(--black)") }}>
                    <IconButton><GiSpeaker/></IconButton>
                </div>
            </button>
        </div>
    );
};
