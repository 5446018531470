import axios from "axios";
import { createContext, useEffect, useState } from "react";
import { APIEndpoints } from "./APIEndpoints";

interface MgtmtTranslations {
    [translationKey: string]: {
        [langKey: string]: string | null;
    };
}

export type MGMTTranslationsContextType = {
    translations: MgtmtTranslations | null;
};

export const MGMTTranslationsContext = createContext<MGMTTranslationsContextType | null>(null);

export const MGMTTranslationsProvider = ({ children }: { children: React.ReactNode }) => {
    const [data, setData] = useState<MgtmtTranslations | null>(null);

    useEffect(() => {
        axios.get(APIEndpoints.MGMTTranslations)
            .then((response) => {
                setData(response.data)
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);
    
    return (
        <MGMTTranslationsContext.Provider value={{ translations: data }}>
            {children}
        </MGMTTranslationsContext.Provider>
    );
};

// Here's an earlier response from the API, so you know a little about what to expect.
/* const mockResponse: MgtmtTranslations = {
    "language_ps": {
        "nb_NO": "pashto",
        "nn_NO": "pashto",
        "fi_FI": "Pashto",
        "ru_RU": null,
        "sv_SE": "pashto",
        "en_US": "Pashto",
        "uk_UA": null,
        "da_DK": "Pashto"
    },
    "language_sw": {
        "nb_NO": "swahili",
        "nn_NO": "swahili",
        "fi_FI": "Swahili",
        "ru_RU": null,
        "sv_SE": "swahili",
        "en_US": "Swahili",
        "uk_UA": null,
        "da_DK": "Swahili"
    },
    "language_no": {
        "nb_NO": "norsk",
        "nn_NO": "norsk",
        "fi_FI": "Norwegian",
        "ru_RU": null,
        "sv_SE": null,
        "en_US": "Norwegian",
        "uk_UA": null,
        "da_DK": "Norwegian"
    },
    "test": {
        "nb_NO": null,
        "nn_NO": null,
        "fi_FI": "test",
        "ru_RU": null,
        "sv_SE": "test ",
        "en_US": "test",
        "uk_UA": null,
        "da_DK": "test"
    },
    "language_ti": {
        "nb_NO": "tigrinja",
        "nn_NO": "tigrinja",
        "fi_FI": "Tigrinya",
        "ru_RU": null,
        "sv_SE": "tigrinska",
        "en_US": "Tigrinya",
        "uk_UA": null,
        "da_DK": "Tigrinya"
    },
    "concept": {
        "nb_NO": null,
        "nn_NO": null,
        "fi_FI": null,
        "ru_RU": null,
        "sv_SE": "concept",
        "en_US": null,
        "uk_UA": null,
        "da_DK": null
    },
    "language_so": {
        "nb_NO": "somali",
        "nn_NO": "somali",
        "fi_FI": "Somali",
        "ru_RU": null,
        "sv_SE": "somaliska",
        "en_US": "Somali",
        "uk_UA": null,
        "da_DK": "Somali"
    },
    "language_prs": {
        "nb_NO": "dari",
        "nn_NO": "dari",
        "fi_FI": "Dari",
        "ru_RU": null,
        "sv_SE": "dari",
        "en_US": "Dari",
        "uk_UA": null,
        "da_DK": "Dari"
    },
    "global_chooseALanguage": {
        "nb_NO": "velg språk",
        "nn_NO": "vel språk",
        "fi_FI": "choose a language",
        "ru_RU": null,
        "sv_SE": "välj ditt språk",
        "en_US": "choose a language",
        "uk_UA": null,
        "da_DK": "choose a language"
    },
    "language_tr": {
        "nb_NO": "tyrkisk",
        "nn_NO": "tyrkisk",
        "fi_FI": "Turkish",
        "ru_RU": null,
        "sv_SE": "turkiska",
        "en_US": "Turkish",
        "uk_UA": null,
        "da_DK": "Turkish"
    }
} */