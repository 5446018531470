import { useTranslateGlobal } from "../../../utils/TranslationHooks";
import { ScrollToAnchorNav } from "../../UIComponents/ScrollToAnchorNav/ScrollToAnchorNav";
import styles from './PageWithSidebars.module.css';

interface Props {
    tableOfContent?: string[];
    header?: React.ReactNode;
    children: React.ReactNode;
}

export const PageWithSidebars = ({ header, tableOfContent, children }: Props) => {

    const translateGlobal = useTranslateGlobal();

    return (
        <div className={styles.page}>

            <div className={styles['sidebar-left']} style={{ visibility: "hidden" }} aria-hidden={true} >

            </div>

            <div className={styles.header}>

                {header}

            </div>

            {(tableOfContent && tableOfContent.length > 0) &&
                <div className={styles['sidebar-right']}>
                    <ScrollToAnchorNav
                        heading={translateGlobal("OnThisPage")}
                        navItems={tableOfContent}
                    />
                </div>
            }

            <div className={styles.content}>
                {children}
            </div>
        </div>
    )
}